import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as icons from '@fortawesome/pro-solid-svg-icons';
import { Tooltip } from 'antd';
import { colors, fonts } from '../../styles/style';
import { ButtonContainer } from './ItemDetailButton.styled';

const ItemDetailButton = ({
  show = true,
  icon = 'faExternalLinkAlt',
  children,
  onClick,
  titleTooltip,
  paddingRight,
  marginLeft,
  onCloseModal,
  preventOpenDrawer,
  tooltipProps,
  iconColor = colors.primary600,
  iconId = 'item-detail-id',
  width = fonts.sizeMd
}) => {
  const [showModal, setShowModal] = useState(false);

  if (!show) {
    return <div />;
  }

  const RenderModal = () =>
    React.cloneElement(children, {
      onClose: (arg1, arg2) => {
        if (onCloseModal) onCloseModal(arg1, arg2);
        setShowModal(false);
      }
    });

  return (
    <ButtonContainer $paddingRight={paddingRight} $marginLeft={marginLeft}>
      <Tooltip placement="bottomRight" title={titleTooltip} align={{ offset: [10, 0] }} {...tooltipProps}>
        <FontAwesomeIcon
          id={iconId}
          style={{ flexGrow: 1, cursor: onClick ? 'pointer' : 'default', width, height: width }}
          icon={typeof icon === 'object' ? icon : icons[icon]}
          color={iconColor}
          onClick={e => {
            e.stopPropagation();
            onClick && onClick();
            if (!preventOpenDrawer) {
              setShowModal(true);
            }
          }}
        />
      </Tooltip>
      {showModal && children && <RenderModal />}
    </ButtonContainer>
  );
};

ItemDetailButton.propTypes = {
  show: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  children: PropTypes.instanceOf(Object),
  onClick: PropTypes.func,
  titleTooltip: PropTypes.string,
  paddingRight: PropTypes.bool,
  marginLeft: PropTypes.bool,
  onCloseModal: PropTypes.func,
  preventOpenDrawer: PropTypes.bool,
  tooltipProps: PropTypes.instanceOf(Object),
  iconColor: PropTypes.string,
  iconId: PropTypes.string,
  width: PropTypes.string
};

export default ItemDetailButton;
