import * as Yup from 'yup';
import { name, phone, email, website, text } from '../schema';
import { observation } from '../mapping';

// @Todo - separated file ? - discuss - //
const supplierSchema = Yup.object().shape({
  name,
  phone,
  email,
  website,
  type: Yup.string().nullable(),
  responsibleName: Yup.string().nullable(),
  contactName: Yup.string().nullable(),
  zipcode: text,
  street: text,
  number: text,
  complement: text,
  neighborhood: text,
  city: text,
  state: text,
  personType: Yup.string().nullable(),
  document: Yup.string().nullable(),
  bankName: Yup.string().nullable(),
  agency: Yup.string().nullable(),
  account: Yup.string().nullable(),
  paymentOptions: Yup.string().nullable(),
  pixKey: text,
  observation: text
});

const items = [
  { label: 'Pessoa Física', value: 'pf' },
  { label: 'Pessoa Jurídica', value: 'pj' }
];

const supplierMapping = ({ personType }) => ({
  name: {
    name: 'Nome fantasia*',
    type: 'text',
    placeholder: 'Nome  fantasia',
    xs: 24,
    md: 12
  },
  legalName: {
    name: 'Razão Social',
    type: 'text',
    placeholder: 'Razão Social',
    xs: 24,
    md: 12
  },
  type: {
    name: 'Qual o tipo da empresa*',
    type: 'select',
    placeholder: 'Tipo',
    dataType: 'supplierType',
    xs: 24,
    md: 12
  },
  idCostCenter: {
    name: 'Categorias',
    type: 'select',
    placeholder: 'Digite para encontrar ou criar uma categoria',
    model: 'costCenter',
    modelOptions: { onlyMine: true, order: [['name']] },
    allowCustomOptions: true,
    allowClear: true,
    xs: 24,
    md: 12
  },
  email: {
    name: 'Qual o email da empresa',
    type: 'text',
    placeholder: 'Ex: empresa@empresa.com.br',
    xs: 24,
    md: 12
  },
  website: {
    name: 'Site da Empresa',
    type: 'text',
    placeholder: 'Site da Empresa',
    xs: 24,
    md: 12
  },
  phone: {
    name: 'Telefone de contato',
    type: 'text',
    placeholder: '(00) 0000-0000',
    xs: 24,
    md: 12
  },
  responsibleName: {
    name: 'Nome do responsável',
    type: 'text',
    placeholder: 'Nome do responsável',
    xs: 24,
    md: 12
  },
  contactName: {
    name: 'Nome de contato',
    type: 'text',
    placeholder: 'Nome de contato',
    xs: 24,
    md: 12
  },
  zipcode: {
    name: 'CEP',
    type: 'zipCode',
    placeholder: '00000-000',
    xs: 24
  },
  street: {
    name: 'Endereço',
    type: 'text',
    placeholder: 'Ex: Rua das Laranjeiras',
    xs: 24,
    md: 8
  },
  number: {
    name: 'Número',
    type: 'text',
    placeholder: '22',
    xs: 24,
    md: 4
  },
  complement: {
    name: 'Complemento',
    type: 'text',
    placeholder: 'Apto 55',
    xs: 24
  },
  neighborhood: {
    name: 'Bairro',
    type: 'text',
    placeholder: 'Jd América',
    xs: 24
  },
  city: {
    name: 'Cidade',
    type: 'text',
    placeholder: 'São Paulo',
    xs: 24,
    md: 8
  },
  state: {
    name: 'Estado',
    dataType: 'states',
    type: 'select',
    placeholder: 'Estado',
    allowClear: true,
    xs: 24,
    md: 4
  },
  personType: {
    name: 'Tipo',
    type: 'radioSwitch',
    defaultValue: 'pf',
    items,
    xs: 24,
    md: 12
  },
  document: {
    name: personType === 'pj' ? 'CNPJ' : 'CPF',
    type: personType === 'pj' ? 'cnpj' : 'cpf',
    placeholder: personType === 'pj' ? '00.000.000/0001-00' : '000.000.000-00',
    xs: 24,
    md: 12
  },
  stateDoc: {
    type: 'text',
    name: personType === 'pj' ? 'Inscrição Estadual' : 'RG',
    placeholder: personType === 'pj' ? 'Ex: 000.000.000.000' : 'Ex: 00.000.000-0',
    xs: 24,
    md: 12
  },
  bankName: {
    name: 'Nome do banco',
    type: 'text',
    placeholder: 'Ex: Banco Itaú',
    xs: 24,
    md: 12
  },
  agency: {
    name: 'Agência',
    type: 'text',
    placeholder: '0000',
    xs: 24,
    md: 12
  },
  account: {
    name: 'Conta corrente',
    type: 'text',
    placeholder: '00000',
    xs: 24,
    md: 12
  },
  isActive: {
    type: 'checkbox',
    name: 'Ativo?',
    xs: 24,
    md: 12,
    defaultValue: true
  },
  pixKey: {
    name: 'Pix',
    placeholder: 'Chave PIX',
    type: 'textarea',
    xs: 24,
    height: 80,
    maxLength: 100
  },
  observation,

  paymentOptions: {
    name: 'Outros meios de pagamento',
    type: 'editor',
    placeholder: 'Detalhe aqui outros meios de pagamento',
    xs: 24,
    height: 80,
    plugins: undefined,
    toolbar: false,
    statusbar: false,
    id: 'supplier-payment-methods-text'
  }
});

export { supplierSchema, supplierMapping };
