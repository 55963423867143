import React from 'react';
import dayjs from 'dayjs';
import { v4 as uuidV4 } from 'uuid';
import { capitalize as capitalizeHelper, sumByField } from './helper';
import { colors } from '../../styles/style';
import { Paragraph } from '../../components/Text/Text';
import formatCurrency from './formatCurrency';
import formatNumber from './formatNumber';
import { store } from '../config/redux-store';

export const getPaymentLabel = ({ billType, isCharge, billTypeEnum, capitalize }) => {
  const result = isCharge ? 'pagamento' : billTypeEnum[billType];

  return capitalize ? capitalizeHelper(result) : result;
};

export const extractInitialOrderData = (orderData, isOrder) => {
  const { orderDate, id, discount, shipping, taxes, idSupplier, paymentTypes = [] } = orderData || {};
  return {
    orderDate,
    idOrder: id,
    idSupplier,
    ...(isOrder && { shipping, taxes, discount, paymentTypes })
  };
};

export const getViewPaymentUrl = ({ billType, isCharge, id, billTypeEnum, idRefurbish, refurbishType, isCustomer }) => {
  if (isCustomer) return `/cliente/cobrancas/visualizar/${id}`;

  return isCharge
    ? `/profissional/financeiro/cobrancas/visualizar/${id}?${
        idRefurbish && (refurbishType === 'projeto' || refurbishType === 'oportunidade')
          ? `${refurbishType}=${idRefurbish}`
          : ''
      }`
    : `/profissional/financeiro/${billTypeEnum[billType]}s/editar/${id}${
        idRefurbish && refurbishType === 'projeto' ? `?${refurbishType}=${idRefurbish}` : ''
      }`;
};

export const getValidationErrorMessage = ({
  isView,
  isRevision,
  totalInstallmentIsValid,
  paymentItemsIsValid,
  hasInvoiceInvalid
}) => {
  if (!paymentItemsIsValid) return 'É obrigatório adicionar no mínimo 1 item.';
  if (!totalInstallmentIsValid) return 'A soma das parcelas é diferente do total do pagamento!';
  if (hasInvoiceInvalid) return 'É obrigatório informar "Série/Número" da nota fiscal';
  return !isView && !isRevision
    ? 'Por favor verifique os erros de formulário antes da revisão!'
    : 'Por favor verifique os erros de formulário!';
};

export const calculateInstallments = ({ totalValue, maxLimitPayment = {} }) => {
  const minimumInstallmentValue = maxLimitPayment.minimumInstallmentValue.value;
  const maximumInstallments = maxLimitPayment.maximumInstallments.value;

  let installmentsCount = Math.floor(totalValue / minimumInstallmentValue);

  if (installmentsCount > maximumInstallments) {
    installmentsCount = maximumInstallments;
  }

  const installments = [];

  for (let i = 1; i <= installmentsCount; i++) {
    const installmentValue = Math.round((totalValue / i) * 100) / 100;
    const installmentText = `Em até ${i}x de R$${installmentValue.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })}`;

    if (installmentValue >= minimumInstallmentValue) {
      installments.push({ value: i, price: installmentValue, label: installmentText });
    }
  }

  return installments;
};

export const paymentTypeWhereClause = ({ idPaymentType, installmentStatuses, billType, isExport }) =>
  idPaymentType && {
    idPaymentType: idPaymentType?.includes(0) && !isExport ? { or: { in: idPaymentType, eq: 'null' } } : idPaymentType,
    idInstallmentStatus: installmentStatuses.allPaid,
    ...(billType && { billType })
  };

export const mountInstallmentWhere = ({
  isExport,
  mountFilter,
  isCurrentBalance,
  paymentTypeValues,
  installmentStatuses
} = {}) => {
  const {
    description,
    idPaymentBankAccount,
    initialDate,
    endDate,
    idFinancialCategory,
    idPaymentCostCenter,
    idCompanyCustomer,
    idSupplier,
    idRefurbish,
    rangeField,
    billType: _billType,
    idPaymentType,
    idReconciliation
  } = mountFilter || {};

  let rangeDate;

  if (isExport) {
    rangeDate = {
      initialDate,
      endDate,
      rangeField,
      paidDate: { ne: null }
    };
  } else if (rangeField === 'asaasEstimatedCreditDate') {
    rangeDate = {
      or: [
        { asaasEstimatedCreditDate: { gte: initialDate, lte: endDate } },
        {
          paidDate: { gte: initialDate, lte: endDate },
          asaasEstimatedCreditDate: null
        }
      ]
    };
  } else if (initialDate || endDate) {
    rangeDate = {
      [rangeField]: { gte: initialDate, lte: endDate }
    };
  }

  if (isCurrentBalance) {
    return {
      ...(idPaymentBankAccount && {
        idPaymentBankAccount: idPaymentBankAccount.includes(-1) ? 'null' : idPaymentBankAccount
      }),
      billType: [paymentTypeValues.expense.value, paymentTypeValues.income.value, paymentTypeValues.transfer.value]
    };
  }

  return {
    ...(description && { description: { like: `%${description}%` } }),
    ...(idPaymentBankAccount && {
      idPaymentBankAccount: idPaymentBankAccount === '-1' ? { eq: 'null' } : idPaymentBankAccount
    }),
    ...rangeDate,
    ...(idFinancialCategory && { idFinancialCategory }),
    ...(idPaymentCostCenter && { idPaymentCostCenter }),
    ...(idCompanyCustomer && { idCompanyCustomer }),
    ...(idSupplier && { idSupplier }),
    billType: _billType,
    ...(idRefurbish && { idRefurbish }),
    ...paymentTypeWhereClause({
      idPaymentType,
      installmentStatuses,
      billType: [paymentTypeValues.expense.value, paymentTypeValues.income.value],
      isExport
    }),
    ownBusiness: true,
    ...(idReconciliation && { idReconciliation })
  };
};

export const serializedPaymentItems = orderItemsList => {
  return orderItemsList?.map(item => ({
    ...item,
    paymentItemLevels: item?.orderItemLevels?.length ? item?.orderItemLevels : null,
    orderItemLevels: undefined
  }));
};

export const mountOrderItems = ({ list, idOrder, feePercentage }) => {
  const idOrderIsArray = Array.isArray(idOrder);
  return idOrderIsArray
    ? list?.map(order => {
        return {
          name: `${feePercentage ? 'Taxa de administração' : 'Reembolso'} ${order?.code}`,
          quantity: 1,
          price: feePercentage ? order?.performedCost * feePercentage : order?.performedCost,
          idOrder: order.id
        };
      })
    : serializedPaymentItems(list?.[0]?.orderItems);
};

export const tooltipFineInterestDiscount = ({ row, val }) => {
  const reduxState = store.getState();
  const { installmentStatuses, paymentStatuses } = reduxState.setup.enums;
  const { paymentStatus, idInstallmentStatus, fine, interest, discount, originalValue, installments } = row;

  const isPaid =
    installmentStatuses?.paidVobiPay.includes(idInstallmentStatus) || paymentStatus?.id === paymentStatuses?.paid;
  const _discount = formatNumber(paymentStatus ? sumByField(installments, 'discount') : discount);
  const _fine = formatNumber(paymentStatus ? sumByField(installments, 'fine') : fine);
  const _interest = formatNumber(paymentStatus ? sumByField(installments, 'interest') : interest);
  const _originalValue = formatNumber(paymentStatus ? sumByField(installments, 'originalValue') : originalValue);
  return isPaid && (_fine || _interest || _discount) ? (
    <ul style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
      <li>
        <Paragraph type="small" color={colors.white}>
          Valor original:{' '}
          {formatCurrency(_originalValue, {
            currencySymbol: 'R$ '
          })}
        </Paragraph>
      </li>
      {_discount ? (
        <li>
          <Paragraph type="small" color={colors.white}>
            Desconto:{' '}
            {formatCurrency(_discount, {
              currencySymbol: 'R$ '
            })}
          </Paragraph>
        </li>
      ) : null}
      {_fine || _interest ? (
        <li>
          <Paragraph type="small" color={colors.white}>
            Juros e multa:{' '}
            {formatCurrency(_fine + _interest, {
              currencySymbol: 'R$ '
            })}
          </Paragraph>
        </li>
      ) : null}
      <li>
        <Paragraph type="small" color={colors.white}>
          Valor pago:{' '}
          {formatCurrency(val, {
            currencySymbol: 'R$ '
          })}
        </Paragraph>
      </li>
    </ul>
  ) : (
    ''
  );
};

export const conciliateProducts = ({
  paymentToCreate,
  setLoading,
  refurbishItemType,
  handleBulkCreateItems,
  setField,
  setShowProductsConciliation
}) => {
  const { products } = paymentToCreate;
  const itemsToCreate = [];
  setLoading(true);
  products.forEach(item => {
    if (!item.suggestion && !item.item)
      itemsToCreate.push({
        name: item.name,
        type: refurbishItemType.product,
        price: item.price,
        code: item.code,
        units: item.unit,
        idUnit: item.unit?.id,
        quantity: item.quantity
      });
  });
  const _handleBulkCreateItems =
    itemsToCreate.length > 0
      ? handleBulkCreateItems({ values: { items: itemsToCreate }, refresh: false })
      : Promise.resolve([]);

  return _handleBulkCreateItems.then(resp => {
    setLoading(false);
    if (resp?.error) {
      return;
    }
    const itemsCreated = resp.reduce((acc, curr) => ({ ...acc, [curr.name]: curr }), {});
    const paymentItems = [];
    products.forEach(item => {
      const itemSuggested = item.suggestion || item.item;
      if (itemSuggested)
        paymentItems.push({
          id: item.id,
          name: itemSuggested.name,
          type: refurbishItemType.product,
          price: item.price,
          code: itemSuggested.code,
          units: itemSuggested.units,
          idUnit: itemSuggested.units?.id,
          quantity: item.quantity,
          totalPrice: item.totalPrice,
          ...(itemSuggested.model === 'item'
            ? { idItem: itemSuggested?.id }
            : {
                idRefurbishItem: itemSuggested?.id,
                ...(itemSuggested.parentLevel && {
                  paymentItemLevels: [
                    {
                      id: uuidV4(),
                      idParent: item.id,
                      idRefurbishItem: itemSuggested.parentLevel.id,
                      type: itemSuggested.parentLevel.type,
                      refurbishItem: { name: itemSuggested.parentLevel.name, id: itemSuggested.parentLevel.id },
                      quantity: item.quantity,
                      percentage: 100
                    }
                  ]
                })
              })
        });
    });
    itemsToCreate.forEach(item => {
      paymentItems.push({
        id: uuidV4(),
        name: item.name,
        type: refurbishItemType.product,
        price: item.price,
        code: itemsCreated[item.name].code,
        units: itemsCreated[item.name].units,
        idUnit: itemsCreated[item.name].idUnit,
        quantity: item.quantity,
        totalPrice: item.totalPrice,
        idItem: itemsCreated[item.name].id
      });
    });
    setField('name')(paymentToCreate.name);
    setField('billType')(paymentToCreate.billType);
    setField('billingDate')(paymentToCreate.billingDate);
    setField('dueDate')(paymentToCreate.dueDate);
    setField('installments')(paymentToCreate.installments);
    setField('isVobiPay')(paymentToCreate.isVobiPay);
    setField('discount')(paymentToCreate.discount);
    setField('taxes')(paymentToCreate.taxes);
    setField('shipping')(paymentToCreate.shipping);
    setField('otherTaxes')(paymentToCreate.otherTaxes);
    setField('isCharge')(paymentToCreate.isCharge);
    setField('ownBusiness')(paymentToCreate.ownBusiness);
    setField('total')(paymentToCreate.total);
    setField('idFinancialCategory')(paymentToCreate.idFinancialCategory);
    setField('idSupplier')(paymentToCreate.idSupplier);
    setField('newSupplier')(paymentToCreate.newSupplier);
    setField('paymentItems')(paymentItems);
    setField('idRefurbish')(paymentToCreate.idRefurbish);
    setField('importedFromXML')(true);
    setField('openAmount')(paymentToCreate.openAmount);
    setField('invoices')(paymentToCreate.invoices);
    setField('paymentComplete')(paymentToCreate.paymentComplete);

    setShowProductsConciliation(false);
  });
};

export const getBulkDisableType = (isPayment, isTransfer, allInstallments) => {
  const reduxState = store.getState();
  const { installmentStatuses } = reduxState.setup.enums;
  if (isTransfer) {
    return allInstallments?.some(t => t?.idReconciliationIn || t?.idReconciliationOut) && 'reconciliation';
  }
  const hasDisabledVobiPay = installments =>
    installments.some(i => i?.idPaymentAsaas && installmentStatuses.paidVobiPay.includes(i?.idInstallmentStatus));
  const hasDisabledReconciliation = installments => installments.some(i => i?.idReconciliation);
  const vobiPayDisabled = hasDisabledVobiPay(allInstallments) ? 'vobiPay' : null;
  const reconciliationDisabled = hasDisabledReconciliation(allInstallments) ? 'reconciliation' : null;
  if (isPayment) {
    return vobiPayDisabled || reconciliationDisabled;
  }
  const disabledPaid =
    allInstallments.some(i => installmentStatuses.allPaid.includes(i?.idInstallmentStatus)) && 'disabledPaid';
  const _disabledOpen =
    allInstallments.some(i => installmentStatuses.pendingPayment === i?.idInstallmentStatus) && 'disableOpen';

  return { disabledPaid, disabledOpen: _disabledOpen || vobiPayDisabled || reconciliationDisabled };
};

export const prepareDataToDuplicate = data => {
  const reduxState = store.getState();
  const { installmentStatuses, paymentStatuses } = reduxState.setup.enums;
  const cloneData = { ...data };
  const {
    id,
    recurrenceId,
    dueDateDiscount,
    dueDateDiscountType,
    dueDateLimitDays,
    fine,
    fineType,
    interest,
    lastModifiedBy,
    lastRecurrenceDate,
    viewedBy,
    idInstallmentAsaas,
    paymentStatus,
    countFile,
    createdAt,
    updatedAt,
    ..._data
  } = cloneData;

  const installments = !_data.isVobiPay
    ? _data.installments.map(item => ({
        price: item.originalValue || item.price,
        percentage: item.percentage,
        idInstallmentStatus: installmentStatuses.pendingPayment,
        dueDate: item.dueDate,
        description: item.description,
        installmentStatus: {
          id: installmentStatuses.pendingPayment,
          name: 'Aguardando'
        },
        number: item.number,
        idCompany: item.idCompany
      }))
    : [];

  const newProperties = {
    invoices: [],
    tags: [],
    dueDate: dayjs(new Date()).format('YYYY-MM-DD'),
    paymentItems: _data.paymentItems.map(item => {
      const _id = uuidV4();
      const paymentItemLevels = item.paymentItemLevels?.map(itemLevel => ({
        ...itemLevel,
        idParent: _id,
        id: uuidV4()
      }));
      return { ...item, idPayment: null, id: _id, idOrder: null, paymentItemLevels };
    }),
    installmentCount: _data.installments?.length,
    files: [],
    idPaymentStatus: paymentStatuses.open,
    paymentTypes: !_data.isVobiPay ? _data.paymentTypes : _data.paymentTypes.map(item => item.id),
    installments,
    recurrence: {
      frequency: 'month',
      recurrenceCount: 1,
      interval: 1
    }
  };

  return { ..._data, ...newProperties };
};
