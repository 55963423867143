import React from 'react';

import * as dayjs from 'dayjs';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faCircleCheck, faCircleDot, faCircleXmark, faReceipt } from '@fortawesome/pro-regular-svg-icons';
import { Tooltip } from 'antd';
import { getColorByStatusInstallment } from '../../helpers/helper';
import MaskedInput from '../../../components/Input/NewMaskedInput';
import formatCurrency from '../../helpers/formatCurrency';
import formatNumber from '../../helpers/formatNumber';
import DatePicker from '../../../components/Datepicker/Datepicker';
import Input from '../../../components/Input/Input';
import { Div, colors, spaces } from '../../../styles/style';
import PaymentDropDown from '../../../components/Dropdown/PaymentDropDown';
import { paymentChildren } from '../Dropdown/paymentDropdown';
import Button from '../../../components/Button/Button';
import { Error, Paragraph } from '../../../components/Text/Text';
import ItemDetailButton from '../../../components/Button/ItemDetailButton';
import { tooltipFineInterestDiscount } from '../../helpers/payment';

const columns = ({
  numberOfInstalments,
  handleChange,
  installmentStatuses,
  isView,
  disabledToCreditType,
  installmentHandleBlur,
  errors,
  touched,
  handleBlur,
  showInstallmentActions,
  idRefurbish,
  valuesChanged,
  handleSave,
  isCharge,
  billType,
  handleSubmitDropdown,
  isCustomerView,
  showCustomerDetails,
  handleHighlight,
  setInstallmentToPay,
  installmentToPay,
  isVobiPay,
  isPublic,
  handleOpenModalFiles
}) => {
  const allColumns = [
    ...(showCustomerDetails
      ? [
          {
            title: '',
            key: 'radio',
            width: 50,
            className: 'col-grow-1 icon-column',
            render: (_, row) => {
              const installmentPaid = row?.idInstallmentStatus !== installmentStatuses.pendingPayment;
              const isCancelled = row?.idInstallmentStatus === installmentStatuses.cancelled;
              const selectedRow = row?.id && (isCustomerView || isPublic) && isVobiPay && row?.id === installmentToPay;
              if (selectedRow) {
                return <FontAwesomeIcon icon={faCircleDot} height={16} width={16} color={colors.primary600} />;
              }

              if (isCancelled) {
                return <FontAwesomeIcon icon={faCircleXmark} height={16} width={16} color={colors.red600} />;
              }

              if (installmentPaid) {
                return <FontAwesomeIcon icon={faCircleCheck} height={16} width={16} color={colors.green400} />;
              }
              return (
                <FontAwesomeIcon
                  icon={faCircle}
                  height={16}
                  width={16}
                  color={colors.neutral300}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    handleHighlight();
                    setInstallmentToPay(row?.id);
                  }}
                />
              );
            }
          }
        ]
      : []),
    {
      title: 'Parcela',
      width: 67,
      dataIndex: 'number',
      key: 'number',
      className: 'col-grow-1',
      render: (_, row, index) => (
        <Paragraph type="small" id={`installment-${index + 1}`}>{`${index + 1}/${numberOfInstalments}`}</Paragraph>
      )
    },
    {
      title: 'Valor',
      dataIndex: 'price',
      width: 120,
      key: 'price',
      className: 'col-grow-1',
      render: (val, row, index) => {
        return (
          <Tooltip title={tooltipFineInterestDiscount({ row, val })}>
            {!isView ? (
              <MaskedInput
                disabled={
                  installmentStatuses.allPaid.includes(row.idInstallmentStatus) ||
                  disabledToCreditType ||
                  row.idInstallmentStatus === installmentStatuses.cancelled
                }
                type="currency"
                id={`installmentValue-${index + 1}`}
                value={formatCurrency(val, {
                  currencySymbol: 'R$ '
                })}
                onChange={e => handleChange(index, 'price', formatNumber(e.target.value))}
                onBlur={installmentHandleBlur}
                maskOptions={{
                  prefix: 'R$',
                  thousandsSeparatorSymbol: '.',
                  allowDecimal: true
                }}
              />
            ) : (
              <Paragraph type="small" id="installmentValue">
                {formatCurrency(val, {
                  currencySymbol: 'R$ '
                })}
              </Paragraph>
            )}
            {!isView && errors[`installments[${index}].price`] && (
              <Error>{errors[`installments[${index}].price`]}</Error>
            )}
          </Tooltip>
        );
      }
    },
    ...(!isView
      ? [
          {
            title: 'Percentual',
            dataIndex: 'percentage',
            width: 100,
            key: 'percentage',
            className: 'col-grow-1',
            render: (val, row, index) => {
              return (
                <>
                  <MaskedInput
                    disabled={
                      installmentStatuses.allPaid.includes(row.idInstallmentStatus) ||
                      disabledToCreditType ||
                      row.idInstallmentStatus === installmentStatuses.cancelled
                    }
                    type="number"
                    id="installmentPercent"
                    value={formatCurrency(val, {
                      currencySymbol: ''
                    })}
                    onChange={e => handleChange(index, 'percentage', formatNumber(e.target.value))}
                    onBlur={installmentHandleBlur}
                    maskOptions={{
                      suffix: '%',
                      prefix: '',
                      includeThousandsSeparator: false,
                      allowDecimal: true
                    }}
                  />
                  {touched.installments && errors[`installments[${index}].percentage`] && (
                    <Error>{errors[`installments[${index}].percentage`]}</Error>
                  )}
                </>
              );
            }
          }
        ]
      : []),
    {
      title: 'Vencimento',
      dataIndex: 'dueDate',
      width: 135,
      key: 'childDueDate',
      className: 'col-grow-1',
      render: (val, row, index) => {
        return (
          <>
            {!isView ? (
              <DatePicker
                disabled={
                  installmentStatuses.allPaid.includes(row.idInstallmentStatus) ||
                  disabledToCreditType ||
                  row.idInstallmentStatus === installmentStatuses.cancelled
                }
                id={`installmentDueDate-${index + 1}`}
                name="installmentDueDate"
                style={{ width: '100%' }}
                format="DD/MM/YYYY"
                allowClear={false}
                value={val ? dayjs(val) : null}
                onChange={value => handleChange(index, 'dueDate', value)}
              />
            ) : (
              <Paragraph type="small" id={`installmentDueDate-${index + 1}`}>
                {val ? dayjs(val).format('DD/MM/YYYY') : ''}
              </Paragraph>
            )}
            {!isView && errors[`installments[${index}].dueDate`] && (
              <Error>{errors[`installments[${index}].dueDate`]}</Error>
            )}
          </>
        );
      }
    },
    {
      title: 'Descrição',
      dataIndex: 'description',
      key: 'description',
      className: 'col-grow-3',
      renderMobile: true,
      render: (val, row, index) => {
        return (
          <>
            {!isView ? (
              <Input
                disabled={
                  installmentStatuses.allPaid.includes(row.idInstallmentStatus) ||
                  row.idInstallmentStatus === installmentStatuses.cancelled
                }
                type="text"
                id={`installmentDescription-${index + 1}`}
                size="middle"
                name="installmentDescription"
                placeholder="Descrição do item"
                value={val}
                onBlur={handleBlur('installments')}
                onChange={e => handleChange(index, 'description', e.target.value)}
              />
            ) : (
              <Paragraph type="small" id={`installmentDescription-${index + 1}`}>
                {val}
              </Paragraph>
            )}
            {!isView && errors[`installments[${index}].description`] && (
              <Error>{errors[`installments[${index}].description`]}</Error>
            )}
          </>
        );
      }
    },
    ...(isView
      ? [
          {
            title: 'Pagamento',
            dataIndex: 'paidDate',
            key: 'paidDate',
            width: 100,
            className: 'col-grow-3',
            render: (val, _, index) => {
              return (
                <Paragraph type="small" id={`installmentPaidDate-${index + 1}`}>
                  {val ? dayjs(val).format('DD/MM/YY') : ''}
                </Paragraph>
              );
            }
          }
        ]
      : []),
    {
      title: 'Status',
      dataIndex: 'installmentStatus',
      key: 'installmentstatus',
      width: 70,
      className: 'col-grow-1',
      renderMobile: true,
      render: (val, row, index) => {
        const isDefeated =
          dayjs(row.dueDate).isBefore(dayjs(new Date()), 'day') &&
          row.idInstallmentStatus === installmentStatuses.pendingPayment;
        const color = isDefeated
          ? colors.red500
          : getColorByStatusInstallment(row.idInstallmentStatus, installmentStatuses);
        return (
          <Paragraph type="small" color={color} id={`installmentStatus-${index + 1}`}>
            {isDefeated ? 'Vencido' : val?.name || ''}
          </Paragraph>
        );
      }
    },
    ...(showInstallmentActions()
      ? [
          {
            title: 'Ações',
            key: 'actions',
            width: 50,
            className: 'col-grow-1',
            render: (_, row, index) => {
              return showInstallmentActions(row) ? (
                <PaymentDropDown
                  key={`action${row.id}`}
                  item={row}
                  idRefurbish={idRefurbish}
                  mappingObj={paymentChildren({
                    installment: row,
                    valuesChanged,
                    handleSave,
                    isCharge,
                    billType,
                    touched,
                    drawerZindex: 1000,
                    isVobiPay: !!row?.idPaymentAsaas
                  })}
                  showConciliation
                  showReceipt
                  afterSubmit={(params, args) => {
                    const data = { ...params, ...args };
                    handleSubmitDropdown(data);
                    if (data?.actionKey === 'receipt' && data?.idReceipt) {
                      window.open(`${window.location.origin}/rc/${data?.idReceipt}`, '_blank');
                    }
                  }}
                  isCustomerView={isCustomerView}
                  id={`installmentActions-${index + 1}`}
                  onItemClick={f => f}
                  isDrawer
                />
              ) : null;
            },
            shouldCellUpdate: (prevRecord, nextRecord) => {
              return (
                prevRecord.idInstallmentStatus !== nextRecord.idInstallmentStatus ||
                prevRecord.countFile !== nextRecord.countFile ||
                prevRecord.idReconciliation !== nextRecord.idReconciliation ||
                prevRecord.idReceipt !== nextRecord.idReceipt
              );
            }
          }
        ]
      : []),
    ...(showCustomerDetails || isPublic
      ? [
          {
            title: '',
            key: 'pay',
            width: 100,
            className: 'col-grow-1',
            renderMobile: true,
            render: (_, row, index) => {
              const installmentPaid = row?.idInstallmentStatus !== installmentStatuses.pendingPayment;
              const selectedRow = row?.id && (isCustomerView || isPublic) && isVobiPay && row?.id === installmentToPay;
              return (
                <Div gap={spaces.space2} justify="end">
                  {row?.idReceipt ? (
                    <ItemDetailButton
                      icon={faReceipt}
                      tooltipProps={{ id: `show-files-installment-${index + 1}` }}
                      titleTooltip="Ver recibo"
                      onClick={() => window.open(`${window.location.origin}/rc/${row?.idReceipt}`, '_blank')}
                    />
                  ) : null}
                  {row?.files?.length ? (
                    <ItemDetailButton
                      icon="faPaperclip"
                      tooltipProps={{ id: `show-files-installment-${index + 1}` }}
                      titleTooltip="Ver anexos"
                      onClick={() => handleOpenModalFiles(row?.files)}
                    />
                  ) : null}
                  {!installmentPaid && row?.idPaymentAsaas ? (
                    <Button
                      className="hide-on-print"
                      type="primary"
                      text={!selectedRow}
                      onClick={() => {
                        handleHighlight();
                        setInstallmentToPay(row?.id);
                      }}
                    >
                      Pagar
                    </Button>
                  ) : (
                    <div />
                  )}
                </Div>
              );
            }
          }
        ]
      : [])
  ];

  return allColumns;
};

export { columns };
