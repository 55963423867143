import React, { useEffect, useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import parse from 'html-react-parser';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, Link } from 'react-router-dom';
import { Typography, Space, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReceipt, faChevronUp, faChevronDown, faFolder, faCog, faGear } from '@fortawesome/pro-regular-svg-icons';
import { faCircleInfo, faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import useViewport from '../../../_Hooks/useViewport';
import { useContextHook } from '../../../contexts/Context';

import Content from '../../../components/Content/Content';
import Box from '../../../components/Box/Box';
import Loader from '../../../components/Loader/CenteredLoader';
import Form from '../../../components/Form/Form';
import ImageInput from '../../../components/Input/ImageInput';
import Button from '../../../components/Button/Button';
import UseAsTemplateModal from '../../../components/Modal/UseProjectAsTemplateModal';

import { schema, templateMapping } from '../../../lib/mapping/Form/templateSchema';
import { colors, spaces, fonts } from '../../../styles/style';
import { TabPane } from '../../../components/Tabs/Tabs';
import Notes from '../../Opportunities/Notes';
import RenderFolder from '../../../components/Folder/RenderFolder';
import ScheduleTable from '../../../components/Table/ScheduleTable';
import Dropdown from '../../../components/Dropdown/Dropdown';
import SubItemsTemplateMenu from './SubItemsTemplateMenu';
import EditModalFactory from '../../../components/EditModalFactory';
import useSeeMore from '../../../_Hooks/useSeeMore';
import SpecificationTemplate from './SpecificationTemplate';
import SpecificationConfigModal from '../../../components/Modal/SpecificationConfigModal';
import { saveHeaderName } from '../../../store/headerName/actions/action';

import {
  NameAndDescriptionContainer,
  SubHeaderContainer,
  TemplateInfosContainer,
  StyledTitle,
  StyledSubTitle,
  ConfigButtonContainer,
  StyledTabPane,
  StyledTabs,
  StyledDescription,
  TypeContainer
} from './EditOrCreateTemplate.styled';
import PlanningConfigModal from '../../../components/Modal/PlanningConfigModal';
import TemplatePlanningTable from '../../../components/Table/TemplatePlanningTable';

const { Text } = Typography;

const templateTaskOptions = idTemplate => ({
  where: { idTemplate },
  order: [['order', 'ASC NULLS FIRST']]
});

const EditOrCreateTemplate = ({ idApply, setConfirmApply, community, subItemsProps }) => {
  const { isMobile } = useViewport(window.innerWidth);
  const _isMobile = isMobile();
  const { user } = useSelector(state => state.authReducer);
  const { userType, templateModule } = useSelector(state => state.setup.enums);
  const headerName = useSelector(state => state.headerNameReducer);
  const isOperator = user.userType === userType.operator.value;
  const { id: _idTemplate } = useParams();
  const history = useHistory();
  const isApply = !!idApply;
  const idTemplate = idApply || _idTemplate;
  const isCreateTemplate = !idTemplate;
  const [seeMore, setSeeMore] = useState(false);
  const [showMoreInfo, setShowMoreInfo] = useState(!_isMobile);
  const [selectedTab, setSelectedTab] = useState('schedule');
  const [showModalFolder, setShowModalFolder] = useState(false);
  const [showModalCopyProject, setShowModalCopyProject] = useState(false);
  const [editItemModalParams, setEditItemModalParams] = useState({});
  const { subItems, setSubItems } = subItemsProps || {};
  const [createComposition, setCreateComposition] = useState({ show: false });
  const [showConfigModal, setShowConfigModal] = useState(false);
  const dispatch = useDispatch();

  const loadAll = useRef(0);

  const title = isCreateTemplate
    ? {
        name: 'Criar template',
        pathName: '/profissional/ferramentas/meus-templates/novo'
      }
    : {
        name: 'Atualizar template',
        pathName: '/profissional/ferramentas/meus-templates/editar'
      };

  const {
    data,
    loading,
    setLoading,
    handleGet,
    handleCreate: handleCreateTemplate,
    handleUpdate: handleUpdateTemplate
  } = useContextHook();

  const { ref, hasOverflow } = useSeeMore();

  useEffect(() => {
    if (!isApply && headerName?.name !== title.name) {
      dispatch(saveHeaderName({ name: title.name, pathName: [title.pathName] }));
    }
  }, [isCreateTemplate]);

  const isBudgetTemplate = templateModule.budget.value;

  const onSubmit = submitData => {
    const CreateOrUpdate = isCreateTemplate
      ? handleCreateTemplate({ values: submitData, refresh: false, displayToast: true })
      : handleUpdateTemplate({
          id: idTemplate,
          values: submitData,
          displayToast: true,
          noLoading: true,
          refresh: false
        });

    CreateOrUpdate.then(({ id, error }) => {
      if (!error && isCreateTemplate) {
        history.push(`editar/${id}`);
      }
    });
  };

  useEffect(() => {
    if (!loading) {
      setSelectedTab(prev => (data?.module === isBudgetTemplate ? 'specification' : prev));
    }
  }, [loading]);

  const refetchTemplate = () => {
    if (idTemplate) {
      handleGet({
        refetchOptions: {
          where: { community },
          include: [{ model: 'company', required: isOperator ? false : !isApply }]
        },
        refetchPathOptions: idTemplate ? `/${idTemplate}` : ''
      });
    } else {
      handleGet();
    }
  };

  useEffect(refetchTemplate, [idTemplate]);

  const handleCopyProjectModal = () => {
    setShowModalCopyProject(!showModalCopyProject);
  };

  const afterSubmitProjectModal = () => {
    loadAll.current += 1;
    handleCopyProjectModal();
    refetchTemplate();
  };

  const _mapping = useMemo(
    () =>
      templateMapping({
        isMobile: _isMobile,
        idTemplate,
        isOperator,
        templateModule: data?.module,
        templateModuleOptions: templateModule,
        handleModal: handleCopyProjectModal,
        showTemplateModule: true
      }),
    [idTemplate, data]
  );

  const _onSubmit = useMemo(() => onSubmit, [idTemplate]);
  const referOptions = useMemo(() => templateTaskOptions(idTemplate), [idTemplate]);
  const stepOptions = useMemo(
    () => ({
      where: {
        '$templateStep.id$': null,
        id: { gte: 2 },
        ...(isOperator && { idCompany: null })
      },
      include: [{ model: 'templateStep', where: { idTemplate }, required: false }],
      order: [['order', 'ASC']],
      onlyMine: !isOperator
    }),
    [idTemplate]
  );

  const SubItemsDropdown = () => {
    return subItems && data?.module !== isBudgetTemplate ? (
      <Dropdown
        trigger={['click']}
        overlayStyle={{ zIndex: 1001 }}
        menu={<SubItemsTemplateMenu initialItems={subItems} onClose={newSubItems => setSubItems(newSubItems)} />}
      >
        <Space id="template-apply-config" size="middle">
          <FontAwesomeIcon icon={faCog} color={colors.primary600} />
          {!_isMobile ? (
            <>
              <Text style={{ color: colors.primary600 }}>Configurar o que aplicar</Text>
              <FontAwesomeIcon icon={faCaretDown} color={colors.primary600} />
              <Tooltip
                placement="bottom"
                title="Caso deseje, você pode escolher os módulos que deseja aplicar ao utilizar este template"
              >
                <FontAwesomeIcon icon={faCircleInfo} color={colors.primary600} />
              </Tooltip>
            </>
          ) : null}
        </Space>
      </Dropdown>
    ) : (
      <>
        {(selectedTab === 'specification' || selectedTab === 'planning') && !isApply ? (
          <ConfigButtonContainer>
            <FontAwesomeIcon
              icon={faGear}
              onClick={() => setShowConfigModal(selectedTab)}
              style={{ color: colors.primary600, cursor: 'pointer' }}
              size="lg"
            />
          </ConfigButtonContainer>
        ) : null}
      </>
    );
  };

  const tasksTab = () => (
    <StyledTabPane key="schedule" tab="Tarefas">
      {selectedTab === 'schedule' && (
        <ScheduleTable
          idReference={idTemplate}
          referModel="template"
          referKey="idTemplate"
          isApply={isApply}
          referOptions={referOptions}
          stepOptions={stepOptions}
          template
          expandEmpty
          lazyLoad
          disableSelection
        />
      )}
    </StyledTabPane>
  );

  const budgetTab = () => (
    <StyledTabPane
      key="specification"
      tab="Orçamento"
      paddingContent="0px 3px 0px 3px"
      backgroundContent={colors.neutral100}
      forceRender
    >
      <div style={{ padding: 8, background: 'white' }}>
        {!loading && selectedTab === 'specification' && (
          <SpecificationTemplate data={data} isApply={isApply} scroll={isApply ? { y: 340, x: '100%' } : undefined} />
        )}
      </div>
    </StyledTabPane>
  );

  const planningTab = () => (
    <StyledTabPane
      key="planning"
      tab="Planejamento"
      paddingContent="0px 3px 0px 3px"
      backgroundContent={colors.neutral100}
      forceRender
    >
      <div style={{ padding: 8, background: 'white' }}>
        {selectedTab === 'planning' && <TemplatePlanningTable data={data} isApply={isApply} />}
      </div>
    </StyledTabPane>
  );

  const filesTab = () => (
    <TabPane key="file" tab="Arquivos">
      <Content bgColor="white">
        <RenderFolder
          idReference={idTemplate}
          referKey="idTemplate"
          showModalFolder={showModalFolder}
          setShowModalFolder={setShowModalFolder}
          isApply={isApply}
          forceLoad={loadAll.current}
        />
        {!isApply && (
          <Button
            id="add-folder-to-template"
            text
            style={{ color: colors.primary600, marginTop: spaces.space3 }}
            onClick={() => setShowModalFolder(true)}
          >
            + Adicionar pasta à este template
          </Button>
        )}
      </Content>
    </TabPane>
  );

  const notesTab = () => (
    <TabPane key="notes" tab="Anotações">
      <div style={{ background: 'white' }}>
        <Notes idTemplate={idTemplate} isApply={isApply} forceLoad={loadAll.current} />
      </div>
    </TabPane>
  );

  return (
    <>
      <Content padding={!isApply && '0'}>
        {loading ? (
          <Loader text="Carregando..." />
        ) : (
          <>
            {isApply ? (
              <Content style={{ display: 'flex', flexDirection: _isMobile ? 'column' : 'row' }} padding={0}>
                {!_isMobile ? (
                  <div>
                    <ImageInput key={`image${data?.id}`} id={data?.id} value={data?.image} disabled size="176px" />
                  </div>
                ) : null}
                <NameAndDescriptionContainer>
                  <SubHeaderContainer>
                    <Text style={{ fontSize: fonts.sizeMd }}>{data?.name || '-'}</Text>
                    <TypeContainer>
                      <FontAwesomeIcon icon={data?.module === templateModule.budget.value ? faReceipt : faFolder} />
                      {data?.module === templateModule.budget.value ? 'Orçamento' : 'Completo'}
                    </TypeContainer>
                  </SubHeaderContainer>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <StyledDescription showAll={seeMore} ref={ref}>
                      {parse(data?.description || '')}
                    </StyledDescription>
                    {hasOverflow && (
                      <Button
                        text
                        type="primary"
                        onClick={() => setSeeMore(!seeMore)}
                        align="end"
                        padding={`0 ${spaces.space0}`}
                      >
                        {seeMore ? 'ver menos' : 'ver mais'}
                      </Button>
                    )}
                    {_isMobile ? (
                      <>
                        <Button fullWidth type="primary" onClick={() => setConfirmApply(idApply)}>
                          Usar template
                        </Button>
                        <Button text onClick={() => setShowMoreInfo(!showMoreInfo)}>
                          {showMoreInfo ? 'Mostrar menos informações' : 'Mostrar mais informações'}
                          <FontAwesomeIcon icon={showMoreInfo ? faChevronUp : faChevronDown} />
                        </Button>
                      </>
                    ) : null}
                  </div>
                </NameAndDescriptionContainer>
                {showMoreInfo ? (
                  <TemplateInfosContainer id="template-info-and-options">
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <StyledTitle>
                        Criado por: <StyledSubTitle>{data?.company ? data.company.name || '-' : 'Vobi'}</StyledSubTitle>
                      </StyledTitle>
                      <StyledTitle margin={`${spaces.space1} 0 0`}>
                        Criado em:{' '}
                        <StyledSubTitle>
                          {data?.createdAt ? dayjs(data?.createdAt).format('DD/MM/YYYY') : '-'}
                        </StyledSubTitle>
                      </StyledTitle>
                    </div>
                    {!_isMobile ? (
                      <div>
                        {!data?.idCompany || data?.community ? null : (
                          <Link to={`/${user.type}/ferramentas/meus-templates/editar/${data?.id}`} target="_blank">
                            <Button
                              id="edit-template"
                              style={{
                                height: spaces.space4,
                                fontSize: fonts.sizeMd
                              }}
                              size="small"
                              type="primary"
                              ghost
                              width="152px"
                            >
                              Editar template
                            </Button>
                          </Link>
                        )}
                        <Button
                          id="use-template"
                          style={{
                            marginTop: spaces.space1,
                            height: spaces.space4,
                            fontSize: fonts.sizeMd
                          }}
                          size="small"
                          type="primary"
                          onClick={() => setConfirmApply(idApply)}
                          width="152px"
                        >
                          Usar template
                        </Button>
                      </div>
                    ) : null}
                  </TemplateInfosContainer>
                ) : null}
              </Content>
            ) : (
              <Box style={{ borderRadius: `${spaces.space1} ${spaces.space1} 0 0`, padding: spaces.space2 }}>
                <Form
                  id="template"
                  mapping={_mapping}
                  schema={schema(false, true)}
                  customButtonTitle={isCreateTemplate ? 'Próximo' : null}
                  onSubmit={_onSubmit}
                  data={data}
                  keepOldValues
                  saveWarningMessage="Existem alterações nos detalhes deste template que precisam ser salvas."
                  marginTop={_isMobile ? '0' : '-20px'}
                />
              </Box>
            )}
          </>
        )}
        {data?.id && idTemplate && !loading ? (
          <StyledTabs
            activeKey={selectedTab}
            onChange={key => setSelectedTab(key)}
            tabBarExtraContent={<SubItemsDropdown />}
          >
            {data?.module !== isBudgetTemplate ? (
              <>
                {tasksTab()}
                {budgetTab()}
                {planningTab()}
                {filesTab()}
                {notesTab()}
              </>
            ) : (
              <>{budgetTab()}</>
            )}
          </StyledTabs>
        ) : null}
        {showModalCopyProject && data ? (
          <UseAsTemplateModal
            title="Escolha o Projeto"
            model="template"
            pathOptions="/refurbishToTemplate"
            textHeader="Você poderá selecionar os módulos que deseja utilizar do projeto selecionado e eles
          serão adicionados neste template."
            successText="Os itens foram adicionados neste template com sucesso"
            text="Escolha o que deseja em seu template."
            source="Template"
            modalType="ApplyProject"
            template={data}
            onClose={() => handleCopyProjectModal()}
            afterSubmit={() => afterSubmitProjectModal()}
          />
        ) : null}
      </Content>

      {showConfigModal === 'specification' && (
        <SpecificationConfigModal
          data={data}
          onSubmit={values => {
            setLoading(true);
            handleUpdateTemplate({
              id: idTemplate,
              values,
              refresh: false,
              displayToast: true,
              noLoading: true
            }).then(() => refetchTemplate()?.then(() => setLoading(false)));
          }}
          onClose={() => setShowConfigModal(false)}
        />
      )}
      {showConfigModal === 'planning' && (
        <PlanningConfigModal
          isTemplate
          data={data}
          onClose={() => setShowConfigModal(false)}
          onSubmit={values => {
            setLoading(true);
            handleUpdateTemplate({
              id: idTemplate,
              values,
              refresh: false,
              displayToast: true,
              noLoading: true
            }).then(() => refetchTemplate()?.then(() => setLoading(false)));
          }}
        />
      )}
      {editItemModalParams.id || createComposition.show ? (
        <EditModalFactory
          {...editItemModalParams}
          idReference={idTemplate}
          model="template-item"
          onClose={() => {
            setEditItemModalParams({});
            setCreateComposition({ show: false });
          }}
          isTemplate
          width={960}
          parentData={data}
          readOnly={isApply}
        />
      ) : null}
    </>
  );
};

EditOrCreateTemplate.propTypes = {
  idApply: PropTypes.number,
  setConfirmApply: PropTypes.func,
  community: PropTypes.bool,
  subItemsProps: PropTypes.instanceOf(Object)
};

export default EditOrCreateTemplate;
