import React from 'react';
import * as dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import toObject from 'dayjs/plugin/toObject';
import isBetween from 'dayjs/plugin/isBetween';
import saveAs from 'save-as';
import { toast } from 'react-toastify';

import * as XLSX from 'xlsx';
import DocumentFileIcon from '../../components/Icons/DocumentFile';
import PowerpointFileIcon from '../../components/Icons/PowerpointFile';
import PhotoshopFileIcon from '../../components/Icons/PhotoshopFile';
import PdfFileIcon from '../../components/Icons/PdfFile';
import XLSFileIcon from '../../components/Icons/XLSFile';
import UnknownFileIcon from '../../components/Icons/UnknownFile';
import formatCurrency from './formatCurrency';
import formatNumber from './formatNumber';
import { colors } from '../../styles/style';
import { replaceReaders, translateModelExport } from './exportHelperFunctions';

dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(toObject);
dayjs.extend(isBetween);

const bytesToSize = bytes => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  return `${Math.round(bytes / 1024 ** i, 2)} ${sizes[i]}`;
};

const removeAccents = str =>
  (str &&
    str
      .toString()
      .toLowerCase()
      .replace(/[àáâãäå]/g, 'a')
      .replace(/[èéêë]/g, 'e')
      .replace(/[ìíîï]/g, 'i')
      .replace(/[òóôõö]/g, 'o')
      .replace(/[ùúûü]/g, 'u')
      .replace(/[ýÿ]/g, 'y')
      .replace(/æ/g, 'ae')
      .replace(/œ/g, 'oe')
      .replace(/ñ/g, 'n')
      .replace(/ç/g, 'c')) ||
  str;

const getFileType = fullType => {
  if (!fullType) return null;
  const split = fullType.split('.');
  return split[split.length - 1];
};

const getFileIcon = ({ filename, width, height, imageElement }) => {
  const ext = (filename && filename.split('.').pop()) || '';

  switch (ext.toLowerCase()) {
    case 'pdf': {
      return <PdfFileIcon width={width} height={height} />;
    }
    case 'pptx':
    case 'ppt': {
      return <PowerpointFileIcon width={width} height={height} />;
    }
    case 'psd': {
      return <PhotoshopFileIcon width={width} height={height} />;
    }
    case 'doc':
    case 'docx': {
      return <DocumentFileIcon width={width} height={height} />;
    }
    case 'xls': {
      return <XLSFileIcon width={width} height={height} />;
    }
    case 'jpg':
    case 'jpeg':
    case 'png':
    case 'svg':
    case 'jfif':
    case 'gif': {
      return imageElement;
    }
    default: {
      return <UnknownFileIcon width={width} height={height} />;
    }
  }
};

const getFileName = fullpath => {
  if (!fullpath) {
    return '';
  }
  const { pathname } = new URL(fullpath);
  const paths = pathname.split('/');
  let path = paths[paths.length - 1];
  if (path.includes('-copy-')) {
    [, path] = path.split('-copy-');
  }
  return decodeURIComponent(path);
};

const getFileNameFromURL = urlString => {
  if (!urlString) return '';
  return decodeURIComponent(new URL(urlString).pathname.split('/').pop());
};

const isImgUrl = url =>
  // eslint-disable-next-line
  /(((https?:\/\/)|(\/\/)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/.test(
    url
  );

const isImgIcon = url => !isImgUrl(url);

const saveFilesForm = ({ files, handleCreate, isPublic }) => {
  const _files = Array.from(files) || [];

  return Promise.all(
    _files.map(file => {
      const formData = new FormData();
      formData.append('file', file);

      if (isPublic) {
        formData.append('isPublic', 'isPublic');
      }
      return handleCreate({ values: formData, refresh: false });
    })
  ).then(result => result.map(res => res.location || res.fullpath));
};

const parseSearchableOptions = (opts, customLabel, customValue, extraPropsOnOptions) =>
  opts && opts.constructor === Array
    ? opts.map(opt => {
        let label = opt?.name || opt?.label;
        if (customLabel && typeof customLabel === 'function') {
          label = customLabel(opt);
        } else if (customLabel && opt?.[customLabel]) {
          label = opt?.[customLabel]?.constructor === Object ? opt?.[customLabel].name : opt?.[customLabel];
          label = label?.trim() ? label?.trim() : opt?.name;
        }
        const { color, where } = opt;
        const _customValue = opt?.[customValue] === '' ? null : opt?.[customValue];
        const id = opt?.id === '' ? null : opt?.id;
        const value = opt?.value === '' ? null : opt?.value;
        const extraProps = {};
        if (extraPropsOnOptions?.length) {
          extraPropsOnOptions?.forEach(prop => {
            extraProps[prop] = opt[prop];
          });
        }
        return {
          value: _customValue ?? id ?? value,
          label: label || '-',
          color,
          where,
          ...extraProps
        };
      })
    : [];

const getSelectItem = ({ value, opts }) => {
  return opts.find(o => o.value === value) || {};
};

const capitalize = s => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const toKebabCase = str => str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();

const padLeft = (string, length) => (Array(length + 1).join('0') + string)?.slice(-length);

const sumByField = (values, field, conditionalField) =>
  values?.map(item => Number(item[conditionalField] || item[field] || 0)).reduce((next, prev) => next + prev, 0);

const supportedExtensions = ['jpg', 'jpeg', 'svg+xml', 'png', 'pdf', 'jfif', 'webp'];
const isExtensionSupported = extension =>
  supportedExtensions.map(s => s.toLowerCase()).includes(extension.toLowerCase());

const getProperty = (obj, path) => {
  if (!path || !obj) return obj;
  const properties = path.split('.');
  return getProperty(obj[properties.shift()], properties.join('.'));
};

const formatSinceTime = date => {
  const createdDate = new Date(date);
  const hoursSinceCreatedDate = (new Date().getTime() - createdDate.getTime()) / (1000 * 60 * 60);

  if (hoursSinceCreatedDate > 48) {
    return `${dayjs(createdDate).format('DD [de] MMMM, YYYY [às] HH:mm')}`;
  }

  return dayjs(createdDate).fromNow();
};

const getInitials = name =>
  name
    ?.split(' ')
    ?.map(s => s.charAt(0))
    ?.join('')
    ?.slice(0, 2);

const hexToRgba = (hex, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
};

const transformResponsibleFilter = filter => {
  const { idResponsible, ..._filter } = filter || {};
  return idResponsible?.includes(null)
    ? {
        ..._filter,
        idResponsible: { or: [...idResponsible, null] }
      }
    : filter;
};

const exportCSV = ({ filePrefix, resp }) => {
  const link = window.document.createElement('a');
  link.setAttribute('href', `data:text/csv;charset=utf-8,%EF%BB%BF${encodeURIComponent(resp)}`);
  link.setAttribute(
    'download',
    `${translateModelExport[filePrefix] || filePrefix}_${dayjs().format('DD-MM-YYYY_HH-mm-ss')}.csv`
  );
  link.click();
};

const exportXlsx = ({ filePrefix, data }) => {
  const _data = Array.isArray(data) ? data : data.items;
  const customHeader = !Array.isArray(data) && data.customHeader;
  const mergeCell = !Array.isArray(data) && data.mergeCell;
  const origin = !Array.isArray(data) && data.origin;
  const wb = XLSX.utils.book_new();
  const ws = XLSX.utils.json_to_sheet([]);
  let header = replaceReaders[filePrefix];
  if (customHeader && Array.isArray(customHeader[0])) {
    header = customHeader;
  } else if (customHeader) header = [customHeader];
  if (mergeCell) {
    ws['!merges'] = mergeCell;
  }

  XLSX.utils.sheet_add_aoa(ws, header);
  XLSX.utils.sheet_add_json(ws, _data, { origin: origin || 'A2', skipHeader: true });
  XLSX.utils.book_append_sheet(wb, ws, 'Exportado');

  XLSX.writeFile(wb, `${translateModelExport[filePrefix] || filePrefix}_${dayjs().format('DD-MM-YYYY_HH-mm-ss')}.xlsx`);
};

const ExportTypes = {
  csv: 'csv',
  xlsx: 'xlsx',
  pdf: 'pdf'
};

const exportToModel = ({
  where,
  include,
  handleGet,
  refetchOptions,
  order = [['createdAt', 'desc']],
  model = 'Arquivo',
  prefixName,
  exportType,
  generateLoading,
  customHeader,
  modelExport,
  customerView,
  pathPrefix,
  extraOptions
}) => {
  return handleGet({
    refetchPathOptions: `${pathPrefix ? `/${pathPrefix}` : ''}/export${exportType ? `/${exportType}` : ''}${
      modelExport ? `/${modelExport}` : ''
    }`,
    refetchOptions: refetchOptions || {
      where,
      include,
      order,
      customerView,
      ...(extraOptions && { ...extraOptions })
    },
    generateLoading,
    keepState: true
  }).then(resp => {
    if (resp?.error) {
      toast.error(resp?.error.message);
      return null;
    }
    if (exportType === 'xlsx') {
      const dataToExport = customHeader
        ? {
            items: resp,
            customHeader
          }
        : resp;

      return exportXlsx({
        filePrefix: prefixName || modelExport || model,
        data: dataToExport
      });
    }
    return exportCSV({ filePrefix: prefixName || model, resp });
  });
};

const maskHours = hours => {
  const _hours = hours.split(':')[0];
  if (parseInt(_hours, 10) <= 99) return '99';
  if (parseInt(_hours, 10) > 99) return '999';
  return '999';
};

const getMinutesFromHHMM = value => {
  if (typeof value !== 'string') return value;
  const [str1, str2] = value.split(':');

  const val1 = Math.round(Number(str1?.replace(',', '.')));
  const val2 = Math.round(Number(str2?.replace(',', '.')));

  if (!Number.isNaN(val1) && Number.isNaN(val2)) {
    return val1;
  }

  if (!Number.isNaN(val1) && !Number.isNaN(val2)) {
    return val1 * 60 + val2;
  }

  return 0;
};

const toHHMM = mins => {
  if (!mins) return null;
  const minNum = parseInt(mins.toString(), 10);
  const hours = Math.floor(minNum / 60);
  const minutes = Math.floor(minNum) % 60;

  return [hours, minutes]
    .map(val => (val < 10 ? `0${val}` : val))
    .join(':')
    .replace(/^0/, '');
};

const groupTaskWithDate = list => {
  if (!list?.length) return {};
  return list?.reduce((acc, cur) => {
    if (!acc[dayjs(cur.createdAt).format('DD MMMM, YYYY')]) {
      acc[dayjs(cur.createdAt).format('DD MMMM, YYYY')] = [];
    }
    acc[dayjs(cur.createdAt).format('DD MMMM, YYYY')].push(cur);
    return acc;
  }, {});
};

const getColorByStatusPayment = (idStatus, status) => {
  const _idStatus = status?.allPaid?.includes(idStatus) ? status?.paid : idStatus;
  switch (_idStatus) {
    case status?.draft: {
      return colors.primary600;
    }
    case status?.open: {
      return colors.orange400;
    }
    case status?.paid: {
      return colors.green500;
    }
    case status?.cancelled: {
      return colors.red600;
    }
    case status?.pendingPayment: {
      return colors.orange400;
    }
    default: {
      return colors.primary600;
    }
  }
};

const getColorByStatusInstallment = (idStatus, status) => {
  switch (idStatus) {
    case status.pendingPayment: {
      return colors.orange400;
    }
    case status.paid: {
      return colors.green500;
    }
    case status.cancelled: {
      return colors.red600;
    }
    default: {
      return colors.primary600;
    }
  }
};

const getColorByStatusBillingManager = (idStatus, status) => {
  switch (idStatus) {
    case status?.pending: {
      return colors.orange400;
    }
    case status?.send: {
      return colors.green400;
    }
    case status?.notSend:
    case status?.error: {
      return colors.red500;
    }
    case status?.discarded:
    default: {
      return colors.neutral500;
    }
  }
};

const getColorByStatusWithdraw = (enumStatus, status) => {
  if (status.open.includes(enumStatus)) {
    return colors.orange400;
  }
  if (status.done.includes(enumStatus)) {
    return colors.green400;
  }
  return colors.red500;
};

const calcValuesByPercentage = ({ property, percentageField, setField, subtotal }) => {
  const calcValue = (formatNumber(subtotal) * formatNumber(percentageField)) / 100;

  if (setField) return setField(property, calcValue, false);

  const value = formatCurrency(calcValue, {
    currencySymbol: 'R$ '
  });

  return value;
};

const calcPercentageByValue = ({ setPercentage, field, subtotal }) => {
  const calcValue = formatCurrency((formatNumber(field) / formatNumber(subtotal)) * 100);
  const value = subtotal > 0 ? calcValue : 0;

  if (setPercentage) return setPercentage(value);
  return calcValue;
};

const getToday = ({ onlyDate = true } = {}) => {
  const now = new Date();
  return onlyDate ? new Date(now.getFullYear(), now.getMonth(), now.getDate()) : now;
};

const getYesterday = ({ onlyDate = true } = {}) => {
  const now = new Date();
  return onlyDate ? new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1) : now;
};

const getTomorrow = ({ onlyDate = true } = {}) => {
  const now = new Date();
  return onlyDate ? new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1) : now;
};

const getApiVersion = () => process.env.REACT_APP_API_URL?.split('/').pop();

const getTypeOfSorter = type => {
  const typeOfSorter = {
    asc: 'ascend',
    desc: 'descend'
  };
  return type ? typeOfSorter[type] : '';
};

const getColorByValueAndType = (value, type, isPercentage, isPrint) => {
  const colorByType = {
    incomeTotal: colors.green500,
    income: colors.green500,
    expense: colors.red500,
    expenseTotal: colors.red500,
    transferincome: colors.green500,
    transferexpense: colors.red500
  };

  if (value === '-') return null;

  if (isPercentage) {
    if (!value) {
      if (isPrint) return colors.neutral800;
      return colors.neutral600;
    }
    return Number(value).toFixed(2) >= 0 && Number(value).toFixed(2) <= 100 ? colors.green500 : colors.red500;
  }

  if (!value || type === 'subtitle') {
    if (isPrint) return colors.neutral800;
    return colors.neutral600;
  }

  if (type && Object.keys(colorByType).includes(type)) return colorByType[type];
  if (Number(value) > 0) return colors.green500;

  return colors.red500;
};

const isNumber = n => !Number.isNaN(parseFloat(n)) && !Number.isNaN(n - 0);
const isNumberInt = n => Number.isInteger(n - 0);
const RangePrice = (_isRange, _min, _max) => {
  if (_isRange) {
    return { gte: _min, lte: _max };
  }
  if (isNumber(_min) || isNumber(_max)) {
    return isNumber(_min) ? { gte: _min } : { gte: _max };
  }
  return undefined;
};

const checkCpf = (strCPF, allowEqualDigits = false) => {
  let sum;
  let rest;
  sum = 0;

  if (strCPF?.length < 11) return false;

  if (!allowEqualDigits && strCPF[0].repeat(11) === strCPF) return false;

  [...Array(9)].forEach((e, i) => {
    sum += parseInt(strCPF.substring(i, i + 1), 10) * (11 - (i + 1));
  });

  rest = (sum * 10) % 11;
  if (rest === 10 || rest === 11) rest = 0;
  if (rest !== parseInt(strCPF.substring(9, 10), 10)) return false;
  sum = 0;

  [...Array(10)].forEach((e, i) => {
    sum += parseInt(strCPF.substring(i, i + 1), 10) * (12 - (i + 1));
  });

  rest = (sum * 10) % 11;
  if (rest === 10 || rest === 11) rest = 0;
  return rest === parseInt(strCPF.substring(10, 11), 10);
};

const checkCnpj = strCnpj => {
  let numbers;
  let digits;
  let sum;
  let result;
  let pos;
  let size;
  let EqDigits;
  EqDigits = 1;

  if (strCnpj?.length < 14) return false;

  [...Array(strCnpj.length)].forEach((e, i) => {
    if (strCnpj.charAt(i - 1) !== strCnpj.charAt(i)) {
      EqDigits = 0;
    }
  });

  if (!EqDigits) {
    size = strCnpj.length - 2;
    numbers = strCnpj.substring(0, size);
    digits = strCnpj.substring(size);
    sum = 0;
    pos = size - 7;

    [...Array(size)].forEach((e, i) => {
      sum += numbers.charAt(i) * pos--;
      if (pos < 2) pos = 9;
    });

    result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

    if (Number(result) !== Number(digits.charAt(0))) return false;
    size += 1;
    numbers = strCnpj.substring(0, size);
    sum = 0;
    pos = size - 7;

    [...Array(size)].forEach((e, i) => {
      sum += numbers.charAt(i) * pos--;
      if (pos < 2) pos = 9;
    });

    result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

    return Number(result) === Number(digits.charAt(1));
  }

  return false;
};

const replaceDecimalNumber = number => {
  if (!number) return '';
  const _number = number?.toString()?.replace(/(^0+(?=\d))|(,?0+$)/g, '');
  return _number?.split('.')?.[1]?.length;
};

const formatDecimalsDinamically = value =>
  formatCurrency(value, { decimalCount: replaceDecimalNumber(value?.toString()) });

const hasAddress = obj => {
  return (
    (obj?.street && obj?.street?.length) ||
    (obj?.number && obj?.number?.length) ||
    (obj?.complement && obj?.complement?.length) ||
    (obj?.city && obj?.city?.length) ||
    (obj?.state && obj?.state?.length)
  );
};

const refactOrder = (sort, order) => {
  const _sort = Array.isArray(sort?.key) ? sort.key : [sort?.key];
  return sort?.key ? [[..._sort, sort.order]] : order;
};

const validDate = (date, format = 'DD MMM YY') => {
  if (!date) return '';

  return dayjs(date).isValid()
    ? `${dayjs(date)
        .format(format)
        .toString()}`
    : '';
};

const indexList = ({
  currentList = [],
  appendToIndex,
  childrenColumn,
  acc = 1,
  setLevels = f => f,
  removeChildren,
  extraValues = {}
}) => {
  return Array?.isArray(currentList)
    ? currentList.map((item, index) => {
        const _item = { ...item };
        removeChildren && delete _item?.[removeChildren];
        const idx = `${appendToIndex ? `${appendToIndex}.` : ''}${index + acc}`;
        if (_item?.[childrenColumn]?.length > 0) {
          setLevels(prev => [...new Set([...(prev || []), _item?.id])]);
        }
        delete _item?.[removeChildren];
        return {
          ..._item,
          index: idx,
          lastItem: currentList?.length <= index + 1,
          lastOrder: currentList[index - 1]?.order,
          nextOrder: currentList[index + 1]?.order,
          ...extraValues,
          [childrenColumn]: _item?.[childrenColumn]
            ? indexList({
                currentList: _item?.[childrenColumn],
                appendToIndex: idx,
                childrenColumn,
                setLevels,
                extraValues
              })
            : undefined
        };
      })
    : null;
};

const getStepColor = (step, index) => {
  if (index < step) {
    return colors.green200;
  }
  if (index === step) {
    return colors.primary400;
  }
  return colors.neutral100;
};

const getBrazilianDate = date => dayjs(date).tz('America/Sao_Paulo');

const getDateWithCustomHours = (date, timeObject = {}) => {
  return new Date(
    dayjs(date)
      .hour(timeObject?.hours || 0)
      .minute(timeObject?.minutes || 0)
      .second(timeObject?.seconds || 0)
      .millisecond(timeObject?.milliseconds || 0)
  );
};

const formatDateToISO = date => date.format('YYYY-MM-DDTHH:mm:ssZ');

function checkEmail(email) {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  return regex.test(email);
}

const minutesToHours = hours => (hours / 60).toFixed(0);

const round = (num, decimals = 2) =>
  Math.round(((num + Number.EPSILON) * 10 ** decimals) / 10 ** decimals).toFixed(decimals);
const customRound = (num, decimalPlaces) => {
  const factor = 10 ** decimalPlaces;
  const tempNumber = num * factor;
  const roundedTempNumber = Math.ceil(tempNumber);
  return roundedTempNumber / factor;
};

const getYears = () => {
  const years = [];
  const currentYear = dayjs().year();
  const endYear = currentYear + 20;
  for (let year = currentYear; year <= endYear; year++) {
    years.push({ value: year, label: String(year) });
  }
  return years;
};

const formatPhoneNumber = phone => {
  if (!phone) return '';

  if (phone.length === 11 || phone.length === 10) {
    const increment = phone.length === 11 ? 1 : 0;
    const ddd = phone?.slice(0, 2);
    const first = phone?.slice(2, 6 + increment);
    const second = phone?.slice(6 + increment);

    return `(${ddd}) ${first}-${second}`;
  }
  return '';
};

const downloadFile = (filePath, fileName) => {
  fetch(`${filePath}&t=${new Date().getTime()}`)
    .then(res => res.blob())
    .then(blob => {
      saveAs(blob, fileName);
    })
    .catch(() => toast.error('Erro ao fazer download do arquivo'));
};

const formatDocument = doc => {
  if (!doc) return null;
  const document = doc?.replace(/\D/g, '');
  if (document?.length === 11) {
    return document?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  }
  if (document?.length === 14) {
    return document?.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
  }
  return document;
};

const replaceAccountBank = num => {
  if (!num) return null;
  const cleanNumber = num.replace(/[ -]/g, '');
  return `${cleanNumber?.slice(0, -1)}-${cleanNumber?.slice(-1)}`;
};

const isValidHour = hour => {
  const [hours, minutes] = hour.split(':');
  if (!hours && !minutes) return false;
  if ((hours && hours > 23) || hours < 0) return false;
  if ((minutes && minutes > 59) || minutes < 0) return false;
  return true;
};

const getBillingManagerMessage = (item, channels, billingManagerType) => {
  const { channel, messageType, sendTo, companyCustomer } = item;
  const { value: channelValue, label: channelLabel } = channels[channel] || {};

  const phoneNumber = channelValue !== 'email' && (sendTo || companyCustomer?.phone);
  const email = channelValue === 'email' && (sendTo || companyCustomer?.email);
  const maskedPhone = formatPhoneNumber(phoneNumber) || phoneNumber;
  const conditionalValue = maskedPhone || email;

  const { message } = billingManagerType[messageType] || {};
  const customerName = companyCustomer?.name?.split(' ')[0] || '';

  const formattedValue = conditionalValue ? `[${conditionalValue}]` : '';

  const finalMessage = message?.replace('{customerName}', customerName)?.replace('{value}', formattedValue);

  return `${channelLabel} ${finalMessage}`;
};

const findLastView = viewedBy => {
  if (viewedBy && viewedBy.length > 0) {
    const lastView = viewedBy?.[0];
    return lastView?.date;
  }
  return null;
};

const getViewDate = ({ dateViewedBy, viewedBy, list } = {}) => {
  const dateToFormat = dateViewedBy || findLastView(viewedBy);

  if (dateToFormat) {
    const formattedDate = dayjs(dateToFormat).format(list ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY [às] HH:mm');
    return formattedDate;
  }

  return null;
};
const getObjectWithSelectedFields = (obj = {}, fields = []) => {
  const _obj = {};

  if (!Object.keys(obj)?.length) return _obj;
  fields.forEach(field => {
    if (obj[field] !== undefined) {
      _obj[field] = obj[field];
    }
  });

  return _obj;
};

const getLevelListFromItems = (listToParse, type) => {
  const leveListParsed = [];

  if (!listToParse || !listToParse.length) return leveListParsed;

  listToParse.forEach(item => {
    if (item.type === type) {
      leveListParsed.push({
        value: `${item.id}`,
        title: item.name || '-',
        children: getLevelListFromItems(item.children, type) || []
      });
    }
  });

  return leveListParsed;
};

const modelImportLinks = {
  item: 'https://homehero-cdn.s3.sa-east-1.amazonaws.com/modelo_importar_catalogo_Vobi.xlsx',
  companyCustomer: 'https://homehero-cdn.s3.sa-east-1.amazonaws.com/modelo_importar_clientes_Vobi.xlsx',
  supplier: 'https://homehero-cdn.s3.sa-east-1.amazonaws.com/modelo_importar_fornecedores_Vobi.xlsx',
  refurbishItems: 'https://homehero-cdn.s3.sa-east-1.amazonaws.com/modelo_importar_orcamento_vobi.xlsx',
  composition: 'https://homehero-cdn.s3.sa-east-1.amazonaws.com/modelo_importar_composicao_Vobi.xlsx',
  specification: 'https://homehero-cdn.s3.sa-east-1.amazonaws.com/modelo_importar_orcamento_v2.xlsx',
  payment: 'https://homehero-cdn.s3.sa-east-1.amazonaws.com/modelo_importar_receitas_e_despesas.xlsx',
  generalTask: 'https://homehero-cdn.s3.sa-east-1.amazonaws.com/modelo_importar_tarefas_geral.xlsx',
  projectTask: 'https://homehero-cdn.s3.sa-east-1.amazonaws.com/modelo_importar_tarefas_projeto.xlsx',
  conciliation: 'https://homehero-cdn.s3.sa-east-1.amazonaws.com/modelo_importar_conciliacao.xlsx',
  quoteItemSuppliers: 'https://homehero-cdn.s3.sa-east-1.amazonaws.com/modelo_importar_resposta_cotacao.xlsx',
  refurbish: 'https://homehero-cdn.s3.sa-east-1.amazonaws.com/modelo_importar_projetos.xlsx',
  opportunity: 'https://homehero-cdn.s3.sa-east-1.amazonaws.com/modelo_importar_oportunidades.xlsx'
};

const getLinkedItemText = item => {
  return item
    ? `Remova o vínculo com a ${item?.idCompany ? 'biblioteca' : 'SINAPI'}. Dessa forma informações
          como nome e unidade poderão ser editadas sem impactar outros itens`
    : `Vinculando o item, as informações como nome e unidade serão sempre os valores definidos na biblioteca`;
};

const validatePercentage = list => {
  let correctPercentage = true;

  list?.forEach(item => {
    const totalPercentage = item?.paymentItemLevels?.reduce((acc, cur) => acc + (Number(cur?.percentage) || 0), 0);

    if (item?.paymentItemLevels?.length && Math.abs(totalPercentage - 100) >= 0.01) correctPercentage = false;
  });

  return correctPercentage;
};

const tableDynamicHeight = ({ list, isPrint, lineHeight = 49, hasSummary }) => {
  const height = window.innerHeight - 200;
  const summaryLength = hasSummary ? 1 : 0;
  const listHeight = (list?.length + summaryLength) * lineHeight;
  return isPrint ? listHeight : `${Math.min(height, listHeight)}px`;
};

const transformHtmlToWhatsappMessage = html =>
  html
    .replace(/<(\/)?(em|i)>/g, '_')
    .replace(/<(\/)?(strong|b)>/g, '*')
    .replace(/<\/p>/g, '\n')
    .replace(/<[^>]+>/g, '');

const renderPriceOrPercentage = (val, isPrice) =>
  isPrice
    ? formatCurrency(val, {
        currencySymbol: 'R$'
      })
    : `${formatCurrency(val)}%`;

const renderArrowReport = ({ showPrice, renderData }) => {
  const arrowUp = showPrice
    ? renderData?.valueAcp > renderData?.value
    : renderData?.percentageAcp > renderData?.percentage;

  const showArrow = showPrice ? renderData?.valueAcp : renderData?.percentageAcp;

  const color = (showPrice && !arrowUp) || (!showPrice && arrowUp) ? colors.primary600 : colors.neutral400;

  return { arrowUp, showArrow, color };
};

const validateFineInterestDiscount = ({ values, types }) => {
  const { percentage } = types || {};

  const {
    total,
    installmentCount,
    installments,
    dueDateDiscountType,
    dueDateDiscount,
    fineType,
    fine,
    interest
  } = values;

  const installmentTotal = total / (installmentCount || installments?.length || 1);
  const discount =
    dueDateDiscountType === percentage?.value ? (dueDateDiscount * installmentTotal) / 100 : dueDateDiscount;
  const _fine = fineType === percentage?.value ? fine : (fine / installmentTotal) * 100;

  if (installmentTotal - discount < 10) {
    return 'O valor da parcela menos o valor do desconto não pode ser menor que R$10,00.';
  }
  if (dueDateDiscountType === percentage?.value && dueDateDiscount > 99.9) {
    return 'O desconto não pode ser maior que 99,9%';
  }
  if (interest > 10) {
    return 'O juros máximo é de 10%';
  }
  if (dueDateDiscountType !== percentage?.value && dueDateDiscount >= installmentTotal) {
    return 'O desconto não pode ser maior ou igual ao total';
  }
  if (fineType !== percentage?.value && fine >= installmentTotal) {
    return 'A multa não pode ser maior ou igual ao total';
  }
  if (_fine > 99.9) {
    return 'A multa não pode ser maior que 99,9%';
  }

  return null;
};

const getLinkItemDisplayToast = item => {
  const messages = {
    SINAPI: `Item vinculado com a SINAPI`,
    default: `Item vinculado com a biblioteca`
  };

  if (!item) return 'Item desvinculado';

  const externalBaseCode = item?.externalBaseCode;

  if (!externalBaseCode) return messages.default;

  const externalBaseName = externalBaseCode.split('_').pop();

  return messages[externalBaseName] || messages.default;
};

const formatZipCode = zipCode => {
  if (!zipCode) return null;
  return zipCode?.replace(/\D/g, '')?.replace(/(\d{5})(\d{3})/, '$1-$2');
};

const addressConcat = address => {
  const { street, number, complement, neighborhood, city, state, zipcode } = address || {};
  const fields = [];
  const _neighborhood = street || complement ? `- ${neighborhood}` : neighborhood;
  const _city = !neighborhood ? `- ${city}` : city;
  const _state = !city && !neighborhood ? `- ${state}` : state;
  const zipCodeFormatted = formatZipCode(zipcode);
  const _zipcode = !city && !neighborhood && !state ? `- ${zipCodeFormatted}` : zipCodeFormatted;

  if (street) fields.push(street);
  if (street && number) fields.push(number);
  if (complement) fields.push(complement);
  if (neighborhood) fields.push(_neighborhood);
  if (city) fields.push(_city);
  if (state) fields.push(_state);
  if (zipcode) fields.push(_zipcode);

  return fields.join(', ').replace(/, - /, ' - ');
};

const removeHtmlTags = str => {
  return str?.replace(/<\/?[^>]+(>|$)/g, '');
};

const stripNonDigits = doc => {
  return doc?.replace(/[^\d]/g, '');
};

const parentMap = ({ levelList, parentMapOptions = { selectable: false } }) => {
  const { selectable } = parentMapOptions || {};
  const leveListParsed = [];

  if (!levelList || !levelList.length) return leveListParsed;

  levelList.forEach(item => {
    if (item?.children?.length > 0) {
      return leveListParsed.push({
        value: item?.id,
        title: item?.name || '-',
        children: parentMap({ levelList: item?.children, parentMapOptions }) || [],
        selectable
      });
    }
    return leveListParsed.push({ value: item?.id, title: item?.name || '-' });
  });

  return leveListParsed;
};

const buildRecursiveInclude = ({
  model = 'financialCategory',
  id,
  depth = 2,
  child = '$children.id$',
  onlyActive = true,
  idCompany
}) => {
  if (depth === 0 || !depth || !idCompany) return [];
  return [
    {
      model,
      as: 'children',
      where: {
        ...(id ? { [child]: { ne: id } } : {}),
        ...(onlyActive ? { isActive: true } : {}),
        idCompany
      },
      required: false,
      include: buildRecursiveInclude({
        model,
        id,
        depth: depth - 1,
        child: child.replace('.id$', '->children.id$'),
        onlyActive,
        idCompany
      })
    }
  ];
};

const extractBillTypeDRE = dreList => {
  const incomeDRE = [{ id: -1, name: 'Não mostrar no DRE' }];
  const expenseDRE = [{ id: -1, name: 'Não mostrar no DRE' }];

  dreList.forEach(item => {
    if (item.children && item.children.length > 0) {
      const incomeChildren = item?.children?.filter(child => child.billType === 'income');
      const expenseChildren = item?.children?.filter(child => child.billType === 'expense');

      if (incomeChildren.length > 0) {
        const newItem = { ...item, children: incomeChildren };
        incomeDRE.push(newItem);
      }

      if (expenseChildren.length > 0) {
        const newItem = { ...item, children: expenseChildren };
        expenseDRE.push(newItem);
      }
    }
  });
  return { incomeDRE, expenseDRE };
};

const stripHTMLTags = html => {
  const div = document.createElement('div');
  div.innerHTML = html;
  return div.textContent || div.innerText || '';
};

export {
  bytesToSize,
  removeAccents,
  getFileType,
  getFileName,
  getFileNameFromURL,
  isImgUrl,
  saveFilesForm,
  parseSearchableOptions,
  getSelectItem,
  capitalize,
  toKebabCase,
  padLeft,
  sumByField,
  supportedExtensions,
  isExtensionSupported,
  getProperty,
  formatSinceTime,
  getInitials,
  hexToRgba,
  transformResponsibleFilter,
  exportCSV,
  exportXlsx,
  ExportTypes,
  exportToModel,
  maskHours,
  getMinutesFromHHMM,
  toHHMM,
  getFileIcon,
  groupTaskWithDate,
  isImgIcon,
  getColorByStatusPayment,
  calcValuesByPercentage,
  calcPercentageByValue,
  getColorByStatusInstallment,
  getToday,
  getYesterday,
  getTomorrow,
  getApiVersion,
  getTypeOfSorter,
  getColorByValueAndType,
  isNumber,
  isNumberInt,
  RangePrice,
  checkCnpj,
  checkCpf,
  replaceDecimalNumber,
  refactOrder,
  validDate,
  indexList,
  getStepColor,
  getBrazilianDate,
  checkEmail,
  formatDateToISO,
  minutesToHours,
  round,
  getColorByStatusWithdraw,
  formatPhoneNumber,
  downloadFile,
  customRound,
  getYears,
  formatDocument,
  replaceAccountBank,
  isValidHour,
  getColorByStatusBillingManager,
  getBillingManagerMessage,
  findLastView,
  getViewDate,
  getObjectWithSelectedFields,
  getLevelListFromItems,
  modelImportLinks,
  getLinkedItemText,
  hasAddress,
  validatePercentage,
  tableDynamicHeight,
  formatDecimalsDinamically,
  transformHtmlToWhatsappMessage,
  renderPriceOrPercentage,
  renderArrowReport,
  validateFineInterestDiscount,
  getLinkItemDisplayToast as getCatalogDrawerDisplayToast,
  addressConcat,
  formatZipCode,
  removeHtmlTags,
  stripNonDigits,
  parentMap,
  buildRecursiveInclude,
  extractBillTypeDRE,
  stripHTMLTags,
  getDateWithCustomHours
};
