import PropTypes from 'prop-types';
import { Gantt } from 'dhx-gantt';
import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowsToLine,
  faBoxOpen,
  faCaretDown,
  faCaretRight,
  faLayerGroup,
  faScrewdriverWrench
} from '@fortawesome/pro-solid-svg-icons';
import { faArrowRight, faCalendar } from '@fortawesome/pro-regular-svg-icons';
import ReactDOMServer from 'react-dom/server';
import { Divider } from 'antd';
import { useSelector } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { toast } from 'react-toastify';
import Avatar from '../components/AvatarEditor/Avatar';
import { getBrazilianDate, getDateWithCustomHours, getInitials, replaceDecimalNumber } from '../lib/helpers/helper';
import { Div, colors, fonts, spaces } from '../styles/style';
import { Description } from '../components/Text/Text';
import useViewport from './useViewport';
import ColorPickerDropDown from '../components/Dropdown/ColorPickerDropDown';
import formatCurrency from '../lib/helpers/formatCurrency';
import Button from '../components/Button/Button';
import { getRefetchOneOptions } from '../lib/helpers/projectFunctions';
import useCRUD from './useCRUD';

const useGantt = ({
  columnsToShow,
  isPrintGantt,
  columnsToPrint,
  idRefurbish,
  workingDays,
  dayoffs,
  tasks,
  afterChange = f => f,
  initialOpen = true,
  disabled,
  isGeneral,
  currentFilterId,
  onOpenRoot = f => f,
  onTaskClick = f => f,
  onGanttRender = f => f,
  listIndexes
}) => {
  const { isMobile: isMobileFn } = useViewport(window.innerWidth);
  const isMobile = isMobileFn();
  const [modalVisible, setModalVisible] = useState(false);
  const [linkDetails, setLinkDetails] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [initialScroll, setInitialScroll] = useState(true);

  const gantt = useRef(Gantt.getGanttInstance());
  const ganttInstance = gantt.current;
  const { refurbishItemType, planningGanttColors } = useSelector(state => state.setup.enums);

  const [isLoading, setIsLoading] = useState(true);

  const { handleUpdate, handleGet } = useCRUD({
    model: 'refurbish-items',
    immediatelyLoadData: false
  });

  const { handleGet: handleGetUser } = useCRUD({
    model: 'User',
    attributes: ['id', 'name', 'avatarFullpath'],
    immediatelyLoadData: false
  });

  const updateResources = resources => {
    if (resources) {
      const resourceStore = ganttInstance.getDatastore(ganttInstance.config.resource_store);

      resourceStore?.parse(resources);
    }
  };

  const transformData = ({
    id,
    name,
    isRoot,
    parentId,
    plStartDate,
    plEndDate,
    duration,
    plIdResponsible,
    type,
    customRootId,
    startHours = { hours: 3, minutes: 0 },
    endHours = { hours: 15, minutes: 0 },
    ...rest
  }) => {
    const startDate = plStartDate ? getDateWithCustomHours(plStartDate, startHours) : undefined;
    const endDate = plEndDate ? getDateWithCustomHours(plEndDate, endHours) : undefined;
    let _duration;

    if (!duration && startDate && endDate) {
      _duration = ganttInstance.calculateDuration({
        start_date: startDate,
        end_date: endDate
      });
    }

    return {
      id: isRoot ? customRootId || `root-${id}` : id,
      text: name,
      type: isRoot ? 'project' : type,
      parent: parentId,
      start_date: startDate || null,
      end_date: endDate || null,
      startHours,
      endHours,
      ...rest,
      isRoot,
      originalDuration: _duration || duration,
      plIdResponsible,
      owner: plIdResponsible
        ? [
            {
              resource_id: plIdResponsible
            }
          ]
        : null,
      unscheduled: !plStartDate || !plEndDate
    };
  };

  const transformAndFindUsers = (nodes, parentIdNode = null, parentColor = null, isUpdateTask, extraData = {}) => {
    let resources = [];
    const indexes = { idToIndex: {}, indexToId: {} };

    const responseData = nodes.flatMap(node => {
      let parentId = parentIdNode;

      const {
        id,
        name,
        countItems,
        plStartDate,
        plEndDate,
        children,
        user: _user,
        type: refurbishItemTypeNode,
        color,
        idParent,
        duration,
        ...rest
      } = node;

      const isRoot = countItems !== null && countItems !== undefined;
      const isTemplate = rest?.idTemplate;

      if (_user) {
        resources.push({
          id: _user.id,
          text: _user.name,
          avatar: _user.avatarFullpath
        });
      }
      let type = isRoot ? 'project' : 'task';
      let nodeColor = parentColor;
      if (refurbishItemTypeNode === refurbishItemType?.parent && !isUpdateTask) {
        nodeColor = idParent
          ? planningGanttColors[parentColor]?.major
          : planningGanttColors[color]?.major || parentColor;
        type = 'project';
        if (!idParent && !isTemplate) {
          parentId = `root-${node?.idRefurbish}`;
        }
      }

      const data = transformData({
        id,
        name,
        isRoot,
        parentId,
        plStartDate,
        plEndDate,
        _user,
        refurbishItemTypeNode,
        color: isUpdateTask ? undefined : nodeColor || color || parentColor,
        duration,
        type,
        ...rest,
        ...extraData
      });

      indexes.idToIndex[data?.id] = data?.index;
      indexes.indexToId[data?.index] = data?.id;

      const childNodes = children
        ? transformAndFindUsers(children, isRoot ? `root-${id}` : id, color || parentColor, isUpdateTask, extraData)
        : { data: [], resources: [], indexes: { idToIndex: {}, indexToId: {} } };

      resources = resources.concat(childNodes.resources);

      indexes.idToIndex = { ...indexes.idToIndex, ...childNodes.indexes.idToIndex };
      indexes.indexToId = { ...indexes.indexToId, ...childNodes.indexes.indexToId };

      return [data, ...childNodes.data];
    });

    return { data: responseData, resources, indexes };
  };

  const batchRefresh = (taskList, updateColor) => {
    for (let i = 0; i < taskList.length; i++) {
      const task = ganttInstance.getTask(taskList[i]?.id) || {};
      task.color = updateColor ? taskList?.[i]?.color : task?.color;
      task.start_date = taskList[i]?.start_date;
      task.end_date = taskList[i]?.end_date;
      task.duration = taskList[i]?.duration;
      task.owner = taskList[i]?.owner;
      task.unscheduled = taskList[i]?.unscheduled;
      task.plIdResponsible = taskList[i]?.plIdResponsible;

      ganttInstance.refreshTask(task?.id, true);
    }
  };

  const handleChange = (values, keys = { start: 'plStartDate', end: 'plEndDate' }) => {
    const newStart = values?.[keys?.start];
    const newEnd = values?.[keys?.end];
    const newValues = { ...values, [keys?.start]: newStart, [keys?.end]: newEnd };

    return handleUpdate({
      updatePathOptions: '/planning',
      values: { ...newValues, idRefurbish },
      refresh: false,
      updateOptions: getRefetchOneOptions()
    }).then(() => {
      return handleGet({
        refetchPathOptions: `/${values?.id}`,
        refetchOptions: getRefetchOneOptions({ withIncludeOnChildren: true })
      }).then(response => {
        if (response?.error) {
          return;
        }

        const isLevel = response?.type === refurbishItemType?.parent && !response?.idParent;

        const result = transformAndFindUsers([response], values?.idParent, null, !isLevel);
        updateResources(result?.resources);
        batchRefresh(result?.data, isLevel);
        afterChange(response);
      });
    });
  };

  const updateGanttConfig = (key, value) => {
    const configMap = {
      start_date: date => ganttInstance.date.convert_to_utc(new Date(date)),
      end_date: date => ganttInstance.date.convert_to_utc(new Date(date)),
      show_chart: Boolean,
      show_grid: Boolean,
      show_links: Boolean,
      drag_links: Boolean,
      details_on_dblclick: Boolean,
      grid_width: Number,
      readonly: Boolean
    };

    if (key in configMap && ganttInstance) {
      ganttInstance.config[key] = configMap[key](value);
      ganttInstance.render();
    }
  };

  useEffect(() => {
    ganttInstance.render();
    if (ganttInstance.config.layout.rows[0].cols[0].maxWidth) {
      const totalWidth = ganttInstance.config.columns.reduce((sum, column) => sum + (column.width || 0), 0);
      ganttInstance.config.layout.rows[0].cols[0].maxWidth = totalWidth;
      ganttInstance.resetLayout();
    }
  }, [columnsToPrint, columnsToShow]);

  useEffect(() => {
    updateGanttConfig('drag_links', !isPrintGantt);
    updateGanttConfig('details_on_dblclick', !isPrintGantt);
    const gridWidth =
      isPrintGantt && ganttInstance
        ? ganttInstance.config.columns.reduce((total, { width = 0 }) => total + width, 0) + 100
        : 504;
    updateGanttConfig('readonly', isPrintGantt);

    if (!isPrintGantt) {
      updateGanttConfig('show_grid', true);
      updateGanttConfig('show_chart', true);
      updateGanttConfig('show_links', true);
    }

    ganttInstance.config.layout.rows[0].cols[0].width = gridWidth;
  }, [isPrintGantt]);

  const computeWorkingDays = (_workingDays, instance) => {
    const dayNames = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    dayNames.forEach((dayName, index) => {
      instance.setWorkTime({ day: index, hours: _workingDays?.[dayName]?.active });
    });
  };

  useEffect(() => {
    if (workingDays) {
      computeWorkingDays(workingDays, ganttInstance);
    }
  }, [workingDays]);

  const computeDayoffs = (_dayoffs, instance) => {
    _dayoffs?.forEach(dayoff => {
      const dateObj = getBrazilianDate(dayoff?.date).toObject();
      if (dateObj.years <= 1900) {
        const currentYear = new Date().getFullYear();
        instance.setWorkTime({ hours: false, date: new Date(currentYear, dateObj.months, dateObj.date) });

        for (let i = 1; i < 6; i++) {
          instance.setWorkTime({ hours: false, date: new Date(currentYear - i, dateObj.months, dateObj.date) });
          instance.setWorkTime({ hours: false, date: new Date(currentYear + i, dateObj.months, dateObj.date) });
        }
        return;
      }
      instance.setWorkTime({ hours: false, date: new Date(dateObj.years, dateObj.months, dateObj.date) });
    });
  };

  useEffect(() => {
    if (dayoffs) {
      computeDayoffs(dayoffs, ganttInstance);
    }
  }, [dayoffs]);

  useEffect(() => {
    if (!tasks?.data?.length || isLoaded) return;
    ganttInstance.parse(tasks);

    setIsLoading(false);
    setIsLoaded(true);
  }, [tasks]);

  const handleLinkDblClick = id => {
    const link = ganttInstance.getLink(id);
    const sourceTask = ganttInstance.getTask(link.source);
    const targetTask = ganttInstance.getTask(link.target);

    setLinkDetails({
      id: link?.id,
      sourceText: sourceTask.text,
      targetText: targetTask.text
    });
    setModalVisible(true);
  };

  const typesMap = {
    all: [
      ganttInstance.config.links.finish_to_start,
      ganttInstance.config.links.start_to_start,
      ganttInstance.config.links.finish_to_finish,
      ganttInstance.config.links.start_to_finish
    ],
    start: [ganttInstance.config.links.start_to_start, ganttInstance.config.links.start_to_finish],
    end: [ganttInstance.config.links.finish_to_start, ganttInstance.config.links.finish_to_finish],
    sucessorStart: [ganttInstance.config.links.start_to_start, ganttInstance.config.links.finish_to_start],
    sucessorEnd: [ganttInstance.config.links.start_to_finish, ganttInstance.config.links.finish_to_finish]
  };

  ganttInstance.eachSuccessor = (callback, root, traversedTasks = {}, type = 'all') => {
    if (!ganttInstance.isTaskExists(root)) return;

    const _traversedTasks = { ...traversedTasks };
    // remember tasks we've already iterated in order to avoid infinite loops
    if (traversedTasks[root]) return;
    _traversedTasks[root] = true;

    const rootTask = ganttInstance.getTask(root);
    const links = rootTask.$source;
    if (links) {
      for (let i = 0; i < links.length; i++) {
        const link = ganttInstance.getLink(links[i]);
        if (
          ganttInstance.isTaskExists(link.target) &&
          !traversedTasks[link.target] &&
          typesMap?.[type]?.includes(link.type)
        ) {
          callback.call(ganttInstance, ganttInstance.getTask(link.target));

          // iterate the whole branch, not only first-level dependencies
          ganttInstance.eachSuccessor(callback, link.target, _traversedTasks);
        }
      }
    }
  };

  ganttInstance.attachEvent('onBeforeGanttRender', () => {
    const diff = isPrintGantt || isGeneral ? 0 : 2;
    const range = ganttInstance.getSubtaskDates();

    if (range.start_date && range.end_date) {
      ganttInstance.config.start_date = ganttInstance.calculateEndDate(range.start_date, -diff, 'year');
      ganttInstance.config.end_date = ganttInstance.calculateEndDate(range.end_date, diff, 'year');
    }
  });

  ganttInstance.attachEvent('onGanttReady', () => {
    updateResources(tasks?.resources);
  });

  useEffect(() => {
    const onGanttRenderEvent = ganttInstance.attachEvent('onGanttRender', () => {
      onGanttRender();
    });

    return () => {
      ganttInstance.detachEvent(onGanttRenderEvent);
    };
  }, [ganttInstance]);

  const formatter = ganttInstance.ext.formatters.durationFormatter({
    enter: 'day',
    store: 'day',
    format: 'day',
    hoursPerDay: 24,
    labels: {
      minute: {
        full: 'minuto',
        plural: 'minutos',
        short: 'min'
      },
      hour: {
        full: 'hora',
        plural: 'horas',
        short: 'h'
      },
      day: {
        full: 'dia',
        plural: 'dias',
        short: 'd'
      },
      week: {
        full: 'semana',
        plural: 'semanas',
        short: 'sem'
      },
      month: {
        full: 'mes',
        plural: 'meses',
        short: 'mes'
      },
      year: {
        full: 'ano',
        plural: 'anos',
        short: 'a'
      }
    }
  });

  const linksFormatter = ganttInstance.ext.formatters.linkFormatter({
    labels: {
      finish_to_start: 'FI',
      start_to_start: 'II',
      finish_to_finish: 'FF',
      start_to_finish: 'IF'
    },
    durationFormatter: formatter
  });

  const separateLink = input => {
    const regex = /^([\d.]+)([^\d.].*)?$/;
    const match = input.match(regex);

    if (match) {
      return {
        wbs: match[1] || '',
        suf: match[2] || ''
      };
    }

    return { wbs: '', suf: '' };
  };

  const formatForShowLink = value => {
    return linksFormatter.format(value);
  };

  const getFormattedLinks = ({ value }) =>
    value
      ?.reduce((acc, item) => {
        const indexTask = listIndexes?.idToIndex?.[item?.source];
        if (indexTask) {
          const { suf } = separateLink(formatForShowLink(item));

          acc.push(`${indexTask}${suf}`);
        }
        return acc;
      }, [])
      .join(', ');

  const handleChangePredecessor = ({ value, idTargetTask, from = 'List' }) => {
    const invalidLinks = [];
    const validLinks = [];
    const countLinkByIndex = {};

    const _value = Array.isArray(value) ? value : value.split(',');

    const linksObj = _value?.reduce((acc, link) => {
      const { suf, wbs } = separateLink(link.trim());
      const idTask = listIndexes?.indexToId?.[wbs];
      const task = ganttInstance.getTask(idTask);

      const wbsTask = task ? ganttInstance.getWBSCode(task) : link;

      const linkStr = `${wbsTask}${suf}`.trim();

      if (linksFormatter.canParse(linkStr)) {
        const parsedLink = linksFormatter.parse(linkStr);
        if (parsedLink && parsedLink.source) {
          const potentialLink = {
            source: parsedLink.source,
            target: Number(idTargetTask),
            type: parsedLink.type || ganttInstance.config.links.finish_to_start
          };
          const sourceTask = ganttInstance.getTask(parsedLink.source);
          if (
            ganttInstance.isCircularLink(potentialLink) ||
            sourceTask?.isRoot ||
            sourceTask?.refurbishItemTypeNode === refurbishItemType.parent
          ) {
            invalidLinks.push(link);
          } else {
            const taskIndex = task ? task?.index : link;
            acc[linkStr] = parsedLink;
            validLinks.push(`${task ? task?.index : link}${suf}`.trim());
            countLinkByIndex[taskIndex] = (countLinkByIndex[taskIndex] || 0) + 1;
          }
          return acc;
        }
      }

      invalidLinks.push(link);
      return acc;
    }, {});

    ganttInstance.getLinks().forEach(link => {
      if (link?.target !== idTargetTask) return;
      const linkStr = linksFormatter.format(link);

      if (!linkStr || !listIndexes?.idToIndex?.[link.source]) return;

      if ((countLinkByIndex[listIndexes?.idToIndex?.[link.source]] || 0) <= 1) {
        // eslint-disable-next-line no-param-reassign
        link.from = from;
        ganttInstance.refreshLink(link?.id);
        ganttInstance.deleteLink(link?.id);
        return;
      }

      toast.error(
        `O link com o item ${
          listIndexes?.idToIndex?.[link.source]
        } não foi alterado por tentativa de criação de link duplicado.`
      );

      delete linksObj[linkStr];
    });
    Object.values(linksObj).forEach(link => {
      ganttInstance.addLink({ ...link, target: idTargetTask, from });
    });

    const strInvalidLinks = invalidLinks.filter(e => e.trim() !== '').join(', ');
    if (strInvalidLinks)
      toast.error(
        `Os seguintes links são inválidos ou criariam dependências circulares e não foram criados:
          \n${strInvalidLinks}`
      );

    const strValidLinks = validLinks.filter(e => e !== '').join(', ');
    return strValidLinks;
  };

  const getInput = node => {
    return node.querySelector('input');
  };

  const formatTaskLinksToText = task => {
    if (!task.parent) {
      return '';
    }
    const links = task.$target;
    const labels = [];
    for (let i = 0; i < links.length; i++) {
      const link = ganttInstance.getLink(links[i]);
      const { suf } = separateLink(linksFormatter.format(link));

      if (link) {
        const sourceTask = ganttInstance.getTask(link.source);

        if (sourceTask && sourceTask.filtered === task.filtered) {
          labels.push(`${sourceTask.index}${suf}`);
        }
      }
    }
    return labels.join(', ');
  };

  ganttInstance.config.editor_types.customPredecessorEditor = {
    show: (id, column, config, placeholder) => {
      const html = `<div><input type='text' name='${column.name}'></div>`;
      // eslint-disable-next-line no-param-reassign
      placeholder.innerHTML = html;
    },

    set_value: (value, id, column, node) => {
      getInput(node).value = formatTaskLinksToText(value);
    },

    get_value: (id, column, node) => {
      return getInput(node).value || '';
    },

    is_changed: (value, id, column, node) => {
      return formatTaskLinksToText(value) !== getInput(node).value;
    },

    is_valid: () => {
      return true;
    },
    save: () => {},
    focus: node => {
      const input = getInput(node);
      if (!input) {
        return;
      }
      if (input.focus) {
        input.focus();
      }

      if (input.select) {
        input.select();
      }
    }
  };

  const editors = {
    predecessors: {
      type: 'customPredecessorEditor',
      map_to: 'auto'
    }
  };

  ganttInstance.config.lightbox.sections = [{ name: 'predecessors', type: 'textarea', map_to: 'predecessors' }];

  ganttInstance.locale.labels.section_predecessors = 'Predecessors';

  const modes = ganttInstance.config.drag_mode;

  const handleAfterSubmit = ({ externalProcessTasks = f => f, after = f => f }) => updatedData => {
    const processedTasks = updatedData?.updatedTasks || [];

    const processedLinks = updatedData?.updatedLinks
      ? Object.values(updatedData.updatedLinks).map(([, [link]]) => link)
      : [];

    if (processedLinks?.length > 0) {
      processedLinks.forEach(link => {
        const { id, type, lag, source, target } = link;

        const currentLink = ganttInstance.getLink(id);
        currentLink.type = type;
        currentLink.lag = lag;
        currentLink.source = source;
        currentLink.target = target;
        ganttInstance.refreshLink(id);
      });
    }

    if (updatedData?.createdLinks?.length > 0) {
      ganttInstance.silent(() => {
        updatedData.createdLinks.forEach(link => {
          ganttInstance.addLink(link);

          const sourceTask = ganttInstance.getTask(link.source);
          const targetTask = ganttInstance.getTask(link.target);
          sourceTask.$source = [...sourceTask.$source, link.id];
          targetTask.$target = [...targetTask.$target, link.id];

          ganttInstance.refreshTask(link.source);
          ganttInstance.refreshTask(link.target);
        });
      });
    }

    if (updatedData?.deletedLinks?.length > 0) {
      ganttInstance.silent(() => {
        updatedData.deletedLinks.forEach(link => {
          ganttInstance.deleteLink(link.id);

          const sourceTask = ganttInstance.getTask(link.source);
          const targetTask = ganttInstance.getTask(link.target);
          sourceTask.$source = sourceTask.$source.filter(id => id !== link.id);
          targetTask.$target = targetTask.$target.filter(id => id !== link.id);

          ganttInstance.refreshTask(link.source);
          ganttInstance.refreshTask(link.target);
        });
      });
    }

    if (processedTasks?.length > 0) {
      externalProcessTasks(processedTasks);

      const promises = processedTasks.map(task => {
        const { plIdResponsible } = task;

        if (plIdResponsible) {
          const resourceStore = ganttInstance?.getDatastore('resource');
          const owner = resourceStore?.getItem(plIdResponsible);
          if (!owner) {
            return handleGetUser({
              refetchPathOptions: `/${plIdResponsible}`,
              refetchOptions: {
                attributes: ['id', 'name', 'avatarFullpath']
              }
            }).then(_user => {
              updateResources([{ id: _user.id, name: _user.name, avatar: _user.avatarFullpath }]);
            });
          }
        }
        return Promise.resolve();
      });

      return Promise.all(promises).then(() => {
        after(processedTasks);
      });
    }
    after();
    return null;
  };

  const updateLinkLags = task => {
    task.$target.forEach(linkId => {
      const link = ganttInstance.getLink(linkId);
      if (link.target === Number(task.id)) {
        const sourceTask = ganttInstance.getTask(link.source);
        const sourceRefDate = typesMap.start.includes(link.type) ? sourceTask.start_date : sourceTask.end_date;
        const targetRefDate = typesMap.sucessorStart.includes(link.type) ? task.start_date : task.end_date;

        if (sourceRefDate && targetRefDate) {
          link.lag = ganttInstance.calculateDuration({
            start_date: getDateWithCustomHours(sourceRefDate),
            end_date: getDateWithCustomHours(targetRefDate)
          });
        }
        ganttInstance.updateLink(linkId);
      }
    });
  };

  useEffect(() => {
    if (isGeneral) {
      ganttInstance.setWorkTime({ day: 0, hours: true });
      ganttInstance.setWorkTime({ day: 6, hours: true });
    }

    ganttInstance.attachEvent('onBeforeLinkAdd', (id, link) => {
      const sourceTask = ganttInstance.getTask(link.source);
      const targetTask = ganttInstance.getTask(link.target);

      if (
        targetTask?.refurbishItemTypeNode === refurbishItemType.parent ||
        sourceTask?.refurbishItemTypeNode === refurbishItemType.parent
      ) {
        const parentText =
          sourceTask?.refurbishItemTypeNode === refurbishItemType.parent ? sourceTask.text : targetTask.text;
        toast.error(`O link com o nível ${parentText || '-'} é inválido.`);
        return false;
      }

      const linkAlreadyExists = sourceTask?.$source?.find(linkId => {
        return ganttInstance.getLink(linkId)?.target === link.target;
      });

      if (linkAlreadyExists) {
        toast.error(`O link com o item ${sourceTask?.text} já existe.`);
        return false;
      }
      return true;
    });

    ganttInstance.attachEvent('onLinkCreated', link => {
      const sourceTask = ganttInstance.getTask(link.source);
      const targetTask = ganttInstance.getTask(link.target);

      const getRefDate = (task, isStart) => getDateWithCustomHours(isStart ? task.start_date : task.end_date);

      const lag = ganttInstance.calculateDuration({
        start_date: getRefDate(sourceTask, typesMap.start.includes(link.type)),
        end_date: getRefDate(targetTask, typesMap.sucessorStart.includes(link.type))
      });

      ganttInstance.addLink({ ...link, lag });

      return false;
    });

    ganttInstance.attachEvent('onLinkDblClick', id => {
      if (!isGeneral) handleLinkDblClick(id);
      return false;
    });

    ganttInstance.attachEvent('onTaskDrag', (id, mode, task, original) => {
      if (mode === modes.resize) {
        const halfADayInMilliseconds = 1000 * 60 * 60 * 12;
        if (+task?.start_date + halfADayInMilliseconds > +task?.end_date) {
          if (+task?.start_date === +original?.start_date) {
            // eslint-disable-next-line no-param-reassign
            task.end_date = getDateWithCustomHours(original?.start_date, { hours: 15 });
          } else {
            // eslint-disable-next-line no-param-reassign
            task.start_date = getDateWithCustomHours(original?.end_date, { hours: 3 });
          }
        }
      }
      return true;
    });

    ganttInstance.attachEvent('onBeforeTaskChanged', (id, mode, original) => {
      const currentTask = ganttInstance.getTask(id);

      if (mode === modes.resize) {
        if (
          +getDateWithCustomHours(currentTask?.start_date) === +getDateWithCustomHours(original?.start_date) &&
          +getDateWithCustomHours(currentTask?.end_date) === +getDateWithCustomHours(original?.end_date)
        )
          return false;
      }

      if (!ganttInstance.isWorkTime(currentTask.start_date) || !ganttInstance.isWorkTime(currentTask.end_date))
        return false;

      if (currentTask.startHours) {
        const startDate = currentTask.start_date
          ? getDateWithCustomHours(currentTask.start_date, currentTask.startHours)
          : undefined;
        currentTask.start_date = startDate || null;
      }

      if (mode === modes.move) {
        const endDate = ganttInstance.calculateEndDate(currentTask.start_date, currentTask.duration - 1);
        currentTask.end_date =
          currentTask.endHours && endDate ? getDateWithCustomHours(endDate, currentTask.endHours) : endDate;
      }
      if (mode === modes.resize) {
        const endDate = currentTask.end_date
          ? getDateWithCustomHours(currentTask.end_date, currentTask.endHours)
          : undefined;
        currentTask.end_date = endDate || null;
      }
      return true;
    });

    ganttInstance.attachEvent('onAfterTaskDrag', id => {
      const currentTask = ganttInstance.getTask(id);
      currentTask.auto_scheduling = false;
      ganttInstance.refreshTask(currentTask.id);

      updateLinkLags(currentTask);

      setTimeout(() => {
        currentTask.auto_scheduling = true;
        ganttInstance.refreshTask(currentTask.id);
        ganttInstance.autoSchedule(currentTask.id);
      }, 100);
    });

    ganttInstance.attachEvent('onBeforeTaskAutoSchedule', (task, start, link, predecessor) => {
      const currentTask = ganttInstance.getTask(task?.id);

      const newDuration = task.fixedDuration
        ? task.originalDuration - 1
        : ganttInstance.calculateDuration({
            start_date: ganttInstance.date.convert_to_utc(getDateWithCustomHours(currentTask.start_date)),
            end_date: ganttInstance.date.convert_to_utc(getDateWithCustomHours(currentTask.end_date))
          });

      const sourceRefDate = typesMap.start.includes(link.type) ? predecessor.start_date : predecessor.end_date;
      currentTask.duration = newDuration;
      if (typesMap.sucessorStart.includes(link.type)) {
        currentTask.start_date = getDateWithCustomHours(
          ganttInstance.calculateEndDate({ start_date: sourceRefDate, duration: link.lag }),
          currentTask.startHours
        );
        currentTask.end_date = getDateWithCustomHours(
          ganttInstance.calculateEndDate({ start_date: currentTask.start_date, duration: newDuration }),
          currentTask.endHours
        );
      } else {
        currentTask.end_date = getDateWithCustomHours(
          ganttInstance.calculateEndDate({ start_date: sourceRefDate, duration: link.lag }),
          currentTask.endHours
        );
        currentTask.start_date = getDateWithCustomHours(
          ganttInstance.calculateEndDate({ start_date: currentTask.end_date, duration: -newDuration }),
          currentTask.startHours
        );
      }

      ganttInstance.updateTask(currentTask?.id);
      return false;
    });
  }, [ganttInstance]);

  useEffect(() => {
    if (!ganttInstance) return () => {};

    const onTaskClickEvent = ganttInstance.attachEvent('onTaskClick', (id, e) => {
      const { target } = e;

      const isPredecessorColumn = target.closest('[data-column-name="predecessors"]');
      const isGoToTask = target.closest('.go-to-task-button');
      const isOpenCloseOrButton = target.closest('.gantt-open-close-button');
      if (!isPredecessorColumn && !isGoToTask && !isOpenCloseOrButton && !isPrintGantt) {
        const currentTask = ganttInstance.getTask(id);
        if (currentTask?.type === 'task' && currentTask?.refurbishItemTypeNode !== refurbishItemType.parent)
          onTaskClick({
            id,
            type: currentTask?.refurbishItemTypeNode,
            idReference: currentTask?.idRefurbish
          });
        setInitialScroll(false);
      }

      return false;
    });
    return () => {
      ganttInstance.detachEvent(onTaskClickEvent);
    };
  }, [ganttInstance, isPrintGantt]);

  useEffect(() => {
    const onBeforeTaskDisplayEvent = ganttInstance.attachEvent('onBeforeTaskDisplay', (id, task) => {
      return !currentFilterId || task?.filtered === currentFilterId;
    });
    return () => {
      ganttInstance.detachEvent(onBeforeTaskDisplayEvent);
    };
  }, [currentFilterId, ganttInstance]);

  ganttInstance.setWorkTime({ hours: ['0:00-24:00'] });

  const Label = ({ text, icon, customStyle, textAlign }) => (
    <div className="gantt_grid_head_cell_aux" style={customStyle}>
      {icon && (
        <Button
          id="icon-grid-header"
          $textAlign="center"
          width={spaces.space2}
          type="text"
          margin={`0 ${spaces.space0} 0 0`}
        >
          <FontAwesomeIcon icon={icon} style={{ fontSize: fonts.sizeSm }} />
        </Button>
      )}
      <Div display="block" $textAlign={textAlign}>
        {text}
      </Div>
    </div>
  );

  const returnItemLabel = () => {
    let itemLabel = ReactDOMServer.renderToString(
      <Label
        text="Item"
        icon={initialOpen ? faArrowsToLine : undefined}
        customStyle={{ display: 'flex', alignItems: 'center' }}
      />
    );

    itemLabel = itemLabel.replace(
      '<button id="icon-grid-header"',
      `<button id="icon-grid-header" onclick="toggleAll()" `
    );
    return itemLabel;
  };

  // Gantt templates config
  window.toggleTask = (id, isRoot) => {
    const _id = isRoot ? `root-${id}` : id;
    const task = ganttInstance.getTask(_id);

    if (isRoot && isGeneral && !task?.$open && !task.loadedChildren) {
      task.loadedChildren = true;
      ganttInstance.refreshTask(task.id);
      onOpenRoot(task);
    }

    if (task?.$open) {
      ganttInstance.close(_id);
    } else {
      ganttInstance.open(_id);
    }
  };

  window.goToTask = (id, isRoot) => {
    ganttInstance.showTask(isRoot ? `root-${id}` : id);
  };

  window.toggleAll = () => {
    let shouldOpen = false;
    const parentTasks = ganttInstance.getTaskBy(task => {
      if (task.refurbishItemTypeNode === refurbishItemType.parent && !task.isRoot) {
        if (!task.$open) {
          shouldOpen = true;
        }
        return true;
      }
      return false;
    });

    ganttInstance.batchUpdate(() => {
      parentTasks.forEach(task => (shouldOpen ? ganttInstance.open(task.id) : ganttInstance.close(task.id)));
    });
  };

  const getTaskFitValue = task => {
    const taskStartPos = ganttInstance.posFromDate(task.start_date);
    const taskEndPos = ganttInstance.posFromDate(task.end_date);

    const width = taskEndPos - taskStartPos;
    const pictureComponentWidth = 32;
    const caretComponentWidth = 48;

    let textWidth = (task.text || '').length * ganttInstance.config.font_width_ratio + pictureComponentWidth;

    if (ganttInstance.hasChild(task.id) || (task?.isRoot && isGeneral)) {
      textWidth += caretComponentWidth;
    }

    if (width < textWidth) {
      return 'right';
    }
    return 'center';
  };

  const getTaskIconHtml = (task, divProps = {}, iconColor = colors.neutral600) => {
    if (ganttInstance.hasChild(task.id) || (task?.isRoot && isGeneral)) {
      const icon = task.$open ? faCaretDown : faCaretRight;
      const iconHtml = ReactDOMServer.renderToString(
        <Button
          type="text"
          width={spaces.space2}
          $textAlign="center"
          {...divProps}
          className="gantt-open-close-wrapper"
        >
          <FontAwesomeIcon
            icon={icon}
            color={iconColor}
            className={`gantt-open-close-button gantt_${task.$open ? 'close' : 'open'}`}
          />
        </Button>
      );
      return iconHtml.replace(
        '<button',
        `<button onclick="toggleTask(${task?.originalId || task?.id},${task?.isRoot})" `
      );
    }
    return '';
  };

  const getMultipleTaskAvatarHtml = task => {
    if (task?.users?.length > 0) {
      const resourceStore = ganttInstance.getDatastore('resource');
      const users = task?.users?.flatMap(u => resourceStore.getItem(u?.id) || []);
      if (users?.length) {
        return ReactDOMServer.renderToString(
          <Div height="100%">
            <Avatar.Group style={{ zIndex: 1, overflowX: 'auto', width: '100%' }}>
              {users?.map(user => (
                <Div key={`avatar-${task?.id}-${user.id}`} display="block" width={spaces.space3}>
                  <Avatar id={`avatar-${task?.id}-${user.id}`} src={user.avatar}>
                    {getInitials(user?.text)}
                  </Avatar>
                </Div>
              ))}
            </Avatar.Group>
          </Div>
        );
      }
    }
    return '';
  };

  const getSingleTaskAvatarHtml = task => {
    const resourceStore = ganttInstance.getDatastore('resource');
    const owner = resourceStore.getItem(task.owner[0].resource_id);
    if (owner) {
      return ReactDOMServer.renderToString(
        <Div height="100%">
          <Avatar style={{ opacity: 100 }} id={`avatar-${task.id}`} size="small" src={owner?.avatar}>
            {getInitials(owner.text)}
          </Avatar>
        </Div>
      );
    }
    return '';
  };

  const getTaskAvatarHtml = task => {
    if ((columnsToShow && !columnsToShow?.plIdResponsible) || (columnsToPrint && !columnsToPrint?.plIdResponsible))
      return '';
    if (task?.users?.length > 0) {
      return getMultipleTaskAvatarHtml(task);
    }

    if (task?.owner?.length > 0) {
      return getSingleTaskAvatarHtml(task);
    }
    return '';
  };

  ganttInstance.templates.leftside_text = (start, end, task) => {
    if (task.isRoot && !isGeneral) return '';
    if (getTaskFitValue(task) === 'left') {
      let content = '';
      if (!isPrintGantt) content += getTaskIconHtml(task);
      if (!task.isRoot) content += getTaskAvatarHtml(task);
      content += task?.text || '-';
      return content;
    }
    return '';
  };

  ganttInstance.templates.rightside_text = (start, end, task) => {
    if (task.isRoot && !isGeneral) return '';
    if (getTaskFitValue(task) === 'right') {
      let content = '';
      if (!isPrintGantt) content += getTaskIconHtml(task);
      if (!task.isRoot) content += getTaskAvatarHtml(task);
      content += task?.text || '-';
      return content;
    }
    return '';
  };

  ganttInstance.templates.task_text = (start, end, task) => {
    if (task.isRoot && !isGeneral) return '';
    if (getTaskFitValue(task) === 'center') {
      let content = '';
      if (!isPrintGantt) content += getTaskIconHtml(task, {}, colors.white);
      if (!task.isRoot) content += getTaskAvatarHtml(task);
      content += task?.text || '-';
      return content;
    }
    return '';
  };

  ganttInstance.templates.timeline_cell_class = (task, date) => {
    const scaleUnit = ganttInstance.getState().scale_unit;
    return ganttInstance.isWorkTime({ task, date }) || scaleUnit === 'year' || scaleUnit === 'month' ? '' : 'weekend';
  };

  ganttInstance.templates.date_grid = (date, task) => {
    if (task && ganttInstance.isUnscheduledTask(task) && ganttInstance.config.show_unscheduled) {
      const calendarIcon = ReactDOMServer.renderToString(
        <FontAwesomeIcon icon={faCalendar} style={{ marginRight: spaces.space0, color: colors.neutral300 }} />
      );
      return calendarIcon;
    }
    const currentYear = new Date().getFullYear();
    if (date.getFullYear() === currentYear) {
      return ganttInstance.date
        .date_to_str('%d %M')(date)
        .toLowerCase();
    }
    return ganttInstance.templates.grid_date_format(date).toLowerCase();
  };

  ganttInstance.templates.task_unscheduled_time = () => {
    return '';
  };

  ganttInstance.templates.tooltip_text = (start, end, task) => {
    const resourceStore = ganttInstance.getDatastore('resource');
    const owner = task.owner && task.owner.length > 0 ? resourceStore.getItem(task.owner[0].resource_id) : null;

    const tooltip = ReactDOMServer.renderToString(
      <Div direction="column" padding="12px" align="flex-start" $maxWidth="180px" gap={spaces.space1}>
        <Description>
          <strong style={{ whiteSpace: 'normal' }}>{task.text}</strong>
        </Description>
        {!ganttInstance.isUnscheduledTask(task) && (
          <Div direction="row">
            <Description>
              {ganttInstance.templates.grid_date_format(start).toLowerCase()} -&gt;{' '}
              {ganttInstance.templates.grid_date_format(end).toLowerCase()}
            </Description>
          </Div>
        )}
        {owner && (
          <>
            <Divider style={{ marginTop: 0, marginBottom: 0 }} />
            <Description style={{ whiteSpace: 'normal' }}>{owner.text}</Description>
          </>
        )}
      </Div>
    );

    return tooltip;
  };

  ganttInstance.templates.parse_date = date => {
    const parsedDate = new Date(date);
    return parsedDate;
  };
  ganttInstance.templates.format_date = date => {
    return new Date(date).toISOString();
  };

  ganttInstance.templates.drag_link = (from, fromStart, to, toStart) => {
    const sourceTask = ganttInstance.getTask(from);
    const targetTask = to ? ganttInstance.getTask(to) : null;

    const tooltip = ReactDOMServer.renderToString(
      <Div direction="column" padding="12px" align="flex-start" $maxWidth="180px" gap={spaces.space1}>
        <Div gap={spaces.space0} $maxWidth="100%">
          <Description ellipsis>
            <strong>{sourceTask?.text}</strong>
          </Description>
          <Description>{fromStart ? '(Início)' : '(Fim)'}</Description>
        </Div>
        {targetTask && (
          <Div gap={spaces.space0} $maxWidth="100%">
            <Description ellipsis>
              <strong>{targetTask?.text}</strong>
            </Description>
            <Description>{toStart ? '(Início)' : '(Fim)'}</Description>
          </Div>
        )}
      </Div>
    );

    return tooltip;
  };

  // Gantt configuration
  ganttInstance.config.drag_progress = false;
  ganttInstance.config.drag_links = !isMobile && !disabled;
  ganttInstance.config.drag_project = false;
  ganttInstance.config.drag_resize = !disabled;
  ganttInstance.config.drag_move = !disabled;
  ganttInstance.config.smart_scales = false;
  ganttInstance.config.fit_tasks = true;
  ganttInstance.config.bar_height = 28;
  ganttInstance.config.row_height = 40;
  ganttInstance.config.date_grid = '%d %M. %y';
  ganttInstance.config.open_tree_initially = initialOpen;
  ganttInstance.config.work_time = true;
  ganttInstance.config.duration_unit = 'day';
  ganttInstance.config.round_dnd_dates = false;
  ganttInstance.config.time_step = 1;
  ganttInstance.config.duration_step = 1;
  ganttInstance.config.auto_scheduling = !disabled;
  ganttInstance.config.auto_scheduling_strict = true;
  ganttInstance.config.auto_scheduling_project_constraint = true;
  ganttInstance.config.auto_scheduling_compatibility = true;
  ganttInstance.config.auto_scheduling_initial = false;
  ganttInstance.config.min_duration = 0;
  ganttInstance.config.preserve_scroll = true;
  ganttInstance.config.initial_scroll = initialScroll;
  ganttInstance.config.details_on_dblclick = false;

  ganttInstance.config.font_width_ratio = 7;
  ganttInstance.config.drag_timeline = {
    ignore: '.gantt_task_line, .gantt_task_link',
    useKey: false
  };
  ganttInstance.config.external_render = {
    isElement: element => React.isValidElement(element),
    renderElement: (element, _container) => {
      const root = createRoot(_container);
      root.render(element);
    }
  };

  const exportXlsx = ({
    name = 'documento',
    columns,
    id,
    header,
    width,
    type,
    server,
    callback,
    visual,
    cellColors,
    dateFormat = 'DD/MM/YYYY'
  }) => {
    const translateItemType = refurbishItemTypeNode => {
      switch (refurbishItemTypeNode) {
        case refurbishItemType.labor:
          return 'Serviço';
        case refurbishItemType.product:
          return 'Produto';
        case refurbishItemType.composition:
          return 'Composição';
        default:
          return null;
      }
    };

    const setTimeToZero = date => {
      const newDate = new Date(date);
      newDate.setHours(0, 0, 0, 0);
      return newDate;
    };

    const activeColumns = ganttInstance?.config?.columns.map(item => item?.name);

    const exportColumns = columns?.filter(
      item => activeColumns.includes(item?.id) || item?.id === 'refurbishItemTypeNode'
    );

    const backupStartDate = ganttInstance?.config?.start_date;
    const backupEndDate = ganttInstance?.config?.end_date;

    const _tasks = ganttInstance?.getTaskByTime()?.sort((a, b) => a?.$index - b?.$index) || [];
    const links = ganttInstance?.getLinks();

    const json = [];
    _tasks.forEach(value => {
      if (value?.unscheduled) return;

      const newValue = {
        ...value,
        refurbishItemTypeNode: translateItemType(value?.refurbishItemTypeNode),
        number: value?.isRoot ? 0 : ganttInstance?.getWBSCode(value).slice(2),
        duration: `${value?.duration}`
      };

      if (value?.owner?.length > 0) {
        const resourceStore = ganttInstance?.getDatastore('resource');
        const owner = resourceStore?.getItem(value?.owner[0]?.resource_id);
        newValue.owner = owner?.text;
      }

      if (value.refurbishItemTypeNode === refurbishItemType.parent) {
        newValue.refurbishItemTypeNode = value.idParentLevel ? 'Subnível' : 'Nível';
      }

      if (links?.length > 0) {
        const taskLinks = links.filter(link => link?.target === value?.id);
        newValue.predecessors =
          taskLinks.length > 0 ? taskLinks.map(link => linksFormatter.format(link).slice(2)).join(', ') : '';
      }

      json.push(newValue);
    });

    let minDate = ganttInstance.date.convert_to_utc(new Date(json[0]?.start_date));
    let maxDate = ganttInstance.date.convert_to_utc(new Date(json[0]?.end_date));

    json.forEach(task => {
      if (+task.start_date < +minDate) minDate = ganttInstance.date.convert_to_utc(new Date(task.start_date));
      if (+task.end_date > +maxDate) maxDate = ganttInstance.date.convert_to_utc(new Date(task.end_date));
    });

    ganttInstance?.config && (ganttInstance.config.start_date = minDate);
    ganttInstance?.config && (ganttInstance.config.end_date = maxDate);

    json.forEach(task => {
      const scale = ganttInstance?.getScale();
      const traceIndexes = scale?.trace_indexes;

      const startDateUtc = setTimeToZero(ganttInstance.date.convert_to_utc(new Date(task.start_date)));
      const endDateUtc = setTimeToZero(
        ganttInstance.date.convert_to_utc(new Date(task.end_date)).setDate(new Date(task.end_date).getDate() + 1)
      );

      const updatedTask = {
        ...task,
        $start: traceIndexes[+startDateUtc],
        $end: traceIndexes[+endDateUtc],
        $type: task?.type || 'task',
        $text: task?.text
      };

      Object.assign(task, updatedTask);
    });
    ganttInstance?.exportToExcel({
      name: `${name}.xlsx`,
      columns: exportColumns,
      visual,
      cellColors,
      data: json,
      date_format: dateFormat,
      id,
      header,
      width,
      type,
      server,
      callback
    });

    ganttInstance?.config && (ganttInstance.config.start_date = backupStartDate);
    ganttInstance?.config && (ganttInstance.config.end_date = backupEndDate);
    ganttInstance?.render();
  };

  const columns = [
    {
      name: 'number',
      showColumnKey: 'number',
      label: ReactDOMServer.renderToString(<Label text="Nº" textAlign="left" />),
      align: 'left',
      min_width: 70,
      width: 70,
      template(task) {
        if (!task.parent) {
          return '';
        }

        return task?.index;
      }
    },
    {
      name: 'text',
      showColumnKey: true,
      label: returnItemLabel(),
      tree: true,
      min_width: 340,
      width: 340,
      template: task => {
        return task?.text || '-';
      },
      onrender: (task, node) => {
        const content = node.querySelector('.gantt_tree_content');
        const container = document.createElement('div');

        container.className = 'custom-text-gantt-container';

        let goToTaskComponent = ReactDOMServer.renderToString(
          <Button
            className="go-to-task-button"
            type="outline"
            padding={0}
            width={spaces.space3}
            style={{ height: spaces.space3, flexShrink: 0 }}
          >
            <FontAwesomeIcon
              icon={faArrowRight}
              style={{ cursor: 'pointer' }}
              color={colors.primary600}
              className={`go-to-task-button go-to-task-${task.id}`}
            />
          </Button>
        );

        goToTaskComponent = goToTaskComponent.replace(
          '<button',
          `<button onclick="goToTask(${task.originalId || task.id},${task?.isRoot})" `
        );

        container.innerHTML = task?.unscheduled ? '' : goToTaskComponent;

        if (task.parent && task.$level === 1) {
          const colorAndNameContainer = document.createElement('div');
          colorAndNameContainer.style.cssText = `
            display: flex;
            align-items: center;
            gap: ${spaces.space0};
            width: 100%;
          `;

          const colorPicker = document.createElement('div');

          createRoot(colorPicker).render(
            <ColorPickerDropDown
              color={task.color || colors.neutral300}
              disabled={isPrintGantt || isGeneral || disabled}
              onSelect={value => {
                handleChange({ id: task.id, color: value }, false);
              }}
            />
          );

          colorAndNameContainer.appendChild(colorPicker);
          colorAndNameContainer.appendChild(content);
          container.insertBefore(colorAndNameContainer, container.firstChild);
        } else {
          container.insertBefore(content, container.firstChild);
        }

        node.appendChild(container);

        return node;
      }
    },
    {
      name: 'quantity',
      showColumnKey: 'quantity',
      label: ReactDOMServer.renderToString(<Label text="Qtd" textAlign="right" />),
      align: 'right',
      min_width: 70,
      width: 70,
      template(task) {
        if (task?.quantity === null || task?.quantity === undefined) {
          return '-';
        }
        return formatCurrency(task?.quantity, { decimalCount: replaceDecimalNumber(task?.quantity) });
      }
    },
    {
      name: 'duration',
      showColumnKey: 'duration',
      label: ReactDOMServer.renderToString(<Label text="Duração" textAlign="right" />),
      align: 'right',
      min_width: 70,
      width: 70,
      template({ duration, unscheduled, originalDuration }) {
        if (unscheduled) return originalDuration || '-';
        if (duration === 0) return duration + 1;
        return duration || '-';
      }
    },
    {
      name: 'start_date',
      showColumnKey: 'plDates',
      label: ReactDOMServer.renderToString(<Label text="Início" textAlign="left" />),
      align: 'left',
      min_width: 115,
      width: 115
    },
    {
      name: 'end_date',
      showColumnKey: 'plDates',
      label: ReactDOMServer.renderToString(<Label text="Fim" textAlign="left" />),
      align: 'left',
      min_width: 115,
      width: 115
    },
    {
      name: 'owner',
      showColumnKey: 'plIdResponsible',
      label: ReactDOMServer.renderToString(<Label text="Responsável" textAlign="left" />),
      align: 'left',
      min_width: 110,
      width: 110,
      template(task) {
        return task?.owner || (isGeneral && task?.users?.length) ? getTaskAvatarHtml(task) : '-';
      }
    },
    {
      name: 'predecessors',
      showColumnKey: 'targetLinks',
      label: ReactDOMServer.renderToString(<Label text="Predecessoras" textAlign="left" />),
      min_width: 120,
      width: 120,
      align: 'left',
      editor: disabled ? undefined : editors.predecessors,
      template(task) {
        if (!task.parent) {
          return '';
        }

        return formatTaskLinksToText(task);
      }
    }
  ];

  ganttInstance.config.columns = columns.flatMap(({ showColumnKey, ...otherProps }) => {
    return !columnsToShow || (isPrintGantt ? columnsToPrint : columnsToShow)?.[showColumnKey] || showColumnKey === true
      ? [otherProps]
      : [];
  });

  ganttInstance.templates.grid_open = item => {
    if ((item.isRoot && (!isGeneral || item?.empty)) || isPrintGantt) {
      return ReactDOMServer.renderToString(<Div $minWidth={spaces.space3} />);
    }

    return getTaskIconHtml(item, {
      width: spaces.space3,
      $minWidth: spaces.space3,
      display: 'flex',
      justify: 'center',
      align: 'center'
    });
  };

  ganttInstance.templates.grid_file = item => {
    let icon;
    switch (item.refurbishItemTypeNode) {
      case refurbishItemType.labor:
        icon = faScrewdriverWrench;
        break;
      case refurbishItemType.product:
        icon = faBoxOpen;
        break;
      case refurbishItemType.composition:
        icon = faLayerGroup;
        break;
      default:
        return '';
    }
    return ReactDOMServer.renderToString(
      <Div align="center" width={spaces.space3} height="100%" margin={`0 ${spaces.space0} 0 0`}>
        <Div width={spaces.space3} justify="center">
          <FontAwesomeIcon icon={icon} style={{ color: colors.neutral300 }} />
        </Div>
      </Div>
    );
  };

  ganttInstance.templates.grid_folder = () => {
    return null;
  };

  ganttInstance.templates.grid_row_class = (start, end, task) => {
    const isChildOfRoot = `${task.parent}`.includes('root');
    if (task.isRoot) return 'main_parent_task';
    if (ganttInstance.hasChild(task.id) && task.parent && !isChildOfRoot) return 'parent_child_task';
    if (ganttInstance.hasChild(task.id)) return 'parent_task';
    if (task.parent && !isChildOfRoot) return 'child_task';
    return '';
  };

  ganttInstance.templates.task_class = (start, end, task) => {
    const isChildOfRoot = `${task.parent}`.includes('root');
    if (task.isRoot) return 'main_parent_task';
    if (ganttInstance.hasChild(task.id) && task.parent && !isChildOfRoot) return 'parent_child_task';
    if (ganttInstance.hasChild(task.id)) return 'parent_task';
    if (task.parent && !isChildOfRoot) return 'child_task';
    return '';
  };

  const handleModalClose = confirmed => {
    if (confirmed) {
      ganttInstance.deleteLink(linkDetails.id);
    }
    setModalVisible(false);
  };

  const customAutoScheduleAll = () => {
    ganttInstance.batchUpdate(() => {
      ganttInstance.eachTask(task => {
        // eslint-disable-next-line no-param-reassign
        task.start_date = getDateWithCustomHours(
          ganttInstance.getClosestWorkTime({
            dir: 'future',
            date: task.start_date,
            unit: ganttInstance.config.duration_unit,
            task
          }),
          task.startHours
        );
        const duration = (task.fixedDuration ? task.originalDuration : task.duration) - 1;
        const newEndDate = ganttInstance.calculateEndDate({ start_date: task.start_date, duration });
        // eslint-disable-next-line no-param-reassign
        task.end_date = getDateWithCustomHours(
          ganttInstance.getClosestWorkTime({
            dir: 'future',
            date: newEndDate,
            unit: ganttInstance.config.duration_unit,
            task
          }),
          task.endHours
        );
        // eslint-disable-next-line no-param-reassign
        task.auto_scheduling = true;
        ganttInstance.updateTask(task.id);
        ganttInstance.autoSchedule(task.id);
      });
    });
  };

  const cleanUnscheduledFromChildren = task => {
    if (task?.unscheduled) return;

    task?.$source?.forEach(linkId => {
      const link = ganttInstance.getLink(linkId);
      const targetTask = ganttInstance.getTask(link.target);

      if (targetTask.unscheduled) {
        targetTask.unscheduled = false;

        const sourceRefDate = typesMap.start.includes(link.type) ? task.start_date : task.end_date;

        const newTargetDate = ganttInstance.calculateEndDate({
          start_date: getDateWithCustomHours(sourceRefDate),
          duration: link.lag
        });

        const newDuration = Number(targetTask.originalDuration) ? targetTask.originalDuration - 1 : 0;

        if (typesMap.sucessorStart.includes(link.type)) {
          targetTask.start_date = getDateWithCustomHours(newTargetDate, targetTask.startHours);
          targetTask.end_date = getDateWithCustomHours(
            ganttInstance.calculateEndDate({ start_date: targetTask.start_date, duration: newDuration }),
            targetTask.endHours
          );
        } else {
          targetTask.end_date = getDateWithCustomHours(newTargetDate, targetTask.endHours);
          targetTask.start_date = getDateWithCustomHours(
            ganttInstance.calculateEndDate({ start_date: targetTask.end_date, duration: -newDuration }),
            targetTask.startHours
          );
        }
      }

      if (targetTask?.$source?.length) cleanUnscheduledFromChildren(targetTask);
    });
  };

  Label.propTypes = {
    text: PropTypes.string.isRequired,
    icon: PropTypes.instanceOf(Object),
    customStyle: PropTypes.string,
    textAlign: PropTypes.string
  };

  return {
    ganttInstance,
    modalVisible,
    linkDetails,
    handleModalClose,
    exportXlsx,
    updateGanttConfig,
    handleChange,
    isLoading,
    transformAndFindUsers,
    transformData,
    batchRefresh,
    getFormattedLinks,
    handleChangePredecessor,
    computeDayoffs,
    computeWorkingDays,
    updateLinkLags,
    handleAfterSubmit,
    updateResources,
    customAutoScheduleAll,
    cleanUnscheduledFromChildren
  };
};

export default useGantt;
