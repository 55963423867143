import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faCircleQuestion } from '@fortawesome/pro-regular-svg-icons';

import Modal from './Modal';
import Tabs, { TabPane } from '../Tabs/Tabs';
import { colors, spaces, Div, breakpoints } from '../../styles/style';
import Input from '../Input/Input';
import useViewport from '../../_Hooks/useViewport';
import { Paragraph, Subtitle } from '../Text/Text';
import TooltipIconComponent from '../Tooltip/TooltipIcon';
import WarningBar from '../Alert/WarningBar';

const TooltipContent = styled.div`
  margin: 0 ${spaces.space1};
  white-space: pre;
  cursor: ${props => props.id && 'pointer'};
`;

const ColumnsContent = styled.div`
  height: ${props => props.$height || '512px'};
  overflow-y: auto;
  width: 100%;

  .spaced-columns {
    display: flex;
    align-items: center;
    margin: ${spaces.space2} 0;
  }

  .columns-text {
    word-break: keep-all;
    width: 142px;
    display: flex;
    align-items: center;

    @media (max-width: ${breakpoints.tablet}) {
      width: ${spaces.space10};
    }
  }

  .checkbox-column {
    width: ${spaces.space5};
    display: flex;
    align-items: center;
    word-break: keep-all;
    justify-content: center;
    margin-left: ${spaces.space7};

    input {
      width: ${spaces.space4};
      height: ${spaces.space2};
    }
  }
`;

const ConfigModal = ({
  onClose,
  onSubmit,
  modalTitle,
  tabs = [],
  handleChangeCheckbox,
  extraContentTop,
  bodyHeight,
  initialTab
}) => {
  const { isMobile: isMobileFn } = useViewport(window.innerWidth);
  const isMobile = isMobileFn();

  const [selectedTab, setSelectedTab] = useState(initialTab || tabs?.[0]?.key) || '';

  const fontAwesome = {
    faInfoCircle,
    faCircleQuestion
  };

  const TooltipIcon = ({ title, color = colors.primary600, placementRight, icon = 'faInfoCircle', id }) => {
    return (
      <TooltipContent id={id}>
        <TooltipIconComponent
          text={title}
          icon={fontAwesome[icon]}
          iconColor={color}
          tooltipProps={{
            placement: placementRight ? 'right' : 'top',
            overlayStyle: placementRight ? { maxWidth: '350px' } : {}
          }}
        />
      </TooltipContent>
    );
  };

  TooltipIcon.propTypes = {
    title: PropTypes.string,
    color: PropTypes.string,
    placementRight: PropTypes.bool,
    icon: PropTypes.string,
    id: PropTypes.string
  };

  const columnsContent = (columns, warningText, myColumnTooltip, clientColumnTooltip, checkedMap) => (
    <ColumnsContent isMobile={isMobile} $height={bodyHeight}>
      {warningText ? (
        <WarningBar width="100%" padding={spaces.space1} margin={`${spaces.space2} 0 0 0`}>
          <TooltipIcon color={colors.neutral600} />
          <Paragraph>{warningText}</Paragraph>
        </WarningBar>
      ) : null}
      <div className="spaced-columns">
        <Subtitle type="secondary" className="columns-text">
          Coluna
        </Subtitle>
        <Subtitle type="secondary" className="checkbox-column">
          Habilitado
          {myColumnTooltip && <TooltipIcon title={myColumnTooltip} />}
        </Subtitle>
        <Subtitle type="secondary" className="checkbox-column">
          Cliente
        </Subtitle>
        {clientColumnTooltip && <TooltipIcon title={clientColumnTooltip} />}
      </div>
      {columns.map(({ key, label, tooltip, id, icon, default: _default }) => (
        <div className="spaced-columns" key={key} id={`${key}-config`}>
          <Paragraph type="small" className="columns-text">
            {label}
            {tooltip && <TooltipIcon title={tooltip} id={id} icon={icon} />}
          </Paragraph>
          <div className="checkbox-column">
            <Input
              type="checkbox"
              checked={checkedMap?.myColumns?.[key] === undefined ? _default : checkedMap?.myColumns?.[key]}
              onChange={handleChangeCheckbox('myColumns', key, selectedTab)}
            />
          </div>
          <div className="checkbox-column" id="config-client">
            <Input
              type="checkbox"
              checked={checkedMap?.clientColumns?.[key] === undefined ? _default : checkedMap?.clientColumns?.[key]}
              onChange={handleChangeCheckbox('clientColumns', key, selectedTab)}
            />
          </div>
        </div>
      ))}
    </ColumnsContent>
  );

  return (
    <Modal
      title={modalTitle}
      onSubmit={() => {
        onSubmit();
        onClose();
      }}
      open
      onClose={onClose}
      width={576}
      noPadding
    >
      {extraContentTop && extraContentTop()}
      <Tabs activeKey={selectedTab} onChange={setSelectedTab} tabsNavBorderBottom={colors.neutral100}>
        {tabs?.map(tab => {
          const { key, title, icon, warningText, myColumnTooltip, clientColumnTooltip, columns, custom, checkedMap } =
            tab || {};

          return (
            <TabPane
              key={key}
              tab={
                icon ? (
                  <Div gap={spaces.space1}>
                    <FontAwesomeIcon icon={icon} className="icon-to-change-when-hover-or-selected" />
                    {title}
                  </Div>
                ) : (
                  title
                )
              }
            >
              <Div padding={`0 ${spaces.space2}`} $fullWidth>
                {custom || columnsContent(columns, warningText, myColumnTooltip, clientColumnTooltip, checkedMap)}
              </Div>
            </TabPane>
          );
        })}
      </Tabs>
    </Modal>
  );
};

ConfigModal.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  modalTitle: PropTypes.string,
  tabs: PropTypes.instanceOf(Array),
  handleChangeCheckbox: PropTypes.func,
  bodyHeight: PropTypes.string,
  extraContentTop: PropTypes.instanceOf(Object),
  initialTab: PropTypes.string
};

export default ConfigModal;
