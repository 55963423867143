import React from 'react';
import PropTypes from 'prop-types';
import * as dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { getColorByStatusInstallment } from '../../lib/helpers/helper';
import DatePicker from '../Datepicker/Datepicker';
import MaskedInput from '../Input/NewMaskedInput';
import RichTextEditor from '../RichTextEditor/RichTextEditor';
import formatCurrency from '../../lib/helpers/formatCurrency';
import formatNumber from '../../lib/helpers/formatNumber';
import { colors, spaces } from '../../styles/style';
import {
  CardFooter,
  InlineItems,
  StyledCardRow,
  StyledStatus,
  StyledText,
  Text,
  TextDescription
} from './PaymentConfigurationMobileCard.styles';
import Card from '../Card/Card';
import Button from '../Button/Button';
import { Error } from '../Text/Text';
import { Line } from '../Line/Line';

const PaymentConfigurationMobileCard = ({
  list = [],
  isView,
  numberOfInstalments,
  handleChange = f => f,
  handleBlur = f => f,
  errors,
  touched,
  actionDropdown,
  setInstallmentToPay = f => f,
  installmentToPay,
  isCustomerView,
  isPublic,
  isVobiPay,
  disabledToCreditType,
  handleHighlight = f => f
}) => {
  const { installmentStatuses } = useSelector(state => state.setup.enums);
  const onlyView = isCustomerView || isPublic || isView;
  return (
    list &&
    list?.map((item, index) => {
      const selectedCard = item?.id && onlyView && isVobiPay && item?.id === installmentToPay;
      const statusColor = getColorByStatusInstallment(item.idInstallmentStatus, installmentStatuses);
      const installmentPaid = installmentStatuses.allPaid.includes(item?.idInstallmentStatus);
      return (
        <Card
          id="item-card"
          bodyBackgroundColor={selectedCard ? colors.primary50 : colors.neutral50}
          footerBackgroundColor={selectedCard ? colors.primary50 : colors.neutral50}
          footer={
            onlyView && !installmentPaid && isVobiPay && (isPublic || isCustomerView) ? (
              <Button
                className="hide-on-print"
                type="primary"
                text={!selectedCard}
                onClick={() => {
                  handleHighlight();
                  setInstallmentToPay(item?.id);
                }}
              >
                Pagar
              </Button>
            ) : null
          }
        >
          <InlineItems isView={isView}>
            <StyledCardRow className="installment-field-wrapper">
              <StyledText>Parcela</StyledText>
              <Text className="field">{`${index + 1}/${numberOfInstalments}`}</Text>
            </StyledCardRow>
            {!isView && !disabledToCreditType && (
              <StyledCardRow className="percentual-field-wrapper">
                <StyledText>Percentual</StyledText>

                <MaskedInput
                  type="number"
                  value={formatCurrency(item.percentage, {
                    currencySymbol: ''
                  })}
                  disabled={installmentStatuses.allPaid.includes(item.idInstallmentStatus) || disabledToCreditType}
                  onChange={e => handleChange(index, 'percentage', formatNumber(e.target.value))}
                  onBlur={handleBlur}
                  maskOptions={{
                    suffix: '%',
                    prefix: '',
                    includeThousandsSeparator: false,
                    allowDecimal: true
                  }}
                  style={{
                    paddingLeft: `${spaces.space1}`,
                    paddingRight: `${spaces.space1}`,
                    height: `${spaces.space4}`
                  }}
                />
                {touched?.installments && errors[`installments[${index}].percentage`] && (
                  <Error>{errors[`installments[${index}].percentage`]}</Error>
                )}
              </StyledCardRow>
            )}

            <StyledCardRow className="value-field-wrapper">
              <StyledText>Valor</StyledText>
              {!isView && !disabledToCreditType ? (
                <MaskedInput
                  type="currency"
                  disabled={installmentStatuses.allPaid.includes(item.idInstallmentStatus) || disabledToCreditType}
                  value={formatCurrency(item.price, {
                    currencySymbol: 'R$ '
                  })}
                  onChange={e => handleChange(index, 'price', formatNumber(e.target.value))}
                  onBlur={handleBlur}
                  maskOptions={{
                    prefix: 'R$',
                    thousandsSeparatorSymbol: '.',
                    allowDecimal: true
                  }}
                  style={{
                    paddingLeft: `${spaces.space1}`,
                    paddingRight: `${spaces.space1}`,
                    height: `${spaces.space4}`
                  }}
                />
              ) : (
                <Text>
                  {formatCurrency(item?.price || 0, {
                    currencySymbol: 'R$ '
                  })}
                </Text>
              )}
              {touched?.installments && errors[`installments[${index}].price`] && (
                <Error>{errors[`installments[${index}].price`]}</Error>
              )}
            </StyledCardRow>
          </InlineItems>
          <InlineItems>
            <StyledCardRow className="flex--1">
              <StyledText>Vencimento</StyledText>
              {!isView ? (
                <DatePicker
                  id="billingDate"
                  disabled={installmentStatuses.allPaid.includes(item.idInstallmentStatus)}
                  name="billingDate"
                  style={{ width: '100%' }}
                  format="DD/MM/YYYY"
                  allowClear={false}
                  value={item?.dueDate ? dayjs(item?.dueDate) : null}
                  onChange={value => handleChange(index, 'dueDate', value)}
                />
              ) : (
                <Text>{item?.dueDate ? dayjs(item?.dueDate).format('DD/MM/YYYY') : '-'}</Text>
              )}
              {touched?.installments && errors[`installments[${index}].dueDate`] && (
                <Error>{errors[`installments[${index}].dueDate`]}</Error>
              )}
            </StyledCardRow>
            <StyledCardRow className="flex--1">
              <StyledText>Status</StyledText>
              <StyledStatus statusColor={statusColor} isView={isView}>
                {item?.installmentStatus?.name || ''}
              </StyledStatus>
            </StyledCardRow>
          </InlineItems>
          <StyledCardRow style={{ paddingRight: spaces.space2, paddingLeft: spaces.space2 }}>
            <StyledText>Descrição</StyledText>
            {!isView ? (
              <RichTextEditor
                value={item?.description || ''}
                style={{ width: '100%' }}
                height={100}
                toolbar={false}
                statusbar={false}
                outputFormat="text"
                disabled={installmentStatuses.allPaid.includes(item.idInstallmentStatus)}
                onChange={value => handleChange(index, 'description', value)}
                forced_root_block={false}
                encoding="UTF-8"
                onBlur={handleBlur}
                entity_encoding="raw"
              />
            ) : (
              <TextDescription>{item.description}</TextDescription>
            )}
            {touched?.installments && errors[`installments[${index}].description`] && (
              <Error>{errors[`installments[${index}].description`]}</Error>
            )}
          </StyledCardRow>
          {actionDropdown && !onlyView && item?.id && (
            <>
              <Line />
              <CardFooter>{actionDropdown(item)}</CardFooter>
            </>
          )}
        </Card>
      );
    })
  );
};

PaymentConfigurationMobileCard.propTypes = {
  list: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  isView: PropTypes.bool,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  noMargin: PropTypes.bool,
  idRefurbish: PropTypes.number,
  disabledToCreditType: PropTypes.bool
};

export default PaymentConfigurationMobileCard;
