import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import useIsCustomer from './useIsCustomer';

const useBackToContext = () => {
  const { pathname, search } = useLocation();
  const { user } = useSelector(state => state.authReducer) || {};
  const { userType } = useSelector(state => state.setup.enums);
  const isCustomer = user.userType === userType.customer.value;

  const history = useHistory();
  const _isCustomer = useIsCustomer();

  const backToRefurbishSubRouteContext = path => {
    if (_isCustomer) {
      history.replace(`/cliente/${path}`);
      return null;
    }

    if (pathname?.includes('/editar')) {
      const newExtraUrl = pathname?.split('profissional')[1].replace('editar', 'visualizar');
      history.replace(`/profissional${newExtraUrl}${search}`);
      return null;
    }

    if (search?.includes('projeto=')) {
      const projectId = search.split('projeto=')[1]?.split('&')[0];
      history.replace(`/profissional/projetos/perfil/${projectId}/${path}`);
      return null;
    }

    history.replace(`/profissional/${path}`);
    return null;
  };

  return () => {
    if (pathname?.includes('/relatorios/')) {
      const id = pathname.split('/')[3];
      if (isCustomer) history.replace(`/cliente/orcamento`);
      else history.replace(`/profissional/projetos/perfil/${id}/orcamento`);
      return null;
    }
    if (pathname?.includes('/diario-de-obra/visualizar') || pathname?.includes('/diario-de-obra/editar')) {
      if (search?.includes('projeto=')) {
        history.replace(`/profissional/projetos/perfil/obra/diario-de-obra`);
        return null;
      }
      history.replace('/profissional/diario-de-obra');
      return null;
    }
    if (pathname?.includes('/gestao-de-compras/solicitacoes/')) {
      backToRefurbishSubRouteContext('gestao-de-compras/solicitacoes');
      return null;
    }

    if (pathname?.includes('/gestao-de-compras/cotacoes/')) {
      backToRefurbishSubRouteContext('gestao-de-compras/cotacoes');
      return null;
    }

    if (pathname?.includes('/gestao-de-compras/ordem-de-compras/')) {
      backToRefurbishSubRouteContext('gestao-de-compras/ordem-de-compras');
      return null;
    }

    if (pathname?.includes('/perfil')) {
      const context = pathname?.split('/perfil', 1);
      history.replace(context?.[0]);
      return null;
    }
    if (pathname?.includes('/editar')) {
      const context = pathname?.split('/editar', 1);
      history.replace(pathname?.includes('/meus-templates') ? `${context?.[0]}/todos` : context?.[0]);
      return null;
    }
    history.goBack();
    return null;
  };
};

export default useBackToContext;
