import React from 'react';
import * as PropTypes from 'prop-types';
import * as dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
import chunk from 'lodash/chunk';
import {
  checkCnpj,
  checkCpf,
  isNumber,
  isNumberInt,
  checkEmail,
  isValidHour,
  getMinutesFromHHMM
} from '../../lib/helpers/helper';
import formatNumber from '../../lib/helpers/formatNumber';
import { store } from '../../lib/config/redux-store';

dayjs.extend(utc);
dayjs.extend(customParseFormat);

const importConfig = {};
const formats = ['DD/MM/YYYY', 'DD-MM-YYYY', 'DD/MM/YY', 'DD-MM-YY'];

const formatDate = date => (date ? dayjs.utc(date, formats, true) : null);

const verifyDate = date => dayjs.utc(date, formats, true).isValid();

const getEnum = (name, type) => {
  const reduxState = store.getState();
  return reduxState.setup?.[type]?.[name];
};

const getOpportunityRefurbishStatus = () => {
  const refurbishStatus = getEnum('refurbishStatus', 'enums');
  if (!refurbishStatus) return [];

  return [
    { label: refurbishStatus.new.name, value: refurbishStatus.new.id },
    { label: refurbishStatus.contact.name, value: refurbishStatus.contact.id },
    { label: refurbishStatus.proposalSent.name, value: refurbishStatus.proposalSent.id },
    { label: refurbishStatus.archived.name, value: refurbishStatus.archived.id }
  ];
};

const getOriginsOfOpportunity = () => {
  const refurbishFlowData = getEnum('refurbishFlowData', 'systemData');

  return refurbishFlowData?.originsOfOpportunity;
};

const DateFormatError = ({ message }) => (
  <div>
    <p>{message}, formatos aceitos:</p>
    <ul style={{ listStyle: 'inside' }}>
      {formats.map(format => (
        <li key={format}>{format}</li>
      ))}
    </ul>
  </div>
);

DateFormatError.propTypes = {
  message: PropTypes.string
};

const observationName = [
  'Observacao',
  'OBSERVAÇÃO',
  'OBSERVACAO',
  'observação',
  'observacao',
  'Obs',
  'Obs.',
  'Observações',
  'OBSERVAÇÕES',
  'observações',
  'Observacoes',
  'OBSERVACOES',
  'observacoes',
  'Descrição',
  'descrição',
  'Descricao',
  'descricao'
];

const docName = ['Cpf', 'cpf', 'CPF', 'Cnpj', 'cnpj', 'CNPJ', 'CPF/CNPJ', 'Cpf/Cnpj', 'cpf/cnpj'];
const birthdayName = ['Data de nascimento', 'Data de Nascimento', 'data de nascimento', 'DATA DE NASCIMENTO'];
const phoneName = [
  'Telefone',
  'telefone',
  'TELEFONE',
  'Tel.',
  'Tel',
  'tel.',
  'tel',
  'TEL.',
  'TEL',
  'phone',
  'Phone',
  'PHONE'
];
const addressName = ['Rua', 'rua', 'RUA', 'Endereço', 'endereço', 'ENDEREÇO'];
const complementName = ['Complemento', 'complemento', 'COMPLEMENTO'];
const neighborName = ['Bairro', 'bairro', 'BAIRRO'];
const zipName = ['CEP', 'Cep', 'cep'];
const cityName = ['Cidade', 'cidade', 'CIDADE'];
const stateName = ['Estado', 'estado', 'ESTADO', 'uf', 'UF', 'Uf'];
const typeName = ['Tipo*', 'Tipo', 'tipo', 'TIPO'];
const statusName = ['Status', 'STATUS', 'status'];
const quantityName = [
  'QUANTIDADE',
  'quantidade',
  'QUANT.',
  'QUANT',
  'Quant',
  'Quant.',
  'quant',
  'quant.',
  'Qtd',
  'Qtd.',
  'qtd',
  'qtd.'
];
const priceName = [
  'PREÇO',
  'preço',
  'Preco',
  'PRECO',
  'preco',
  'valor',
  'Valor',
  'Preço unitário',
  'Valor un.',
  'Valor unitário',
  'valor unitário',
  'Custo un.',
  'Custo unitário'
];
const unitName = ['UNIDADE', 'unidade', 'Un', 'Un.', 'un', 'un.'];
const name = ['Nome', 'NOME', 'nome', 'item', 'ITEM', 'Item'];
const number = ['Número', 'NÚMERO', 'Número', 'numero', 'NUMERO', 'Numero', 'Nº'];
const numberOrder = [...number, 'Ordem', 'ordem', 'ORDEM'];
const codeName = ['código', 'CÓDIGO', 'Código', 'Codigo', 'codigo', 'CODIGO'];
const paymentType = [
  'FORMA DE PGTO',
  'forma de pgto',
  'Forma de pgto',
  'Forma de pagamento',
  'FORMA DE PAGAMENTO',
  'forma de pagamento',
  'FORMA DE PGTO.',
  'forma de pgto.',
  'Forma de pgto.',
  'FORMAS DE PGTO',
  'formas de pgto',
  'Formas de pgto',
  'Formas de pagamento',
  'FORMAS DE PAGAMENTO',
  'formas de pagamento',
  'FORMAS DE PGTO.',
  'formas de pgto.',
  'Formas de pgto.'
];
const paidDate = [
  'DATA DE PAGAMENTO',
  'data de pagamento',
  'Data de pagamento',
  'DATA DE PGTO',
  'data de pgto',
  'Data de pgto',
  'DATA DE PGTO.',
  'data de pgto.',
  'Data de pgto.'
];
const dueDate = [
  'DATA DE VENCIMENTO',
  'data de vencimento',
  'Data de vencimento',
  'vencimento',
  'VENCIMENTO',
  'Vencimento'
];
const valuePayment = [
  'VALOR',
  'valor',
  'Valor',
  'VALOR TOTAL',
  'valor total',
  'Valor do pagamento',
  'valor do pagamento',
  'VALOR DO PAGAMENTO',
  'Valor do pgto',
  'valor do pgto',
  'VALOR DO PGTO',
  'Valor do pgto.',
  'valor do pgto.',
  'VALOR DO PGTO.'
];
const category = [
  'CATEGORIA FINANCEIRA',
  'categoria financeira',
  'Categoria financeira',
  'categoria',
  'Categoria',
  'CATEGORIA'
];

const emailName = ['Email', 'email', 'E-mail', 'e-mail', 'responsáveis', 'responsável'];

importConfig.companyCustomer = () => ({
  title: 'Importar clientes',
  validators: { isActive: { inativo: false, default: true } },
  formatters: { birthDate: value => formatDate(value) },
  rowHook: (data, addError) => {
    const validatePersonType = ['pj', 'pf'];
    if (!validatePersonType.includes(data.personType?.trim()?.toLowerCase())) {
      addError('personType', {
        message: 'Tipo não permitido, deve ser pf ou pj!',
        level: 'error'
      });
    }
    const validateStatus = ['ativo', 'inativo'];
    if (data.isActive && !validateStatus.includes(data.isActive?.trim()?.toLowerCase())) {
      addError('isActive', {
        message: 'Status não permitido, deve ser Ativo ou Inativo!',
        level: 'error'
      });
    }
    const _doc = data.doc?.replace(/[,-./]/g, '');

    if (data.doc && !checkCpf(_doc) && !checkCnpj(_doc)) {
      addError('doc', {
        message: 'O documento informado (CNPJ/CPF) está incorreto.',
        level: 'error'
      });
    }

    if (data?.birthDate && !verifyDate(data?.birthDate)) {
      addError('birthDate', {
        message: <DateFormatError message="Data de nascimento inválida" />,
        level: 'error'
      });
    }
    return data;
  },
  fields: [
    {
      label: 'Nome/Nome Fantasia*',
      key: 'name',
      fieldType: {
        type: 'input'
      },
      example: 'João Silva',
      validations: [
        {
          rule: 'required',
          errorMessage: 'Nome é obrigatório',
          level: 'error'
        }
      ]
    },
    {
      label: 'Razão Social',
      key: 'businessName',
      fieldType: {
        type: 'input'
      },
      example: 'Empresa LTDA.'
    },
    {
      label: 'Email',
      key: 'email',
      fieldType: {
        type: 'input'
      },
      example: 'joao@gmail.com'
    },
    {
      label: 'Tipo*',
      alternateMatches: typeName,
      key: 'personType',
      fieldType: {
        type: 'select',
        options: [
          { label: 'pf', value: 'pf' },
          { label: 'pj', value: 'pj' }
        ]
      },
      validations: [
        {
          rule: 'required',
          errorMessage: 'Tipo é obrigatório',
          level: 'error'
        }
      ],
      example: 'pf'
    },
    {
      label: 'CPF/CNPJ',
      alternateMatches: docName,
      key: 'doc',
      fieldType: {
        type: 'input'
      },
      example: '93804350020'
    },
    {
      label: 'RG/IE',
      key: 'stateDoc',
      fieldType: {
        type: 'input'
      },
      example: '426998749'
    },
    {
      label: 'Data de nascimento',
      alternateMatches: birthdayName,
      key: 'birthDate',
      fieldType: {
        type: 'input'
      },
      example: '12/12/2000'
    },
    {
      label: 'Telefone',
      alternateMatches: phoneName,
      key: 'phone',
      fieldType: {
        type: 'input'
      },
      example: '11991630007'
    },
    {
      label: 'Status',
      alternateMatches: statusName,
      key: 'isActive',
      fieldType: {
        type: 'select',
        options: [
          { label: 'Ativo', value: 'Ativo' },
          { label: 'Inativo', value: 'Inativo' }
        ]
      },
      example: 'Ativo'
    },
    {
      label: 'CEP',
      alternateMatches: zipName,
      key: 'zipcode',
      fieldType: {
        type: 'input'
      },
      example: '21530000'
    },
    {
      label: 'Rua',
      alternateMatches: addressName,
      key: 'street',
      fieldType: {
        type: 'input'
      },
      example: 'Avenida Brasil'
    },
    {
      label: 'Número',
      alternateMatches: numberOrder,
      key: 'number',
      fieldType: {
        type: 'input'
      },
      example: '1'
    },
    {
      label: 'Complemento',
      alternateMatches: complementName,
      key: 'complement',
      fieldType: {
        type: 'input'
      },
      example: 'Apto 02'
    },
    {
      label: 'Bairro',
      alternateMatches: neighborName,
      key: 'neighborhood',
      fieldType: {
        type: 'input'
      },
      example: 'Coelho Neto'
    },
    {
      label: 'Cidade',
      alternateMatches: cityName,
      key: 'city',
      fieldType: {
        type: 'input'
      },
      example: 'Rio de Janeiro'
    },
    {
      label: 'Estado',
      alternateMatches: stateName,
      key: 'state',
      fieldType: {
        type: 'input'
      },
      example: 'RJ'
    },
    {
      label: 'Observações',
      alternateMatches: observationName,
      key: 'observation',
      fieldType: {
        type: 'input'
      },
      example: 'Observação diversa'
    }
  ]
});

importConfig.supplier = () => ({
  title: 'Importar fornecedores',

  rowHook: (data, addError) => {
    const validateType = ['prestador de serviço', 'loja'];
    if (!validateType.includes(data.type?.trim()?.toLowerCase())) {
      addError('type', { message: 'Tipo não permitido, deve ser Prestador de serviço ou Loja!', level: 'error' });
    }
    const validatePersonType = ['pj', 'pf'];
    if (data.personType && !validatePersonType.includes(data.personType?.trim()?.toLowerCase())) {
      addError('personType', {
        message: 'Tipo de pessoa não permitido, deve ser pf ou pj!',
        level: 'error'
      });
    }
    const _doc = data.document?.replace(/[,-./]/g, '');

    if (data.document && !checkCpf(_doc) && !checkCnpj(_doc)) {
      addError('doc', {
        message: 'O documento informado (CNPJ/CPF) está incorreto.',
        level: 'error'
      });
    }
    return data;
  },

  fields: [
    {
      label: 'Nome fantasia*',
      alternateMatches: ['Nome fantasia'],
      key: 'name',
      fieldType: {
        type: 'input'
      },
      example: 'Fornecedor 1',
      validations: [
        {
          rule: 'required',
          errorMessage: 'Nome é obrigatório',
          level: 'error'
        }
      ]
    },
    {
      label: 'Razão social',
      alternateMatches: ['Razão social'],
      key: 'legalName',
      fieldType: {
        type: 'input'
      },
      example: 'Vobi1'
    },
    {
      label: 'Tipo*',
      alternateMatches: typeName,
      key: 'type',
      fieldType: {
        type: 'select',
        options: [
          { label: 'Loja', value: 'Loja' },
          { label: 'Prestador de serviço', value: 'Prestador de serviço' }
        ]
      },
      example: 'Prestador de serviço',
      validations: [
        {
          rule: 'required',
          errorMessage: 'Tipo é obrigatório',
          level: 'error'
        }
      ]
    },
    {
      label: 'Tipo Pessoa',
      alternateMatches: ['Tipo Pessoa'],
      key: 'personType',
      fieldType: {
        type: 'select',
        options: [
          { label: 'pf', value: 'pf' },
          { label: 'pj', value: 'pj' }
        ]
      },
      example: 'pj'
    },
    {
      label: 'CPF/CNPJ',
      alternateMatches: docName,
      key: 'document',
      fieldType: {
        type: 'input'
      },
      example: '93804350020'
    },
    {
      label: 'RG/IE',
      key: 'stateDoc',
      fieldType: {
        type: 'input'
      },
      example: '426998749'
    },
    {
      label: 'Categoria',
      alternateMatches: ['Categoria'],
      key: 'costCenter',
      fieldType: {
        type: 'input'
      },
      example: 'Móveis'
    },
    {
      label: 'Email',
      alternateMatches: ['Email', 'email', 'E-mail', 'e-mail'],
      key: 'email',
      fieldType: {
        type: 'input'
      },
      example: 'vobi1@gmail.com'
    },
    {
      label: 'Site',
      alternateMatches: ['Site', 'site', 'SITE'],
      key: 'website',
      fieldType: {
        type: 'input'
      },
      example: 'www.vobi.com.br'
    },
    {
      label: 'Telefone Contato',
      alternateMatches: ['Telefone Contato'],
      key: 'phone',
      fieldType: {
        type: 'input'
      },
      example: '(11) 91417-6655'
    },
    {
      label: 'Responsável',
      alternateMatches: ['Responsavel'],
      key: 'responsibleName',
      fieldType: {
        type: 'input'
      },
      example: 'Marcos'
    },
    {
      label: 'Nome Contato',
      alternateMatches: ['Nome Contato'],
      key: 'contactName',
      fieldType: {
        type: 'input'
      },
      example: 'Vinicius'
    },
    {
      label: 'CEP',
      alternateMatches: zipName,
      key: 'zipcode',
      fieldType: {
        type: 'input'
      },
      example: '04543-000'
    },
    {
      label: 'Endereço',
      alternateMatches: addressName,
      key: 'street',
      fieldType: {
        type: 'input'
      },
      example: 'Av. Pres. Juscelino Kubitschek'
    },
    {
      label: 'Número',
      alternateMatches: number,
      key: 'number',
      fieldType: {
        type: 'input'
      },
      example: '1327'
    },
    {
      label: 'Complemento',
      alternateMatches: complementName,
      key: 'complement',
      fieldType: {
        type: 'input'
      },
      example: 'Apto 02'
    },
    {
      label: 'Bairro',
      alternateMatches: neighborName,
      key: 'neighborhood',
      fieldType: {
        type: 'input'
      },
      example: 'Itaim Bibi'
    },
    {
      label: 'Cidade',
      alternateMatches: cityName,
      key: 'city',
      fieldType: {
        type: 'input'
      },
      example: 'São Paulo'
    },
    {
      label: 'Estado',
      alternateMatches: stateName,
      key: 'state',
      fieldType: {
        type: 'input'
      },
      example: 'SP'
    },
    {
      label: 'Banco',
      alternateMatches: ['Banco'],
      key: 'bankName',
      fieldType: {
        type: 'input'
      },
      example: 'BB'
    },
    {
      label: 'Agencia',
      alternateMatches: ['Agencia'],
      key: 'agency',
      fieldType: {
        type: 'input'
      },
      example: '1'
    },
    {
      label: 'Conta Corrente',
      alternateMatches: ['Conta Corrente', 'Cc'],
      key: 'account',
      fieldType: {
        type: 'input'
      },
      example: '112233'
    },
    {
      label: 'Meios de pagamento',
      alternateMatches: ['Meios de pagamento'],
      key: 'paymentOptions',
      fieldType: {
        type: 'input'
      },
      example: 'Pix: XPTO'
    },
    {
      label: 'Observações',
      alternateMatches: observationName,
      key: 'observation',
      fieldType: {
        type: 'input'
      },
      example: 'Observação diversa'
    }
  ]
});

const textValidation = (data, addError, key, label) => {
  if (data[key] && (data[key]?.length > 255 || data[key]?.length < 3)) {
    addError(key, { message: `O(a) ${label} deve ter no mínimo 3 e no máximo 255 caracteres.`, level: 'error' });
  }
};

importConfig.item = () => ({
  title: 'Importar Catálogo',
  formatters: { price: value => formatNumber(value) },
  fields: [
    {
      label: 'Código',
      alternateMatches: ['Cód', 'Cod', 'Codigo', 'Código'],
      key: 'code',
      fieldType: {
        type: 'input'
      },
      example: '12457'
    },
    {
      label: 'Tipo*',
      alternateMatches: typeName,
      key: 'type',
      fieldType: {
        type: 'select',
        options: [
          { label: 'Serviço', value: 'Serviço' },
          { label: 'Produto', value: 'Produto' }
        ]
      },
      example: 'Serviço',
      validations: [
        {
          rule: 'required',
          errorMessage: 'Tipo é obrigatório',
          level: 'error'
        }
      ]
    },
    {
      label: 'Nome*',
      alternateMatches: ['Nome'],
      key: 'name',
      fieldType: {
        type: 'input'
      },
      example: 'Serviço teste',
      validations: [
        {
          rule: 'required',
          errorMessage: 'Nome é obrigatório',
          level: 'error'
        }
      ]
    },
    {
      label: 'Unidade',
      key: 'unit',
      alternateMatches: unitName,
      fieldType: {
        type: 'input'
      },
      example: 'un'
    },
    {
      label: 'Fornecedor',
      alternateMatches: ['Fornecedor'],
      key: 'supplier',
      fieldType: {
        type: 'input'
      },
      example: 'Fornecedor teste'
    },
    {
      label: 'Grupo',
      alternateMatches: ['Grupo'],
      key: 'refurbishGroup',
      fieldType: {
        type: 'input'
      },
      example: 'Sala'
    },
    {
      label: 'Categoria',
      alternateMatches: ['Categoria'],
      key: 'costCenter',
      fieldType: {
        type: 'input'
      },
      example: 'Móveis'
    },
    {
      label: 'Preço',
      alternateMatches: ['Preço', 'Preco'],
      key: 'price',
      fieldType: {
        type: 'input'
      },
      example: '630,5'
    },
    {
      label: 'Link',
      alternateMatches: ['Link'],
      key: 'link',
      fieldType: {
        type: 'input'
      },
      example: 'https://www.google.com/'
    },
    {
      label: 'Marca',
      alternateMatches: ['Marca'],
      key: 'brand',
      fieldType: {
        type: 'input'
      },
      example: 'Vobi'
    },
    {
      label: 'Descrição',
      alternateMatches: ['Descrição', 'Descricao'],
      key: 'description',
      fieldType: {
        type: 'input'
      },
      example: 'Descrição diversa'
    }
  ]
});

importConfig.item['4'] = ({ itemClasses }) => ({
  ...importConfig.item,
  title: 'Importar catálogo',
  customChunk: (array, size) => {
    const map = {};
    array.forEach(row => {
      const { compositionCode, itemCode, type } = row;
      const rowWithChildren = map[compositionCode];
      if (itemCode || type !== 'Composição') {
        rowWithChildren?.children?.push(row);
      } else {
        map[compositionCode] = { ...row, children: [] };
      }
    });
    const result = chunk(Object.values(map), size);
    return result;
  },
  fields: [
    {
      label: 'Código Composição',
      key: 'compositionCode',
      alternateMatches: ['Código Composição', 'codigo composicao'],
      fieldType: {
        type: 'input'
      },
      example: '12457'
    },
    {
      label: 'Código Item',
      key: 'itemCode',
      alternateMatches: ['Código Item', 'codigo item'],
      fieldType: {
        type: 'input'
      },
      example: '12457'
    },
    {
      label: 'Tipo*',
      key: 'type',
      alternateMatches: typeName,
      fieldType: {
        type: 'select',
        options: [
          { label: 'Serviço', value: 'Serviço' },
          { label: 'Produto', value: 'Produto' },
          { label: 'Composição', value: 'Composição' }
        ]
      },
      example: 'Serviço',
      validations: [
        {
          rule: 'required',
          errorMessage: 'Tipo é obrigatório',
          level: 'error'
        }
      ]
    },
    {
      label: 'Nome*',
      key: 'name',
      alternateMatches: name,
      fieldType: {
        type: 'input'
      },
      example: 'Serviço teste',
      validations: [
        {
          rule: 'required',
          errorMessage: 'Nome é obrigatório',
          level: 'error'
        }
      ]
    },
    {
      label: 'Quantidade',
      key: 'quantity',
      alternateMatches: quantityName,
      fieldType: {
        type: 'input'
      },
      example: '1'
    },
    {
      label: 'Unidade',
      key: 'unit',
      alternateMatches: unitName,
      fieldType: {
        type: 'input'
      },
      example: 'un'
    },
    {
      label: 'Custo unitário',
      key: 'price',
      alternateMatches: priceName,
      fieldType: {
        type: 'input'
      },
      example: '630,5'
    },
    {
      label: 'Classe',
      key: 'idClass',
      alternateMatches: ['classe, CLASSE', 'Classe'],
      fieldType: {
        type: 'select',
        options: itemClasses?.map(i => ({ label: i.name, value: i.id }))
      },
      example: 'FUNDACOES E ESTRUTURAS'
    }
  ]
});

const verifyId = (id, addError, field, fieldName) => {
  if (id && !isNumberInt(id)) {
    addError(field, {
      message: `Id do ${fieldName} deve ser um número`,
      level: 'error'
    });
  }
};

const verifyMinInput = (value, addError, field, fieldName) => {
  if (value && value.length < 3) {
    addError(field, {
      message: `${fieldName} deve ter no mínimo 3 caracteres`,
      level: 'error'
    });
  }
};

importConfig.payment = paymentStatuses => ({
  title: 'Importar pagamentos',
  rowHook: (data, addError) => {
    textValidation(data, addError, 'name', 'nome');

    const {
      name: paymentName,
      idRefurbish,
      idCustomer,
      idSupplier,
      idPaymentStatus,
      idPaymentType,
      value,
      paidDate: paidDateRow,
      billingDate,
      dueDate: dueDateRow
    } = data;

    verifyId(idRefurbish, addError, 'idRefurbish', 'projeto');
    verifyId(idCustomer, addError, 'idCustomer', 'cliente');
    verifyId(idSupplier, addError, 'idSupplier', 'fornecedor');

    verifyMinInput(paymentName, addError, 'name', 'Nome');

    if (!isNumber(formatNumber(value)) || value === 0) {
      addError('value', {
        message: 'Valor deve ser um número e diferente de zero',
        level: 'error'
      });
    }

    if (Number(idPaymentStatus) === paymentStatuses.paid && !idPaymentType) {
      addError('idPaymentType', {
        message: '"Pago com" é obrigatório para pagamentos pagos',
        level: 'error'
      });
    }

    if (paidDateRow && !verifyDate(paidDateRow)) {
      addError('paidDate', {
        message: <DateFormatError message="Data de pagamento inválida" />,
        level: 'error'
      });
    }

    if (billingDate && !verifyDate(billingDate)) {
      addError('billingDate', {
        message: <DateFormatError message="Data de competência inválida" />,
        level: 'error'
      });
    }

    if (dueDateRow && !verifyDate(dueDateRow)) {
      addError('dueDate', {
        message: <DateFormatError message="Data de vencimento inválida" />,
        level: 'error'
      });
    }

    return data;
  },
  formatters: {
    price: value => formatNumber(value),
    billingDate: value => formatDate(value),
    paidDate: value => formatDate(value),
    dueDate: value => formatDate(value)
  },
  fields: [
    {
      label: 'Nome*',
      key: 'name',
      fieldType: {
        type: 'input'
      },
      example: 'Receita ou despesa',
      validations: [
        {
          rule: 'required',
          errorMessage: 'Nome é obrigatório',
          level: 'error'
        }
      ]
    },
    {
      label: 'Status',
      alternateMatches: statusName,
      key: 'idPaymentStatus',
      fieldType: {
        type: 'select',
        options: [
          { label: 'Em aberto', value: 2 },
          { label: 'Pago', value: 3 }
        ]
      },
      example: 'Em aberto',
      validations: [
        {
          rule: 'required',
          errorMessage: 'Status é obrigatório',
          level: 'error'
        }
      ]
    },
    {
      label: 'Categoria financeira',
      key: 'financialCategory',
      alternateMatches: category,
      fieldType: {
        type: 'input'
      },
      example: 'Móveis'
    },
    {
      label: 'Cliente',
      alternateMatches: ['CLIENTE', 'cliente', 'Cliente'],
      key: 'idCustomer',
      fieldType: {
        type: 'input'
      },
      example: '1234'
    },
    {
      label: 'Fornecedor',
      alternateMatches: ['FORNECEDOR', 'fornecedor', 'Fornecedor'],
      key: 'idSupplier',
      fieldType: {
        type: 'input'
      },
      example: '5678'
    },
    {
      label: 'Data de competência*',
      alternateMatches: ['DATA DE COMPETENCIA', 'data de competencia', 'Data de competência', 'DATA DE COMPETÊNCIA'],
      key: 'billingDate',
      fieldType: {
        type: 'input'
      },
      example: '2/19/2022',
      validations: [
        {
          rule: 'required',
          errorMessage: 'Data de competência é obrigatório',
          level: 'error'
        }
      ]
    },
    {
      label: 'Data de vencimento',
      alternateMatches: dueDate,
      key: 'dueDate',
      fieldType: {
        type: 'input'
      },
      example: '3/20/2022'
    },
    {
      label: 'Projeto',
      alternateMatches: ['PROJETO', 'projeto', 'Projeto'],
      key: 'idRefurbish',
      fieldType: {
        type: 'input'
      },
      example: '91011'
    },
    {
      label: 'Conta bancária',
      alternateMatches: ['CONTA BANCARIA', 'conta bancaria', 'Conta bancária', 'CONTA BANCÁRIA'],
      key: 'bankAccount',
      fieldType: {
        type: 'input'
      },
      example: 'Banco 1'
    },
    {
      label: 'Centro de custo',
      alternateMatches: ['CENTRO DE CUSTO', 'centro de custo', 'Centro de custo'],
      key: 'paymentCostCenter',
      fieldType: {
        type: 'input'
      },
      example: 'Centro de custo 1'
    },
    {
      label: 'Valor*',
      alternateMatches: valuePayment,
      key: 'value',
      fieldType: {
        type: 'input'
      },
      example: '100',
      validations: [
        {
          rule: 'required',
          errorMessage: 'Valor é obrigatório',
          level: 'error'
        }
      ]
    },
    {
      label: 'Data de pagamento',
      alternateMatches: paidDate,
      key: 'paidDate',
      fieldType: {
        type: 'input'
      },
      example: '3/20/2022'
    },
    {
      label: 'Forma de pagamento',
      key: 'paymentTypes',
      alternateMatches: paymentType,
      fieldType: {
        type: 'input'
      },
      example: 'PIX, Boleto, Cartão de crédito'
    },
    {
      label: 'Pago com',
      key: 'idPaymentType',
      alternateMatches: ['Pago com', 'pago com', 'PAGO COM'],
      fieldType: {
        type: 'select',
        options: [
          { label: 'PIX', value: '1' },
          { label: 'Boleto', value: '2' },
          { label: 'Cartão de crédito', value: '3' },
          { label: 'Cartão de débito', value: '4' },
          { label: 'Transferência', value: '5' },
          { label: 'Dinheiro', value: '6' },
          { label: 'Outros', value: '7' }
        ]
      },
      example: 'PIX'
    }
  ]
});

importConfig.refurbishItems = () => ({
  title: 'Importar itens de orçamento',
  formatters: { productPrice: value => formatNumber(value), laborPrice: value => formatNumber(value) },
  fields: [
    {
      label: 'Número*',
      alternateMatches: numberOrder,
      key: 'number',
      fieldType: {
        type: 'input'
      },
      example: '1.1.1',
      validations: [
        {
          rule: 'required',
          errorMessage: 'Número é obrigatório',
          level: 'error'
        }
      ]
    },
    {
      label: 'Tipo*',
      alternateMatches: typeName,
      key: 'type',
      fieldType: {
        type: 'select',
        options: [
          { label: 'Serviço', value: 'Serviço' },
          { label: 'Produto', value: 'Produto' },
          { label: 'Nível', value: 'Agrupador' },
          { label: 'Composição', value: 'Composição' }
        ]
      },
      example: 'Produto',
      validations: [
        {
          rule: 'required',
          errorMessage: 'Tipo é obrigatório',
          level: 'error'
        }
      ]
    },
    {
      label: 'Item*',
      key: 'name',
      alternateMatches: name,
      fieldType: {
        type: 'input'
      },
      example: 'Bancada',
      validations: [
        {
          rule: 'required',
          errorMessage: 'Nome é obrigatório',
          level: 'error'
        }
      ]
    },
    {
      label: 'Código',
      alternateMatches: codeName,
      key: 'code',
      fieldType: {
        type: 'input'
      },
      example: 'ABC123'
    },
    {
      label: 'Fornecedor',
      key: 'supplier',
      alternateMatches: ['FORNECEDOR', 'fornecedor'],
      fieldType: {
        type: 'input'
      },
      example: 'Tok&Stok'
    },
    {
      label: 'Grupo',
      key: 'refurbishGroup',
      alternateMatches: ['GRUPO', 'grupo'],
      fieldType: {
        type: 'input'
      },
      example: 'Cozinha'
    },
    {
      label: 'Categoria',
      key: 'costCenter',
      alternateMatches: ['CATEGORIA', 'categoria'],
      fieldType: {
        type: 'input'
      },
      example: 'Móveis'
    },
    {
      label: 'Custo un. Material',
      key: 'productPrice',
      alternateMatches: [
        ...priceName,
        'Custo unitário Material',
        'Custo unitário material',
        'Preço Material',
        'Preço material',
        'Material',
        'material',
        'Preço Produto',
        'Preço produto',
        'Custo Produto',
        'Custo produto'
      ],
      fieldType: {
        type: 'input'
      },
      example: '543,25'
    },
    {
      label: 'Custo un. Mão de obra',
      key: 'laborPrice',
      alternateMatches: [
        ...priceName,
        'Custo unitário Mão de Obra',
        'Custo unitário Mão de obra',
        'Custo unitário mão de obra',
        'Preço Mão de Obra',
        'Preço Mão de obra',
        'Preço mão de obra',
        'Mão de Obra',
        'Mão de obra',
        'mão de obra',
        'mão-de-obra',
        'Preço Mão de Obra',
        'Preço Mão de obra',
        'Preço mão de obra',
        'Custo Mão de Obra',
        'Custo Mão de obra',
        'Custo mão de obra'
      ],
      fieldType: {
        type: 'input'
      },
      example: '543,25'
    },
    {
      label: 'Quantidade',
      key: 'quantity',
      alternateMatches: quantityName,
      fieldType: {
        type: 'input'
      },
      example: '1'
    },
    {
      label: 'Unidade',
      alternateMatches: unitName,
      key: 'unit',
      fieldType: {
        type: 'input'
      },
      example: 'un'
    },
    {
      label: 'BDI',
      alternateMatches: ['bdi', 'BDI', 'Bdi'],
      key: 'bdi',
      fieldType: {
        type: 'input'
      },
      example: '10'
    },
    {
      label: 'Status',
      alternateMatches: statusName,
      key: 'status',
      fieldType: {
        type: 'select',
        options: [
          { label: 'Aprovado', value: 'approved' },
          { label: 'Não aprovado', value: 'rejected' },
          { label: 'Para aprovar', value: 'pending' }
        ]
      },
      example: 'Aprovado'
    },
    {
      label: 'Descrição',
      alternateMatches: observationName,
      key: 'itemObservation',
      fieldType: {
        type: 'input'
      },
      example: 'Bancada de madeira maciça'
    },
    {
      label: 'Altura (cm)',
      alternateMatches: ['Altura', 'ALTURA', 'altura', 'Altura (cm)'],
      key: 'height',
      fieldType: {
        type: 'input'
      },
      example: '100'
    },
    {
      label: 'Largura (cm)',
      alternateMatches: ['Largura', 'LARGURA', 'largura', 'Largura (cm)'],
      key: 'width',
      fieldType: {
        type: 'input'
      },
      example: '122'
    },
    {
      label: 'Comprimento (cm)',
      alternateMatches: ['Comprimento', 'COMPRIMENTO', 'comprimento', 'Comprimento (cm)'],
      key: 'length',
      fieldType: {
        type: 'input'
      },
      example: '133'
    },
    {
      label: 'Peso (kg)',
      alternateMatches: ['Peso', 'PESO', 'peso', 'Peso (kg)'],
      key: 'weight',
      fieldType: {
        type: 'input'
      },
      example: '40'
    }
  ]
});

importConfig.task = ({ idRefurbish }) => ({
  title: 'Importar tarefas',
  rowHook: (data, addError) => {
    textValidation(data, addError, 'name', 'nome');
    textValidation(data, addError, 'refurbishStep', 'Etapa');

    const validatePriority = ['baixa', 'média', 'alta'];
    if (data.priority?.length && !validatePriority.includes(data.priority?.trim()?.toLowerCase())) {
      addError('priority', {
        message: 'Prioridade não permitida, deve ser baixa, média ou alta!',
        level: 'error'
      });
    }

    const validateStatus = ['planejado', 'em andamento', 'concluído', 'cancelado'];
    if (data.status?.length && !validateStatus.includes(data.isActive?.trim()?.toLowerCase())) {
      addError('status', {
        message: 'Status não permitido, deve ser planejado, em andamento, concluído ou cancelado!',
        level: 'error'
      });
    }

    if (data.responsible?.length && !checkEmail(data.responsible)) {
      addError('responsible', {
        message: 'O email do responsável informado não é valido.',
        level: 'error'
      });
    }

    if (data.refurbishStep?.length && !data.idRefurbish?.length && !idRefurbish) {
      addError('idRefurbish', {
        message: 'É necessário ter um projeto para adicionar a tarefa a uma etapa.',
        level: 'error'
      });
    }

    if (data.estimatedHours?.length && !isValidHour(data.estimatedHours)) {
      addError('estimatedHours', {
        message: 'O campo de horas estimadas deve estar no formato HH:mm, HH: ou :mm',
        level: 'error'
      });
    }

    return data;
  },
  formatters: {
    startDate: value => formatDate(value),
    endDate: value => formatDate(value),
    estimativeDuration: value => getMinutesFromHHMM(value)
  },
  fields: [
    {
      label: 'Nome da tarefa*',
      key: 'name',
      alternateMatches: ['NOME', 'nome', 'Nome', 'NOME DA TAREFA', 'nome da tarefa'],
      fieldType: {
        type: 'input'
      },
      validations: [
        {
          rule: 'required',
          errorMessage: 'Nome é obrigatório',
          level: 'error'
        }
      ],
      example: 'Entrevista com o cliente'
    },
    {
      label: 'Etapa da tarefa',
      key: 'refurbishStep',
      alternateMatches: ['etapa', 'Etapa', 'ETAPA', 'ETAPA DA TAREFA', 'etapa da tarefa'],
      fieldType: {
        type: 'input'
      },
      example: 'Levantamento de dados'
    },
    ...(!idRefurbish
      ? [
          {
            label: 'Projeto - ID',
            key: 'idRefurbish',
            alternateMatches: [
              'projeto',
              'PROJETO',
              'Projeto',
              'projeto - ID',
              'PROJETO - ID',
              'projeto - id',
              'PROJETO - id',
              'Projeto - id'
            ],
            fieldType: {
              type: 'input'
            },
            example: '1234'
          }
        ]
      : []),
    {
      label: 'Data de Início',
      alternateMatches: [
        'Data de Início',
        'data de início',
        'data início',
        'DATA INÍCIO',
        'DATA DE INÍCIO',
        'início',
        'INÍCIO',
        'Início',
        'data inicio',
        'DATA INICIO',
        'inicio',
        'INICIO',
        'Inicio'
      ],
      key: 'startDate',
      fieldType: {
        type: 'input'
      },
      example: '06/06/2023'
    },
    {
      label: 'Data de Fim',
      alternateMatches: ['Data de Fim', 'data de fim', 'data fim', 'fim', 'DATA FIM', 'DATA DE FIM', 'FIM', 'Fim'],
      key: 'endDate',
      fieldType: {
        type: 'input'
      },
      example: '16/06/2023'
    },
    {
      label: 'Descrição',
      alternateMatches: ['descrição', 'DESCRICAO', 'descricao', 'DESCRIÇÃO'],
      key: 'description',
      fieldType: {
        type: 'input'
      },
      example: 'Descrição diversa'
    },
    {
      label: 'Horas estimadas',
      alternateMatches: ['horas estimadas', 'HORAS ESTIMADAS', 'Horas estimadas'],
      key: 'estimativeDuration',
      fieldType: {
        type: 'input'
      },
      example: '12:30'
    },
    {
      label: 'Responsável',
      alternateMatches: ['responsável', 'RESPONSÁVEL', 'Responsavel', 'responsavel', 'RESPONSAVEL'],
      key: 'responsible',
      fieldType: {
        type: 'input'
      },
      example: 'responsavel@email.com'
    },
    {
      label: 'Prioridade',
      alternateMatches: ['prioridade', 'PRIORIDADE'],
      key: 'priority',
      fieldType: {
        type: 'select',
        options: [
          { label: 'baixa', value: 1 },
          { label: 'média', value: 2 },
          { label: 'alta', value: 3 }
        ]
      },
      example: 'baixa'
    },
    {
      label: 'Status',
      alternateMatches: ['status', 'STATUS'],
      key: 'status',
      fieldType: {
        type: 'select',
        options: [
          { label: 'planejado', value: 1 },
          { label: 'em andamento', value: 2 },
          { label: 'concluído', value: 4 },
          { label: 'cancelado', value: 5 }
        ]
      },
      example: 'planejado'
    }
  ]
});

importConfig.conciliation = () => ({
  title: 'Importar conciliações',

  fields: [
    {
      label: 'Data',
      alternateMatches: ['Data', 'data', 'compensação'],
      key: 'date',
      fieldType: {
        type: 'input'
      },
      example: '16/06/2023',
      validations: [
        {
          rule: 'required',
          errorMessage: 'Data é obrigatória',
          level: 'error'
        }
      ]
    },
    {
      label: 'Descrição',
      key: 'description',
      alternateMatches: observationName,
      fieldType: {
        type: 'input'
      },
      example: 'pagamento teste',
      validations: [
        {
          rule: 'required',
          errorMessage: 'Nome é obrigatório',
          level: 'error'
        }
      ]
    },
    {
      label: 'Valor',
      key: 'value',
      alternateMatches: priceName,
      fieldType: {
        type: 'input'
      },
      example: '630,5',
      validations: [
        {
          rule: 'required',
          errorMessage: 'Valor é obrigatório',
          level: 'error'
        }
      ]
    }
  ]
});

importConfig.quoteSuppliers = () => ({
  title: 'Importar dados da cotação',

  fields: [
    {
      label: 'Frete',
      alternateMatches: ['frete', 'Frete'],
      key: 'shipping',
      fieldType: {
        type: 'input'
      },
      example: '123'
    },
    {
      label: 'Desconto',
      alternateMatches: ['desconto', 'Desconto'],
      key: 'discount',
      fieldType: {
        type: 'input'
      },
      example: '123'
    },
    {
      label: 'Imposto',
      alternateMatches: ['imposto', 'Imposto'],
      key: 'taxes',
      fieldType: {
        type: 'input'
      },
      example: '123'
    },
    {
      label: 'Previsão de entrega',
      alternateMatches: ['Previsão de entrega', 'entrega', 'Entrega'],
      key: 'expectedDate',
      fieldType: {
        type: 'input'
      },
      example: '16/06/2023'
    },
    {
      label: 'Prazo de validade da cotação',
      alternateMatches: ['Prazo de validade da cotação', 'validade', 'Validade'],
      key: 'dueDate',
      fieldType: {
        type: 'input'
      },
      example: '16/06/2023'
    },
    {
      label: 'Condições de pagamento',
      alternateMatches: ['Condições de pagamento', 'pagamento', 'Pagamento'],
      key: 'paymentInfo',
      fieldType: {
        type: 'input'
      },
      example: '3x sem juros'
    },
    {
      label: 'Observações',
      alternateMatches: observationName,
      key: 'observation',
      fieldType: {
        type: 'input'
      },
      example: 'Observação diversa'
    }
  ]
});

importConfig.quoteItemSuppliers = () => ({
  title: 'Importar cotação do fornecedor',

  fields: [
    {
      label: 'Código do item',
      alternateMatches: [...codeName, 'Código do item'],
      key: 'code',
      fieldType: {
        type: 'input'
      },
      example: '123',
      validations: [
        {
          rule: 'required',
          errorMessage: 'Código é obrigatório',
          level: 'error'
        }
      ]
    },
    {
      label: 'Nome do item',
      alternateMatches: [...name, 'Nome do item'],
      key: 'name',
      fieldType: {
        type: 'input'
      },
      example: 'Serviço teste',
      validations: [
        {
          rule: 'required',
          errorMessage: 'Nome é obrigatório',
          level: 'error'
        }
      ]
    },
    {
      label: 'Quantidade',
      key: 'quantity',
      alternateMatches: [...quantityName, 'Quantidade atendida'],
      fieldType: {
        type: 'input'
      },
      example: '10',
      validations: [
        {
          rule: 'required',
          errorMessage: 'Quantidade é obrigatória',
          level: 'error'
        }
      ]
    },
    {
      label: 'Valor unitário',
      key: 'price',
      alternateMatches: priceName,
      fieldType: {
        type: 'input'
      },
      example: '630,5',
      validations: [
        {
          rule: 'required',
          errorMessage: 'Preço é obrigatório',
          level: 'error'
        }
      ]
    }
  ]
});

importConfig.refurbish = ({ stepList, isOpportunity }) => {
  return {
    formatters: {
      predictionDate: value => formatDate(value),
      users: value => (value ? value.replace(/\s+/g, '').split(',') : [])
    },
    rowHook: (data, addError) => {
      textValidation(data, addError, 'name', 'nome');

      if (data?.predictionDate && !verifyDate(data?.predictionDate)) {
        addError('predictionDate', {
          message: <DateFormatError message="Data de início inválida" />,
          level: 'error'
        });
      }
      return data;
    },
    fields: [
      {
        label: 'Nome*',
        alternateMatches: [
          'Nome do Projeto',
          'Nome do projeto',
          'Nome da Oportunidade',
          'Nome',
          'Nome da oportunidade'
        ],
        key: 'name',
        fieldType: {
          type: 'input'
        },
        example: 'Projeto X',
        validations: [
          {
            rule: 'required',
            errorMessage: `Nome ${isOpportunity ? 'da oportunidade' : 'do projeto'} é obrigatório`,
            level: 'error'
          }
        ]
      },
      {
        label: 'Cliente',
        alternateMatches: ['CLIENTE', 'cliente', 'Cliente'],
        key: 'companyCustomerName',
        fieldType: {
          type: 'input'
        },
        example: 'João Silva'
      },
      {
        label: 'Origem',
        key: 'idOrigin',
        alternateMatches: ['Origem do Projeto', 'ORIGEM', 'Origem do projeto'],
        fieldType: {
          type: 'select',
          options: getOriginsOfOpportunity()
        },
        example: 'Indicação'
      },
      {
        label: 'Expectativa de início',
        key: 'predictionDate',
        fieldType: {
          type: 'input'
        },
        example: '01/01/2024'
      },
      {
        label: 'Pretensão de investimento',
        key: 'budget',
        fieldType: {
          type: 'input'
        },
        example: '50000,00'
      },
      {
        label: 'Valor de ganho da oportunidade',
        key: 'profits',
        fieldType: {
          type: 'input'
        },
        example: '10000,00'
      },
      {
        label: 'Metragem quadrada',
        key: 'm2',
        fieldType: {
          type: 'input'
        },
        example: '65'
      },
      {
        label: 'Status',
        key: isOpportunity ? 'idStatus' : 'idStep',
        fieldType: {
          type: 'select',
          options: isOpportunity
            ? getOpportunityRefurbishStatus()
            : stepList?.map(i => ({ label: i.name, value: i.id }))
        },
        example: isOpportunity ? 'novo' : 'Em andamento'
      },
      {
        label: 'Descrição',
        key: 'necessityDescription',
        alternateMatches: observationName,
        fieldType: {
          type: 'input'
        },
        example: 'Descrição do projeto'
      },
      {
        label: 'CEP',
        key: 'zipcode',
        alternateMatches: zipName,
        fieldType: {
          type: 'input'
        },
        example: '12345-678'
      },
      {
        label: 'Endereço',
        alternateMatches: addressName,
        key: 'street',
        fieldType: {
          type: 'input'
        },
        example: 'Rua Exemplo'
      },
      {
        label: 'Número',
        alternateMatches: number,
        key: 'number',
        fieldType: {
          type: 'input'
        },
        example: '123'
      },
      {
        label: 'Complemento',
        alternateMatches: complementName,
        key: 'complement',
        fieldType: {
          type: 'input'
        },
        example: 'Apto 101'
      },
      {
        label: 'Estado',
        alternateMatches: stateName,
        key: 'state',
        fieldType: {
          type: 'input'
        },
        example: 'SP'
      },
      {
        label: 'Cidade',
        alternateMatches: cityName,
        key: 'city',
        fieldType: {
          type: 'input'
        },
        example: 'São Paulo'
      },
      {
        label: 'Responsável',
        alternateMatches: emailName,
        key: 'users',
        fieldType: {
          type: 'input'
        },
        example: 'joao@gmail.com'
      }
    ]
  };
};

export default importConfig;
