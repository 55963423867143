import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faCircleInfo,
  faEllipsis,
  faShareNodes,
  faSquareDollar,
  faTrashCan,
  faXmark,
  faMessage,
  faArrowRightFromLine
} from '@fortawesome/pro-regular-svg-icons';
import {
  faBoxOpen,
  faLayerGroup,
  faScrewdriverWrench,
  faCircleSmall,
  faCaretRight,
  faCaretDown
} from '@fortawesome/pro-solid-svg-icons';
import { faAdd } from '@fortawesome/pro-light-svg-icons';
import { Divider, Tooltip, Menu, TreeSelect } from 'antd';
import styled from 'styled-components';
import useViewport from '../../_Hooks/useViewport';
import { BadgeText, Paragraph, Subtitle, Title } from '../Text/Text';
import Drawer from './Drawer';
import Tabs, { TabPane } from '../Tabs/Tabs';
import { colors, Div, spaces, fonts } from '../../styles/style';
import Input from '../Input/Input';
import Select from '../Select/Select';
import TooltipIcon from '../Tooltip/TooltipIcon';
import ItemAttachmentContent from '../Content/ItemAttachmentContent';
import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import DropdownV2 from '../Dropdown/DropdownV2';
import CopyLinkButton from '../Button/CopyLinkButton';
import EditModalFactory from '../EditModalFactory';
import useCRUD from '../../_Hooks/useCRUD';
import { planningMapping, planningSchema } from '../../lib/mapping/Form/planningSchema';
import FormV2 from '../Form/FormV2';
import ConfirmModal from '../Modal/ConfirmModal';
import CommentListV2 from '../List/CommentListV2';
import useRefurbishPlanningDrawer from '../../_Hooks/useRefurbishPlanningDrawer';
import useTemplatePlanningDrawer from '../../_Hooks/useTemplatePlanningDrawer';
import useGantt from '../../_Hooks/useGantt';
import { hasPermission } from '../../routes/Common/PrivateRoute';
import useFilter from '../../_Hooks/useFilter';
import AnalyticsServiceFactory from '../../lib/analytics/analytics';

const ContainerDropdownTreeSelect = styled(Div)`
  .ant-tree-select-dropdown {
    padding: 0px;
  }

  .ant-select-tree-treenode.ant-select-tree-treenode-switcher-open {
    &:hover {
      background-color: ${colors.neutral75};
    }
  }

  .ant-select-tree .ant-select-tree-node-content-wrapper {
    &:hover {
      background-color: ${colors.neutral75};
    }
  }

  .ant-select-tree .ant-select-tree-node-content-wrapper {
    transition: all 0s !important;
  }

  .ant-select-tree .ant-select-tree-treenode {
    padding: 0px 4px !important;
  }

  .ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
    background-color: ${colors.neutral75} !important;
  }

  .ant-select-tree-treenode.ant-select-tree-treenode-switcher-open.ant-select-tree-treenode-selected {
    background-color: ${colors.neutral75} !important;
  }
`;

const childrenModel = {
  'refurbish-items': 'refurbishItems',
  'template-item': 'templateItem'
};

const EditPlanningDrawer = ({
  open,
  onClose,
  tab = '0',
  desktopWidth = '780px',
  id,
  dataGantt,
  afterSubmit = f => f,
  isTemplate,
  idReference,
  ...props
}) => {
  const { isMobile } = useViewport(window.innerWidth);
  const _isMobile = isMobile();
  const { plans, permissions } = useSelector(state => state.setup);
  const { refurbishItemType, userType, specificationDefaultColumnsToShow } = useSelector(state => state.setup.enums);
  const { user } = useSelector(state => state.authReducer);
  const [files, setFiles] = useState([]);

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const { storedFilters, updateFilters } = useFilter({
    persistKey: 'show-comments-side-component'
  });

  const isCustomer = user.userType === userType.customer.value;
  const isAnonymous = !!user?.anonymous;

  let initialShowCommentsSideComponent = storedFilters !== null ? storedFilters?.showCommentsSideComponent : true;
  if (isAnonymous) initialShowCommentsSideComponent = false;

  const [showComponents, setShowComponents] = useState({
    showMobileOptionsModal: false,
    showShareDropdown: false,
    showBudgetModal: false,
    showSelectPredecessors: false,
    showCommentsSideComponent: initialShowCommentsSideComponent,
    showCommentsModal: false
  });
  const [selectedTab, setSelectedTab] = useState(!isCustomer ? tab : '1');

  const analytics = AnalyticsServiceFactory();

  const [description, setDescription] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const [newFormValues, setNewFormValues] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [internalDataGantt, setInternalDataGantt] = useState({ data: [], links: [], resources: [] });

  const model = isTemplate ? 'template-item' : 'refurbish-items';
  const referenceKey = isTemplate ? 'idTemplate' : 'idRefurbish';
  const chatWidth = '360px';

  const { data, loading } = useCRUD({
    model,
    immediatelyLoadData: !!id,
    pathOptions: `/${id}`,
    options: {
      where: { [referenceKey]: idReference },
      attributes: [
        'id',
        'name',
        'code',
        'quantity',
        'type',
        'description',
        'plStartDate',
        'plEndDate',
        'plIdResponsible',
        'duration',
        'quantity',
        referenceKey
      ],
      include: [
        { model: 'user', attributes: ['id', 'name', 'avatarFullpath'] },
        { model: 'supplier', attributes: ['id', 'name'], as: 'supplier' },
        { model: childrenModel[model], attributes: ['id', 'name', 'color'], as: 'parent' },
        ...(!isTemplate
          ? [
              'files',
              {
                model: 'refurbish',
                attributes: ['id', 'workingDays', 'itemsToShare', 'clientColumns'],
                include: [{ model: 'user', attributes: ['id', 'name'] }, 'refurbishDayoffs']
              },
              { model: 'comments', attributes: ['id'], as: 'comments' }
            ]
          : [])
      ]
    }
  });

  const { ganttInstance, transformAndFindUsers, updateLinkLags, cleanUnscheduledFromChildren } = useGantt({
    idRefurbish: data?.refurbish?.id,
    workingDays: data?.refurbish?.workingDays,
    dayoffs: data?.refurbish?.refurbishDayoffs,
    tasks: internalDataGantt
  });

  const { handleGet: getItems } = useCRUD({
    model,
    immediatelyLoadData: false
  });

  const { handleGet: handleGetPlanningLink } = useCRUD({
    model: 'planningLink',
    immediatelyLoadData: false
  });

  const hookToUse = useMemo(() => (isTemplate ? useTemplatePlanningDrawer : useRefurbishPlanningDrawer), [isTemplate]);
  const propsForTrack = { idCompany: user?.idCompany, idRefurbish: data?.idRefurbish, idUser: user?.id };

  const { createLink, removeLink, updateLink, handleSubmit, onChangeDateRelatedField } = hookToUse({
    data,
    setNewFormValues,
    newFormValues,
    setIsSubmitting,
    afterSubmit,
    setHasChanges,
    setSelectedItems,
    ganttInstance,
    updateLinkLags,
    cleanUnscheduledFromChildren
  });

  useEffect(() => {
    if (!ganttInstance) return;

    ganttInstance.plugins({
      auto_scheduling: true
    });
  }, [ganttInstance]);

  useEffect(() => {
    if (!ganttInstance || internalDataGantt.data.length === 0) return;
    ganttInstance.parse(internalDataGantt);
  }, [internalDataGantt]);

  useEffect(() => {
    if (!data || !dataGantt) return;
    const ganttTasks = dataGantt.data || [];
    const links = dataGantt.links || [];

    const promise = ganttTasks?.length
      ? Promise.resolve([ganttTasks, links])
      : Promise.all([
          getItems({ refetchOptions: { where: { [referenceKey]: idReference } } }),
          handleGetPlanningLink({ refetchOptions: { where: { [referenceKey]: idReference } } })
        ]).then(([_ganttasks, _links]) => {
          const result = transformAndFindUsers(_ganttasks, null, null, false, {
            ...(!isTemplate && data?.refurbish?.plBasedDate && { type: 'task' })
          });
          return [result?.data, _links];
        });

    promise.then(([_tasks, _links]) => {
      const newSelectedItems = [];

      // Process links to build newSelectedItems
      _links.forEach(link => {
        if (Number(link.target) === data.id) {
          const sourceTask = _tasks.find(task => task.id === Number(link.source));
          if (sourceTask) {
            newSelectedItems.push({
              source: Number(link.source),
              type: link.type || ganttInstance.config.links.finish_to_start,
              lag: link.lag,
              id: link.id
            });
          }
        }
      });

      setInternalDataGantt({
        data: _tasks,
        links: _links
      });

      setSelectedItems(newSelectedItems);
    });
  }, [data, dataGantt]);

  useEffect(() => {
    if (data) {
      setFiles(data.files || []);
      setDescription(data.description || '');
      if (isCustomer && data?.refurbish?.itemsToShare?.planningTimeline) setSelectedTab(tab);
    }
  }, [data]);

  const handleClose = () => {
    if (hasChanges) {
      setShowConfirmModal(true);
    } else {
      onClose({});
    }
  };

  const handleConfirmModalClose = (_, opts) => {
    if (opts?.discard) {
      setHasChanges(false);
      onClose();
    } else {
      setShowConfirmModal(false);
    }
  };

  const itemTypeMap = {
    [refurbishItemType.labor]: { name: 'Serviço', icon: faScrewdriverWrench },
    [refurbishItemType.product]: { name: 'Produto', icon: faBoxOpen },
    [refurbishItemType.composition]: { name: 'Composição', icon: faLayerGroup }
  };

  const mappedItemType = itemTypeMap[data?.type] || null;

  const typeOptions = [
    { name: _isMobile ? 'FI' : 'Fim - Início', value: ganttInstance.config.links.finish_to_start },
    { name: _isMobile ? 'FF' : 'Fim - Fim', value: ganttInstance.config.links.finish_to_finish },
    { name: _isMobile ? 'II' : 'Início - Início', value: ganttInstance.config.links.start_to_start },
    { name: _isMobile ? 'IF' : 'Início - Fim', value: ganttInstance.config.links.start_to_finish }
  ];

  const { list, handleGet: getList } = useCRUD({
    model,
    immediatelyLoadData: false
  });

  useEffect(() => {
    if (data) {
      getList({
        refetchOptions: {
          attributes: ['name', 'id', 'type', 'color', 'idParent', 'plStartDate', 'plEndDate', 'duration'],
          where: {
            type: refurbishItemType.parent,
            idParent: null,
            [referenceKey]: data?.[referenceKey]
          },
          include: [
            {
              model: childrenModel[model],
              as: 'children',
              attributes: ['name', 'id', 'type', 'color', 'idParent', 'plStartDate', 'plEndDate', 'duration'],
              required: false,

              include: [
                {
                  model: childrenModel[model],
                  as: 'children',
                  attributes: ['name', 'id', 'type', 'color', 'idParent', 'plStartDate', 'plEndDate', 'duration'],
                  required: false,
                  where: {
                    [`$children.type$`]: refurbishItemType.parent
                  }
                }
              ]
            }
          ],
          order: [['order']]
        }
      });
    }
  }, [data]);

  const getLevelListAndColorsFromItems = (listToParse, actualItem, parentColor) => {
    if (!listToParse || listToParse?.length === 0) return [];

    return listToParse.map(item => {
      const shouldDisableItem = (_item, targetId) => {
        if (_item?.id === targetId) return true;
        if (_item?.type === refurbishItemType?.parent) return true;

        const potentialLink = {
          source: Number(_item.id),
          target: Number(targetId),
          type: ganttInstance.config.links.finish_to_start
        };
        if (ganttInstance.isCircularLink(potentialLink)) return true;

        const hasDisabledDescendants = _item?.children?.some(child => {
          return child?.id === targetId || shouldDisableItem(child, targetId);
        });
        return hasDisabledDescendants;
      };

      const isParent = shouldDisableItem(item, id);
      const itemColor =
        item?.type === refurbishItemType?.parent && !item?.idParent
          ? item?.color || colors.neutral300
          : parentColor || colors.neutral300;

      const isSelected = selectedItems.some(selected => Number(selected?.source) === item?.id);

      return {
        value: `${item?.id}`,
        disabled: isParent || (isSelected && item?.id !== Number(actualItem)),
        title: (
          <Div $fullWidth $fullHeight justify="space-between">
            <Paragraph
              color={
                isParent || (isSelected && item?.id !== Number(actualItem)) ? colors.neutral400 : colors.neutral600
              }
              type="small"
              fo
              ellipsis
              weight={
                item?.id !== Number(actualItem) && item?.type === refurbishItemType?.parent
                  ? fonts.weight600
                  : fonts.weight500
              }
            >
              {item.name || '-'}
            </Paragraph>
            <Div padding={spaces.space0}>
              <FontAwesomeIcon color={itemColor} icon={faCircleSmall} />
            </Div>
          </Div>
        ),
        children: getLevelListAndColorsFromItems(item?.children, actualItem, itemColor) || []
      };
    });
  };

  const handleShareWhatsapp = () => {
    analytics.track({
      event: 'construct-schedule-item-shared',
      eventProperties: { ...propsForTrack, type: 'Whatsapp' }
    });
    window.open(
      `https://wa.me/?text=Olá%20veja%20esse%20item%20do%20seu%20projeto%20na%20platafo
      rma:%20${window.location.origin}/o/${id}`,
      '_blank'
    );
  };

  const renderListPredecessors = () => {
    const dependanceWidth = _isMobile ? '50%' : '65%';
    const typeWidth = '18%';
    let intervalWidth = _isMobile ? '15%' : '10%';
    if (isCustomer) intervalWidth = _isMobile ? '25%' : '15%';
    const deleteWidth = _isMobile ? '10%' : '5%';

    return (
      <Div margin={`${spaces.space1} 0 0 0`} gap={spaces.space1} $fullWidth direction="column">
        <Div $fullWidth gap={spaces.space1}>
          {showComponents?.showSelectPredecessors || selectedItems.length > 0 ? (
            <Div width={dependanceWidth} $minWidth={dependanceWidth}>
              <Paragraph type="small">Depende de</Paragraph>
            </Div>
          ) : null}

          {selectedItems?.length > 0 && (
            <>
              <Div width={typeWidth} $minWidth={typeWidth}>
                <Paragraph type="small">Tipo</Paragraph>
              </Div>
              <Div width={intervalWidth} $minWidth={intervalWidth}>
                <Paragraph type="small">Intervalo</Paragraph>
              </Div>
              <Div width={deleteWidth} $minWidth={deleteWidth}>
                <></>
              </Div>
            </>
          )}
        </Div>

        {selectedItems?.length > 0 &&
          selectedItems.map(link => {
            const { source, type: _type, lag } = link;
            return (
              <Div $fullWidth gap={spaces.space1} key={source}>
                <Div width={dependanceWidth}>
                  <TreeSelect
                    getPopupContainer={() => document.getElementById(`containerTreeSelect`)}
                    switcherIcon={({ expanded }) =>
                      expanded ? (
                        <FontAwesomeIcon color={colors.neutral500} icon={faCaretDown} />
                      ) : (
                        <FontAwesomeIcon color={colors.neutral500} icon={faCaretRight} />
                      )
                    }
                    showSearch
                    style={{ width: '100%' }}
                    className="tree-select-level"
                    treeDefaultExpandAll
                    value={source}
                    onChange={newValue => {
                      updateLink(link, { value: newValue });
                    }}
                    treeData={getLevelListAndColorsFromItems(list, source)}
                    onClick={e => {
                      e.stopPropagation();
                    }}
                    disabled={isCustomer}
                    treeNodeFilterProp="title"
                    onDropdownVisibleChange={() =>
                      setShowComponents({ ...showComponents, showSelectPredecessors: false })
                    }
                  />
                </Div>
                <Div width={typeWidth}>
                  <Select
                    name="idType"
                    options={typeOptions}
                    placeholder="Selecione..."
                    onChange={newType => {
                      updateLink(link, { type: newType });
                    }}
                    value={_type}
                    disabled={isCustomer}
                  />
                </Div>
                <Div width={intervalWidth}>
                  <Input
                    onChange={e => {
                      updateLink(link, { lag: Number(e.target.value) });
                    }}
                    value={lag}
                    placeholder={_isMobile ? '0' : '0 dias'}
                    type="number"
                    disabled={isCustomer}
                  />
                </Div>
                {!isCustomer && (
                  <Div width={deleteWidth}>
                    <Button
                      type="iconNeutral"
                      justify="center"
                      width={spaces.space4}
                      height={spaces.space4}
                      onClick={() => {
                        removeLink(link);
                      }}
                    >
                      <FontAwesomeIcon color={colors.neutral500} icon={faTrashCan} />
                    </Button>
                  </Div>
                )}
              </Div>
            );
          })}
      </Div>
    );
  };

  const renderPredecessors = () => {
    const onEmpty = isCustomer ? <Paragraph>Nenhuma predecessora adicionada.</Paragraph> : null;
    return (
      <Div padding={spaces.space2} $fullWidth>
        <Div direction="column" align="flex-start" $fullWidth gap={spaces.space1}>
          <Div gap={spaces.space0}>
            <Subtitle>{`${isCustomer ? 'P' : 'Configurar p'}redecessoras`}</Subtitle>
            <TooltipIcon
              icon={faCircleInfo}
              iconColor={colors.primary500}
              text="Após escolher o item relacionado e o tipo de dependência, as respectivas conexões
            aparecerão na visualização de Linha do Tempo do projeto e as datas serão sincronizadas
            de acordo com as datas e os intervalos definidos."
            />
          </Div>
          {selectedItems?.length > 0 || showComponents?.showSelectPredecessors ? renderListPredecessors() : onEmpty}
          {showComponents?.showSelectPredecessors && (
            <TreeSelect
              getPopupContainer={() => document.getElementById(`containerTreeSelect`)}
              switcherIcon={({ expanded }) =>
                expanded ? (
                  <FontAwesomeIcon color={colors.neutral500} icon={faCaretDown} />
                ) : (
                  <FontAwesomeIcon color={colors.neutral500} icon={faCaretRight} />
                )
              }
              open={showComponents?.showSelectPredecessors}
              id="tree-select-level"
              showSearch
              className="tree-select-level"
              style={{ width: '100%' }}
              treeDefaultExpandAll
              onChange={createLink}
              treeData={getLevelListAndColorsFromItems(list)}
              onClick={e => {
                e.stopPropagation();
              }}
              disabled={isCustomer}
              treeNodeFilterProp="title"
              onDropdownVisibleChange={() => setShowComponents({ ...showComponents, showSelectPredecessors: false })}
            />
          )}

          {!isCustomer && (
            <Button
              align="start"
              text
              onClick={() => setShowComponents({ ...showComponents, showSelectPredecessors: true })}
            >
              <FontAwesomeIcon icon={faAdd} />
              Adicionar dependência
            </Button>
          )}
        </Div>
      </Div>
    );
  };

  const renderAttachment = () => (
    <Div padding={spaces.space2} width="100%">
      <ItemAttachmentContent
        readOnly={isCustomer}
        hideTitle
        hasDescription={false}
        data={{ files }}
        setFiles={setFiles}
      />
    </Div>
  );

  const menu = (
    <Menu>
      <Menu.Item>
        <Button onClick={handleShareWhatsapp} id="share-planning" text>
          Compartilhar no Whatsapp
        </Button>
      </Menu.Item>
      <Menu.Item>
        <CopyLinkButton
          customEvent="construct-schedule-item-shared"
          customEventProperties={{ ...propsForTrack, type: 'Copy' }}
          urlContext="o"
          idData={id}
          id="copy-link"
          text
        >
          Copiar link
        </CopyLinkButton>
      </Menu.Item>
    </Menu>
  );

  const renderHeaderIcons = () => {
    const icons = [
      ...(!isTemplate && !isAnonymous
        ? [
            {
              icon: faMessage,
              title: 'Comentários',
              onClick: () =>
                setShowComponents({
                  ...showComponents,
                  showCommentsSideComponent: !showComponents.showCommentsSideComponent
                })
            }
          ]
        : []),
      ...(hasPermission(user, ['item'], plans, permissions) && (!isCustomer || data?.refurbish?.itemsToShare?.item)
        ? [
            {
              icon: faSquareDollar,
              title: 'Ver detalhes do orçamento',
              onClick: () => {
                analytics.track({
                  event: 'construct-schedule-budget-item-viewed',
                  eventProperties: { ...propsForTrack }
                });
                setShowComponents({ ...showComponents, showBudgetModal: true });
              }
            }
          ]
        : []),
      {
        icon: faShareNodes,
        title: 'Compartilhar',
        onClick: () => setShowComponents({ ...showComponents, showShareDropdown: true })
      },
      { icon: faXmark, title: 'Fechar', onClick: handleClose }
    ];

    return (
      <Div gap={spaces.space0}>
        {_isMobile ? (
          <Div>
            {!isAnonymous && (
              <BadgeText
                background={colors.neutral600}
                $textColor={colors.white}
                $fontSize={fonts.sizeXs}
                $padding={data?.comments?.length > 9 ? `0px ${spaces.space0}` : `0px`}
                $borderRadius={spaces.space1}
                size="small"
                offset={[-5, 5]}
                count={data?.comments?.length || 0}
              >
                <Button
                  type="iconNeutral"
                  justify="center"
                  width={spaces.space4}
                  height={spaces.space4}
                  pressed={showComponents.showCommentsModal}
                  onClick={() => setShowComponents({ ...showComponents, showCommentsModal: true })}
                  style={{ position: 'relative' }}
                >
                  <FontAwesomeIcon color={colors.neutral500} icon={faMessage} />
                </Button>
              </BadgeText>
            )}
            <Button
              type="iconNeutral"
              width={spaces.space4}
              height={spaces.space4}
              onClick={() => setShowComponents({ ...showComponents, showMobileOptionsModal: true })}
            >
              <FontAwesomeIcon color={colors.neutral500} icon={faEllipsis} />
            </Button>
          </Div>
        ) : (
          icons.map(({ icon, title, onClick }) => (
            <Tooltip key={title} placement="bottom" title={title}>
              <BadgeText
                background={colors.neutral600}
                $fontSize={fonts.sizeXs}
                $textColor={colors.white}
                $padding={data?.comments?.length > 9 ? `0px ${spaces.space0}` : `0px`}
                $borderRadius={spaces.space1}
                size="small"
                offset={[-5, 5]}
                count={icon === faMessage ? data?.comments?.length : 0}
              >
                <Button
                  type="iconNeutral"
                  justify="center"
                  pressed={icon === faMessage && showComponents.showCommentsSideComponent}
                  width={spaces.space4}
                  height={spaces.space4}
                  onClick={onClick}
                  style={{ position: 'relative' }}
                >
                  <FontAwesomeIcon icon={icon} />
                </Button>
              </BadgeText>
            </Tooltip>
          ))
        )}
        {showComponents?.showShareDropdown && (
          <DropdownV2
            open={showComponents?.showShareDropdown}
            slim
            trigger={['click']}
            menu={menu}
            padding={0}
            showIcon={false}
            onOpenChange={v => setShowComponents({ ...showComponents, showShareDropdown: v })}
          />
        )}
      </Div>
    );
  };

  const hasTimelinePermission =
    (isCustomer && data?.refurbish?.itemsToShare?.planningTimeline) ||
    (!isCustomer && hasPermission(user, ['planningTimeline'], plans, permissions));

  const renderMobileContent = () => (
    <>
      {renderPredecessors()}
      {!isTemplate && (
        <>
          <Divider style={{ margin: `${spaces.space1} 0` }} />
          {renderAttachment()}
        </>
      )}
    </>
  );

  const renderDesktopContent = () => (
    <Tabs activeKey={selectedTab} onChange={setSelectedTab} fullHeight={false} width="100%">
      {hasTimelinePermission ? (
        <TabPane key="0" tab="Dependências" forceRender>
          {renderPredecessors()}
        </TabPane>
      ) : null}
      {!isTemplate ? (
        <TabPane key="1" tab="Anexos" forceRender>
          {renderAttachment()}
        </TabPane>
      ) : null}
    </Tabs>
  );

  const renderItemTypeIcon = () => {
    const itemType = itemTypeMap[data?.type];
    if (!itemType) return null;

    return (
      <Div gap={spaces.space1}>
        <Div width={spaces.space4} height={spaces.space4} align="center" justify="center">
          <FontAwesomeIcon icon={itemType.icon} style={{ color: colors.neutral300 }} />
        </Div>
      </Div>
    );
  };

  const renderCommentsSideComponent = () => (
    <>
      <Button
        style={{
          position: 'absolute',
          right: showComponents?.showCommentsSideComponent ? '346px' : `-${spaces.space3}`,
          top: '109px',
          zIndex: 1000,
          transition: 'right 0.3s ease-in-out'
        }}
        type="outline"
        size="small"
        width={spaces.space3}
        shape="circle"
        onClick={() => {
          setShowComponents({ ...showComponents, showCommentsSideComponent: false });
        }}
      >
        <FontAwesomeIcon style={{ fontSize: fonts.sizeSm }} icon={faArrowRightFromLine} />
      </Button>
      <Div
        style={{
          maxWidth: showComponents?.showCommentsSideComponent ? chatWidth : '0',
          width: '100%',
          height: '100%',
          position: 'relative',
          transition: 'width 0.3s ease-in-out',
          overflow: 'hidden'
        }}
      />
      <Div
        direction="column"
        style={{
          position: 'absolute',
          backgroundColor: colors.white,
          height: `calc(100% - (${spaces.space8} + 57px))`,
          right: 0,
          top: `${spaces.space8}`,
          bottom: '57px',
          borderLeft: showComponents?.showCommentsSideComponent ? `1px solid ${colors.neutral100}` : 'none',
          borderTop: showComponents?.showCommentsSideComponent ? `1px solid ${colors.neutral100}` : 'none',
          width: showComponents?.showCommentsSideComponent ? chatWidth : '0',
          transition: 'width 0.3s ease-in-out',
          overflow: 'hidden'
        }}
      >
        {showComponents?.showCommentsSideComponent && (
          <Div $fullWidth direction="column" $fullHeight>
            <CommentListV2
              data={data}
              idRefurbish={data?.idRefurbish}
              model={model}
              tooltipText="Os comentários são compartilhados entre os módulos de Orçamento e Planejamento.
          Tudo que você comentar aqui também será apresentado no Orçamento."
            />
          </Div>
        )}
      </Div>
    </>
  );

  const calcDrawerWidth = () => {
    if (_isMobile) return '100%';
    if (showComponents?.showCommentsSideComponent) return `calc(${desktopWidth} + ${chatWidth})`;
    return desktopWidth;
  };

  useEffect(() => {
    if (showComponents.showCommentsSideComponent !== storedFilters?.showCommentsSideComponent)
      updateFilters({ ...storedFilters, showCommentsSideComponent: showComponents.showCommentsSideComponent });
  }, [showComponents.showCommentsSideComponent]);

  return (
    <>
      <Drawer
        open={open}
        formId="planningForm"
        title={
          <Div gap={spaces.space1}>
            {_isMobile && (
              <Div onClick={handleClose} padding={spaces.space1}>
                <FontAwesomeIcon icon={faArrowLeft} />
              </Div>
            )}
            {renderItemTypeIcon()}
            <Title id="planning-title">{mappedItemType?.name}</Title>
          </Div>
        }
        onClose={handleClose}
        padding="0px"
        width={calcDrawerWidth()}
        closeIcon={false}
        extra={renderHeaderIcons()}
        loading={isSubmitting || loading}
        submitText="Salvar"
        showSubmit={!isCustomer}
      >
        <Div direction="row" $fullHeight>
          <Div $minWidth={_isMobile ? '100%' : desktopWidth} direction="column" $fullHeight align="flex-start">
            <ContainerDropdownTreeSelect id="containerTreeSelect" />
            <Div display="block" padding={spaces.space2} width="100%">
              <Div margin={`0 0 ${spaces.space3} 0`} align="center" gap={spaces.space1}>
                <Title weight={fonts.weight700}>{data?.name}</Title>
              </Div>
              <FormV2
                id="planningForm"
                newValues={newFormValues}
                data={{ ...data, responsible: data?.user }}
                schema={planningSchema}
                onValueChanged={setHasChanges}
                mapping={planningMapping({
                  parent: data?.parent,
                  supplier: data?.supplier,
                  onChangeDateRelatedField,
                  workDays: data?.refurbish?.workingDays,
                  dayoffs: data?.refurbish?.refurbishDayoffs,
                  isTemplate
                })}
                onSubmit={submitData => handleSubmit({ ...submitData, ...newFormValues, description }, files)}
                displayButtons={false}
                readOnly={isCustomer}
              />
              <Div direction="column" gap={spaces.space1} align="flex-start" margin={`${spaces.space2} 0 0 0`}>
                {_isMobile ? <Subtitle>Descrição</Subtitle> : null}
                {isCustomer ? (
                  <Paragraph>{description}</Paragraph>
                ) : (
                  <Input
                    value={description}
                    onChange={e => {
                      setDescription(e.target.value);
                      setHasChanges(true);
                    }}
                    className="form-component"
                    type="textarea"
                    placeholder="Adicione uma descrição..."
                    minRows={_isMobile ? 1 : 3}
                  />
                )}
              </Div>
            </Div>
            {_isMobile ? renderMobileContent() : renderDesktopContent()}
            {showComponents?.showBudgetModal && (
              <EditModalFactory
                {...props}
                onClose={() => {
                  setShowComponents({ ...showComponents, showBudgetModal: false, showMobileOptionsModal: false });
                }}
                isTemplate={isTemplate}
                readOnly
                hideAttachments
                hideComments
                id={id}
                idReference={idReference}
                idCompany={user?.idCompany}
                type={data?.type}
                model={model}
                renderColumns={(isCustomer && data?.refurbish?.clientColumns) || specificationDefaultColumnsToShow}
              />
            )}
          </Div>
          {!isTemplate && !_isMobile ? renderCommentsSideComponent() : null}
        </Div>
      </Drawer>
      {showConfirmModal && (
        <ConfirmModal
          title="Alterações não salvas"
          text="Você possui alterações que não foram salvas. Salve ou descarte as alterações para continuar."
          formId="planningForm"
          alertInfo="Essa ação não poderá ser desfeita."
          submitText="Salvar"
          showDiscardButton
          onClose={handleConfirmModalClose}
        />
      )}
      {showComponents?.showCommentsModal && (
        <Modal
          title={
            <Div $fullWidth direction="row" align="center" gap={spaces.space1}>
              <Paragraph type="large" weight={fonts.weight600} ellipsis>
                Comentários
              </Paragraph>
              <TooltipIcon
                icon={faCircleInfo}
                iconColor={colors.primary500}
                text="Os comentários são compartilhados entre os módulos de Orçamento e Planejamento.
                  Tudo que você comentar aqui também será apresentado no Orçamento."
              />
            </Div>
          }
          open={showComponents?.showCommentsModal}
          hideFooter
          onClose={() => setShowComponents({ ...showComponents, showCommentsModal: false })}
        >
          <CommentListV2 data={data} idRefurbish={data?.idRefurbish} isMobile={_isMobile} model={model} />
        </Modal>
      )}
      {showComponents?.showMobileOptionsModal && (
        <Modal
          title="Opções"
          open={showComponents?.showMobileOptionsModal}
          hideFooter
          onClose={() => setShowComponents({ ...showComponents, showMobileOptionsModal: false })}
        >
          {hasPermission(user, ['item'], plans, permissions) && (!isCustomer || data?.refurbish?.itemsToShare?.item) ? (
            <Button
              text
              onClick={() => {
                setShowComponents({ ...showComponents, showBudgetModal: true });
                analytics.track({
                  event: 'construct-schedule-budget-item-viewed',
                  eventProperties: { ...propsForTrack }
                });
              }}
            >
              Ver detalhes do orçamento
            </Button>
          ) : null}
          <CopyLinkButton
            customEvent="construct-schedule-item-shared"
            customEventProperties={{ ...propsForTrack, type: 'Copy' }}
            urlContext="o"
            idData={id}
            id="copy-link"
            text
          >
            Copiar link
          </CopyLinkButton>
          <Button text onClick={handleShareWhatsapp}>
            Compartilhar no Whatsapp
          </Button>
        </Modal>
      )}
    </>
  );
};

EditPlanningDrawer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  tab: PropTypes.string,
  desktopWidth: PropTypes.string,
  id: PropTypes.number,
  isTemplate: PropTypes.bool,
  dataGantt: PropTypes.instanceOf(Object),
  afterSubmit: PropTypes.func,
  idReference: PropTypes.number
};

export default EditPlanningDrawer;
