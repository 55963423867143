import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import useCRUD from '../_Hooks/useCRUD';
import { saveRefurbish } from '../store/refurbish/actions/action';
import CenteredLoader from '../components/Loader/CenteredLoader';
import { mappingRoutes } from '../lib/mapping/Routes/mappingRoutes';

const RedirectLink = () => {
  const { user } = useSelector(state => state.authReducer) || {};
  const refurbish = useSelector(state => state.refurbishReducer);
  const { userType } = useSelector(state => state.setup.enums);
  const isCustomer = !user || user.userType === userType.customer.value;
  const { id, tab, action } = useParams();
  const history = useHistory();
  const { type } = user || {};
  const {
    location: { pathname }
  } = history;

  const letter = pathname?.split('/')?.[1];

  const { model, childrenPath, queryName, options, openRoot } =
    mappingRoutes?.[letter]?.({
      ...user,
      idModel: id,
      idRefurbish: refurbish?.id
    }) || {};

  const { data, loading } = useCRUD({
    model,
    pathOptions: `/${id}`,
    options,
    immediatelyLoadData: !!id
  });

  const dispatch = useDispatch();

  let idRefurbish = null;

  if ((queryName !== 'arquivo' && queryName !== 'nota') || data?.shared || data?.folder?.shared || !isCustomer) {
    idRefurbish = data?.idRefurbish || data?.refurbishes?.[0]?.id;
  }

  useEffect(() => {
    if (!loading && idRefurbish && (data?.refurbish || data?.refurbishes?.[0])) {
      dispatch(saveRefurbish(data?.refurbish || data?.refurbishes?.[0]));
    }
  }, [loading]);

  let userPath;

  if (!loading) {
    const redirectChildrenPath = childrenPath === 'orcamento' ? `${childrenPath}` : childrenPath;
    let queryParam = queryName && data?.id ? `${queryName}=${data?.id}` : null;

    if (childrenPath === 'orcamento' && queryName === 'item' && data?.type) {
      queryParam = `${queryParam}&type=${data.type}`;
    }

    if (isCustomer && idRefurbish) {
      userPath = `/cliente/${redirectChildrenPath}?${queryParam || ''}${tab ? `&tab=${tab}` : ''}`;
    } else if (!loading && !data && isCustomer) {
      userPath = `/cliente/${redirectChildrenPath}${tab ? `?tab=${tab}` : ''}`;
    } else if (!openRoot && idRefurbish) {
      userPath = `/profissional/projetos/perfil/${idRefurbish}/${redirectChildrenPath}?${queryParam || ''}${
        tab ? `&tab=${tab}` : ''
      }${action ? `&acao=${action}` : ''}`;
    } else {
      userPath = `/profissional/${redirectChildrenPath}?${queryParam || ''}${action ? `&acao=${action}` : ''}`;
    }
    return history.push(userPath);
  }
  if (!loading && id && ((openRoot && !data) || (!idRefurbish && !openRoot))) {
    toast.error('Link inválido');
    return history.push(`/${type}/dashboard`);
  }
  return <CenteredLoader />;
};

export default RedirectLink;
