import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import parse from 'html-react-parser';
import { toast } from 'react-toastify';
import NotesList from '../../components/List/NotesList';
import ModalConfirm from '../../components/Modal/ConfirmModal';
import RichTextEditor from '../../components/RichTextEditor/RichTextEditor';
import emptyNotes from '../../components/Images/emptyNotes.svg';

import useCRUD from '../../_Hooks/useCRUD';
import useUrlParams from '../../_Hooks/useUrlParams';
import { useContextHook } from '../../contexts/Context';
import { colors } from '../../styles/style';

import NoContent from '../../components/NoContent/NoContent';
import Button from '../../components/Button/Button';
import useViewport from '../../_Hooks/useViewport';
import InfoSidebar from '../../components/Sidebar/InfoSidebar';
import { Context as InfoSidebarContext } from '../../contexts/GeneralContext';
import Modal from '../../components/Modal/Modal';
import CenteredLoader from '../../components/Loader/CenteredLoader';
import { ApplyNotes, NotesContainer, ListNotes, EditSelectedNote, ApplyNoteText } from './Notes.styles';

const Notes = ({ idTemplate, isApply, forceLoad, isProject }) => {
  const { isMobile } = useViewport(window.innerWidth);
  const { data, handleUpdate: updateRefurbish, setData: setRefurbishData } = useContextHook() || {};

  const { id: _idRefurbish } = data || {};
  const itemsToShare = data.itemsToShare || { note: true };
  const idRefurbish = !idTemplate ? _idRefurbish : null;

  const { user } = useSelector(state => state.authReducer);
  const { userType, refurbishStatus } = useSelector(state => state.setup.enums);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showSidebarModal, setShowSidebarModal] = useState(false);
  const [selectedNote, setSelectedNote] = useState({});
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [sideBarTab, setSideBarTab] = useState(null);
  const isOpportunity = refurbishStatus?.opportunity.includes(data?.idStatus);

  const shouldSave = useRef(false);

  const isCustomer = user.userType === userType.customer.value;

  let notesContainerHeight;
  if (isMobile()) {
    notesContainerHeight = isApply ? 'calc(100vh - 360px)' : 'calc(100vh - 168px)';
  } else {
    notesContainerHeight = isApply ? 'calc(100vh - 370px)' : 'calc(100vh - 114px)';
  }

  const { loading, list: notes, handleCreate, handleUpdate, handleDelete, handleGet } = useCRUD({
    model: 'refurbish-notes',
    options: {
      order: [['updatedAt', 'desc']],
      where: {
        ...(isCustomer ? { shared: { ne: null } } : {}),
        ...(idTemplate ? { idTemplate } : { idRefurbish })
      }
    },
    immediatelyLoadData: false
  });

  const { loading: loadingNotification, foundParams } = useUrlParams({
    preventTrigger: Object.keys(selectedNote)?.length,
    urlParams: ['tab', 'nota']
  });

  const richTextValue =
    typeof selectedNote?.note !== 'string' ? notes && notes[0] && notes[0].note : selectedNote?.note;
  const images = selectedNote?.images || [];

  const toggleDeleteModal = show => setShowDeleteModal(show);

  const handleShare = () => {
    if (!itemsToShare.note) setShowConfirmModal(true);
  };

  const handleConfirmShare = () => {
    const values = { isShared: data.isShared, itemsToShare: { ...itemsToShare, note: true } };
    updateRefurbish({ id: idRefurbish, values, updatePathOptions: '/share', refresh: false }).then(resp => {
      if (!resp?.error) {
        setRefurbishData({ ...data, itemsToShare: resp.itemsToShare });
      }
    });
  };

  const handleSubmit = _values => {
    if (isCustomer || !user) return null;

    const values = {
      ..._values,
      idRefurbish,
      idTemplate,
      shared: _values?.values?.shared,
      event: _values?.event || `${isProject ? 'project' : 'opportunity'}-note-${_values.id ? 'edited' : 'created'}`
    };
    if (isApply) return Promise.resolve();

    if (!values.id) {
      return handleCreate({ values }).then(resp => {
        shouldSave.current = false;
        if (!isMobile() && !resp?.error) {
          setSelectedNote(prevState => ({ ...prevState, id: resp.id }));
        }
      });
    }
    return handleUpdate({ id: values.id, values }).then(res => {
      if (_values.switched) {
        shouldSave.current = false;
        setSelectedNote(res);
      }
    });
  };

  const handleEditorChange = (content, imagesArray) => {
    if (!shouldSave.current) {
      return;
    }
    setSelectedNote(prevState => {
      return {
        ...prevState,
        note: content,
        images: imagesArray?.filter(image => image && content.includes(image))
      };
    });
  };

  const handleSelect = currentSelected => {
    shouldSave.current = false;
    setSelectedNote({
      ...currentSelected,
      images: notes?.find(p => p.id === currentSelected?.id)?.images
    });
  };

  const handleAddButton = () => {
    const newNotes = {
      note: 'Nova anotação',
      images: []
    };
    shouldSave.current = true;
    setSelectedNote(newNotes);
  };

  const handleModal = result => {
    if (result) {
      handleDelete({
        id: selectedNote.id,
        values: { event: `${isProject ? 'project' : 'opportunity'}-note-deleted` }
      }).then(resp => {
        shouldSave.current = false;
        setSelectedNote(isMobile() ? {} : resp?.[0] || {});
        toggleDeleteModal(false);
        setShowSidebarModal(false);
      });
    }
  };

  useEffect(() => {
    handleGet().then(res => !isMobile() && setSelectedNote(res?.[0] || {}));
  }, [forceLoad]);

  useEffect(() => {
    if (loadingNotification) return undefined;
    let timer;
    if (selectedNote && Object.keys(selectedNote).length && shouldSave.current) {
      timer = setTimeout(() => {
        handleSubmit({ ...selectedNote });
      }, 500);
    }
    if (selectedNote && Object.keys(selectedNote).length && !shouldSave.current) shouldSave.current = true;
    return () => clearTimeout(timer);
  }, [selectedNote]);

  useEffect(() => {
    if (!foundParams?.nota || loading) {
      return;
    }

    const found = notes?.find(note => note.id === Number(foundParams?.nota));
    if (!found) {
      toast.error('Nota não encontrada.');
      return;
    }
    if (foundParams?.tab) setSideBarTab(foundParams?.tab);
    window.history.replaceState(null, '', window.location.pathname);
    setSelectedNote(found);
  }, [foundParams, loading]);

  const ButtonsNoContent = () => {
    return (
      <div style={{ display: 'flex', justifyContent: isMobile() ? ' center' : 'flex-start' }}>
        <Button id="new-note" type="primary" style={{ marginRight: '10px' }} onClick={() => handleAddButton()}>
          Criar anotação
        </Button>
        <Button id="view-example" ghost type="primary">
          Ver exemplo
        </Button>
      </div>
    );
  };

  const EmptyState = () => (
    <div style={{ flex: 3 }}>
      <NoContent
        title="Conheça o bloco de anotações"
        description={`Registre o andamento do seu projeto, crie atas de reunião, escreva notas sobre os pontos
      mais importantes, insira links e imagens e ainda compartilhe informações com seu cliente.`}
        buttonTitle="Criar anotação"
        image={<img src={emptyNotes} alt="emptyNotes" />}
        showButtonIcon={false}
        renderButton={false}
        extraButton={ButtonsNoContent}
        style={{ margin: 'auto', maxWidth: '50%' }}
      />
    </div>
  );

  const handleCancelShare = () => {
    setShowConfirmModal(false);
  };

  const duplicateNote = currentNote => {
    handleCreate({
      values: {
        ...currentNote,
        ...(idTemplate ? { idTemplate } : { idRefurbish })
      },
      idRefurbish,
      postPathOptions: '/duplicate',
      displayToast: true
    }).then(res => {
      setSelectedNote(res);
      setShowSidebarModal(false);
    });
  };

  const handleChangeShared = (id, newShared) => {
    const shared = newShared ? null : new Date();
    const verb = 'patch';
    handleUpdate(
      {
        id,
        updatePathOptions: '/shared',
        values: {
          idRefurbish,
          shared
        },
        refresh: false,
        verb
      },
      true
    ).then(res => {
      handleGet();
      shouldSave.current = false;
      setSelectedNote(res);
      handleShare();
    });
  };

  return loadingNotification ? (
    <CenteredLoader />
  ) : (
    <InfoSidebarContext data={{ setShowSidebarModal }}>
      <div style={{ display: 'flex', justifyContent: selectedNote?.id && isMobile() ? 'space-between' : 'end' }}>
        {selectedNote?.id && (
          <FontAwesomeIcon
            className="showMobile"
            color={colors.primary600}
            icon={faArrowLeft}
            onClick={() => {
              setSelectedNote({});
            }}
            style={{ margin: '5px 0 0 5px' }}
          />
        )}
      </div>
      <NotesContainer $notesContainerHeight={notesContainerHeight}>
        {!(selectedNote?.id && isMobile()) && (
          <NotesList
            list={notes}
            onClick={handleSelect}
            selected={selectedNote}
            onAdd={handleAddButton}
            onDelete={() => toggleDeleteModal(true)}
            isApply={isApply}
            handleCreateNote={duplicateNote}
            handleChangeShared={handleChangeShared}
            isOpportunity={isOpportunity}
          />
        )}

        {notes?.length ? (
          <>
            {isApply ? (
              <ApplyNotes>
                <ApplyNoteText>{parse(richTextValue || '')}</ApplyNoteText>
              </ApplyNotes>
            ) : (
              <>
                {selectedNote?.id ? (
                  <EditSelectedNote>
                    <RichTextEditor
                      key={`note-${selectedNote.id}`}
                      onChange={handleEditorChange}
                      value={richTextValue}
                      images={images}
                      statusbar={false}
                      disabled={isCustomer}
                      height={isMobile() ? 'calc(100vh - 200px)' : '100%'}
                      toolbar={isCustomer ? false : undefined}
                    />
                  </EditSelectedNote>
                ) : (
                  <ListNotes>{!isMobile() && <CenteredLoader />}</ListNotes>
                )}
                {!isMobile() && (
                  <InfoSidebar
                    data={selectedNote}
                    isShared={false}
                    isCustomer={isCustomer}
                    hasDescription={false}
                    isProject
                    idRefurbish={idRefurbish}
                    handleChangeShared={handleChangeShared}
                    handleRemove={() => setShowDeleteModal(true)}
                    model="refurbish-notes"
                    isModuleShared={itemsToShare.note}
                    selectedTab={sideBarTab}
                    handleCreateNote={duplicateNote}
                    isOpportunity={isOpportunity}
                  />
                )}
              </>
            )}
          </>
        ) : (
          !loading && !isCustomer && !isApply && <EmptyState />
        )}
      </NotesContainer>
      {showDeleteModal ? (
        <ModalConfirm
          text="Deseja realmente apagar a anotação?"
          onSubmit={handleModal}
          onClose={() => toggleDeleteModal(false)}
        />
      ) : null}
      {showSidebarModal && isMobile() && (
        <Modal
          open
          noPadding
          hideFooter
          onClose={() => setShowSidebarModal(false)}
          style={{ display: 'flex' }}
          height="100%"
          width="100%"
        >
          <InfoSidebar
            data={selectedNote}
            isShared={false}
            isCustomer={isCustomer}
            hasDescription={false}
            isProject={isProject}
            idRefurbish={idRefurbish}
            handleRemove={() => setShowDeleteModal(true)}
            model="refurbish-notes"
            isModuleShared={itemsToShare.note}
            selectedTab={sideBarTab}
            handleCreateNote={duplicateNote}
            handleChangeShared={handleChangeShared}
          />
        </Modal>
      )}
      {showConfirmModal && (
        <ModalConfirm
          confirmButtonText="Ativar"
          cancelButtonText="Não ativar"
          title="Compartilhar módulo de anotações"
          onSubmit={handleConfirmShare}
          onClose={handleCancelShare}
          text="O compartilhamento com o cliente do módulo de anotações não está ativado, deseja ativar?"
        />
      )}
    </InfoSidebarContext>
  );
};

Notes.propTypes = {
  idTemplate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isApply: PropTypes.bool,
  forceLoad: PropTypes.number,
  isProject: PropTypes.bool
};

export default Notes;
