import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown as AntDropDown, Input, Menu, Button as AntButton, Tooltip } from 'antd';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import * as icons from '@fortawesome/pro-regular-svg-icons';
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import Button from '../Button/Button';
import { removeAccents } from '../../lib/helpers/helper';

import { spaces, colors, fonts, breakpoints, radius } from '../../styles/style';
import useCRUD from '../../_Hooks/useCRUD';
import Modal from '../Modal/Modal';
import useViewport from '../../_Hooks/useViewport';
import CenteredLoader from '../Loader/CenteredLoader';
import AddSelectButton from '../Button/AddSelectButton';

const StyledDropDown = styled(AntDropDown)`
  cursor: pointer;
  padding: ${props => (props.$dropdownPadding ? props.$dropdownPadding : `${spaces.space1} ${spaces.space2}`)};

  &.form-component {
    padding: ${spaces.space0} ${spaces.space1};
    width: 100%;
  }
`;

const RenderEditOptions = styled.div`
  position: absolute;
  bottom: -38px;
  background-color: #ffffff;
  width: 100%;
  box-shadow: 1px 2px 5px rgb(0 0 0 / 8%);
  border-radius: 0 0 ${radius.radius1} ${radius.radius1};
  height: ${spaces.space5};
`;

const StyledMenu = styled.div`
  padding: 0;
  max-height: 300px;
  overflow: hidden auto;
  box-shadow: 0 0 20px 0 rgb(0 0 0 / 8%);
  border-radius: ${radius.radius1};
  position: relative;

  &.form-component {
    .ant-menu-vertical {
      border-right: 0;

      .ant-menu-item {
        padding: 0;
      }
    }
  }

  .ant-dropdown-menu-item {
    &:first-of-type {
      border-radius: ${radius.radius1} ${radius.radius1} 0 0;
    }

    &:last-of-type {
      border-radius: 0 0 ${radius.radius1} ${radius.radius1};
    }

    &:hover {
      background-color: ${colors.neutral100};

      .ant-btn {
        font-weight: 400;
        color: ${colors.neutral600};
      }
    }

    .ant-btn {
      font-weight: 300;
    }
  }

  .ant-dropdown-menu-title-content {
    width: 100%;
  }

  @media (max-width: ${breakpoints.tablet}) {
    box-shadow: none;
    border: none;
  }
`;

const StyledButton = styled(AntButton)`
  &.form-component {
    height: ${spaces.space4};
    border-radius: ${spaces.space0};
    border-color: ${colors.neutral100};
    padding: ${spaces.space0} ${spaces.space1};
    font-size: ${fonts.sizeSm};

    span {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &:hover {
      border-color: ${colors.neutral100};
    }
  }
  width: ${props => props.width};
  align-items: center;
  justify-content: space-between;
  background-position: center;
  cursor: pointer;
  font-family: ${fonts.family};
  font-size: ${fonts.sizeSm};
  height: auto;
  border-radius: ${spaces.space1};
  white-space: ${props => (props.$hideOverflow ? 'nowrap' : 'normal')};
  text-overflow: ellipsis;
  overflow: hidden;

  @media (max-width: ${breakpoints.tablet}) {
    font-size: ${fonts.sizeLg};
  }

  ${props =>
    props.$isBulk
      ? css`
          color: ${colors.white};
          padding: 0;
          margin-left: ${spaces.space1} !important;
          width: auto;

          &:hover {
            color: inherit;
          }
        `
      : css`
          color: ${colors.neutral600};
          border: 1px solid transparent;
          padding: ${spaces.space0};
          text-align: left;
          min-width: ${spaces.space5};

          &:after {
            animation: none !important;
          }

          &:hover {
            color: inherit;
            border: 1px solid ${colors.neutral500};
          }
        `}
  &:focus {
    color: inherit;
  }
`;

const StyledItemButton = styled(AntButton)`
  width: 100%;
  display: flex;
  border: none;
  background-color: transparent;
  font-family: ${fonts.family};
  font-size: ${fonts.sizeSm};
  box-shadow: none;

  @media (max-width: ${breakpoints.tablet}) {
    font-size: ${fonts.sizeLg};
  }

  &:hover {
    background-color: transparent;
  }
`;

const StyledSpan = styled.span`
  margin-left: ${spaces.space1};
`;

const DropdownFontAwesomeIcon = styled(FontAwesomeIcon)`
  &.form-component {
    font-size: ${fonts.sizeMd};
  }
  ${props =>
    props.$isBulk
      ? css`
          height: ${fonts.sizeLg};
          width: ${fonts.sizeLg};
          padding: ${spaces.space1};
        `
      : css`
          margin-left: ${spaces.space1};
          color: ${colors.neutral400};
        `}
`;

const SelectDropdown = ({
  data,
  loadList,
  model,
  width = '100%',
  options = {},
  pathOptions,
  onSelect = () => {},
  canCreate = true,
  className,
  createData = {},
  children,
  IconSelector,
  preventLoad,
  refetch = () => {},
  showClean = true,
  cleanValue = 1,
  hideOverflow = false,
  isOpen,
  onClose = f => f,
  placeholder = 'Opções',
  initialFocus = true,
  setShowOpenDrawer = f => f,
  editOptions,
  hasPermission,
  icon,
  tooltip,
  isBulk,
  onClick,
  hideTextSpan = false,
  buttonPadding,
  buttonAlign = 'center',
  ...props
}) => {
  const { list, handleCreate, loading, totalItems, handleGet } = useCRUD({
    model,
    options,
    pathOptions,
    immediatelyLoadData: false
  });

  const offset = useRef(1);
  const [isVisibleClean, setVisibleClean] = useState(false);
  const [isVisible, setVisible] = useState(isOpen);
  const [search, setSearch] = useState('');
  const [create, setCreate] = useState(false);
  const [showRenderModal, setShowRenderModal] = useState(isOpen);
  const [fullList, setFullList] = useState([]);
  const { isMobile } = useViewport(window.innerWidth);

  const loadMore = () => {
    const { where } = options;
    const newOptions = {
      ...options,
      where: {
        ...where,
        ...(search && { ulike: { [`${model}.name`]: `%${removeAccents(search)}%` } })
      },
      offset: offset.current,
      limit: 10
    };
    handleGet({ refetchOptions: { ...newOptions } });
  };

  const handleScroll = e => {
    const bottom = e.target.scrollHeight - Math.ceil(e.target.scrollTop) <= e.target.clientHeight;
    if (bottom && totalItems > fullList.length) {
      offset.current += 1;
      loadMore();
    }
  };

  useEffect(() => {
    setFullList(prev => (offset.current > 1 ? [...prev, ...list] : list));
  }, [list]);

  useEffect(() => {
    if (loadList) {
      const _list = search
        ? loadList?.filter(l => {
            const name = l?.name || l?.label;
            if (name === search) {
              setCreate(false);
            }
            return removeAccents(name)?.includes(removeAccents(search));
          })
        : loadList;

      setFullList(_list);

      return () => {};
    }

    const timer = setTimeout(() => {
      offset.current = 1;
      if (!preventLoad) loadMore();
    }, 400);

    return () => clearTimeout(timer);
  }, [search]);

  useEffect(() => {
    if (isVisible && preventLoad) {
      loadMore();
    }
  }, [isVisible]);

  const memoList = useMemo(() => {
    setCreate(true);

    return fullList?.length ? fullList : loadList;
  }, [fullList, loadList]);

  const handleSelect = (e, item) => {
    e.stopPropagation();
    onSelect(item);
    setSearch('');
    refetch && refetch();
    setVisible(false);
    setShowRenderModal(false);
  };

  const handleSelectClean = e => {
    e.stopPropagation();
    onSelect({ id: cleanValue, value: cleanValue });
    setSearch('');
    refetch && refetch();
    setVisible(false);
  };

  const handleCreateOption = e => {
    e.stopPropagation();
    return handleCreate({ values: { ...createData, name: search }, refresh: false }).then(newItem =>
      handleSelect(e, { ...newItem, isNewRecord: true })
    );
  };

  const callbackRef = useCallback(inputElement => {
    if (inputElement && initialFocus) {
      setTimeout(() => inputElement.focus(), 100);
    }
  }, []);

  const onClickBulk = e => {
    if (!isBulk) return;
    onClick(e.key);
    setVisible(false);
  };

  const _menu =
    isVisible || isMobile() ? (
      <>
        <StyledMenu
          onScroll={handleScroll}
          role="presentation"
          onClick={e => e.stopPropagation()}
          className={className}
        >
          <Menu>
            {model && (
              <Menu.Item>
                <div>
                  <Input.Search
                    ref={callbackRef}
                    value={search}
                    allowClear
                    type="text"
                    onChange={e => setSearch(e.target.value)}
                    id={`search-${tooltip || 'status'}`}
                  />
                </div>
              </Menu.Item>
            )}
            {create && search && canCreate ? (
              <Menu.Item>
                <Button type="link" onClick={handleCreateOption}>
                  {`+ criar "${search}"`}
                </Button>
              </Menu.Item>
            ) : null}
            {memoList?.map(item => {
              return (
                <Menu.Item key={item.id || item.value} onClick={onClickBulk}>
                  <StyledItemButton onMouseDown={e => !isBulk && handleSelect(e, item)}>
                    {IconSelector && <IconSelector item={item} />}
                    {!hideTextSpan && <StyledSpan>{item.name || item.label}</StyledSpan>}
                  </StyledItemButton>
                </Menu.Item>
              );
            })}
            {loading && (
              <li>
                <CenteredLoader />
              </li>
            )}
          </Menu>
        </StyledMenu>
        {editOptions && hasPermission ? (
          <RenderEditOptions>
            <AddSelectButton
              onClick={() => {
                setShowOpenDrawer(true);
                onClose();
              }}
              noMargin
            />
          </RenderEditOptions>
        ) : null}
      </>
    ) : (
      <StyledMenu />
    );

  return isMobile() ? (
    <>
      <Button
        shape="text"
        align={buttonAlign}
        ghost
        onClick={e => {
          e.stopPropagation();
          setShowRenderModal(true);
        }}
        padding={buttonPadding}
      >
        {children ? (
          <div role="presentation">{children}</div>
        ) : (
          <>
            {data?.name || data?.label || placeholder || '-'}
            {isVisibleClean && data?.id !== 1 && showClean && (
              <DropdownFontAwesomeIcon icon={faTimes} onClick={e => handleSelectClean(e)} />
            )}
          </>
        )}
      </Button>
      {showRenderModal && (
        <Modal
          title={placeholder}
          open
          onClose={e => {
            e.stopPropagation();
            setShowRenderModal(false);
            onClose();
          }}
        >
          {_menu}
        </Modal>
      )}
    </>
  ) : (
    <>
      <StyledDropDown
        trigger="click"
        overlay={_menu}
        overlayClassName="c-dropdown"
        slim
        placeholder={placeholder}
        className={className}
        {...props}
        visible={isVisible}
        onVisibleChange={() => {
          setVisible(!isVisible);
          isVisible && onClose();
        }}
      >
        {children ? (
          <span role="presentation" onClick={e => e.stopPropagation()}>
            {children}
          </span>
        ) : (
          <StyledButton
            width={width}
            $hideOverflow={hideOverflow}
            onMouseEnter={() => setVisibleClean(true)}
            onMouseLeave={() => setVisibleClean(false)}
            onClick={e => e.stopPropagation()}
            type="link"
            $isBulk={isBulk}
            className={className}
          >
            <>
              {data?.name || data?.label || (!icon && '-')}
              {icon && (
                <Tooltip title={tooltip} placement="top">
                  <DropdownFontAwesomeIcon
                    icon={icons[icon]}
                    data-tip={tooltip}
                    id={`${tooltip}-dropdown`}
                    $isBulk={isBulk}
                  />
                </Tooltip>
              )}
              {isVisibleClean && data?.id !== 1 && showClean && (
                <DropdownFontAwesomeIcon
                  className={className}
                  icon={className === 'form-component' ? faCircleXmark : faTimes}
                  onClick={e => handleSelectClean(e)}
                />
              )}
            </>
          </StyledButton>
        )}
      </StyledDropDown>
    </>
  );
};

SelectDropdown.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  loadList: PropTypes.instanceOf(Array),
  model: PropTypes.string,
  width: PropTypes.string,
  options: PropTypes.instanceOf(Object),
  pathOptions: PropTypes.string,
  onSelect: PropTypes.func,
  canCreate: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  IconSelector: PropTypes.instanceOf(Object),
  createData: PropTypes.instanceOf(Object),
  preventLoad: PropTypes.bool,
  refetch: PropTypes.func,
  showClean: PropTypes.bool,
  cleanValue: PropTypes.number,
  hideOverflow: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  placeholder: PropTypes.string,
  initialFocus: PropTypes.bool,
  setShowOpenDrawer: PropTypes.func,
  editOptions: PropTypes.bool,
  hasPermission: PropTypes.bool,
  icon: PropTypes.string,
  tooltip: PropTypes.string,
  isBulk: PropTypes.bool,
  onClick: PropTypes.func,
  hideTextSpan: PropTypes.bool,
  buttonPadding: PropTypes.string,
  buttonAlign: PropTypes.string
};

export default SelectDropdown;
