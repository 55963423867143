import React, { memo, useState, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faPenToSquare } from '@fortawesome/pro-solid-svg-icons';

import { Tooltip } from 'antd';
import ItemAttachmentContent from '../Content/ItemAttachmentContent';
import Tabs, { TabPane } from '../Tabs/Tabs';
import Description from '../Content/Description';
import { mappingDescriptionConf } from '../../lib/mapping/Description/mappingDescriptionConf';

import { Container, TabContent, FormContainer } from './EditItemModal.styles';
import { colors, spaces } from '../../styles/style';
import CommentList from '../List/CommentList';
import ShareLinkDropdown from '../Dropdown/ShareLinkDropdown';
import {
  itemSchema,
  newBudgetItemMapping as budgetItemMapping,
  linkedItemMapping as itemMapping
} from '../../lib/mapping/Form/itemSchema';
import formatCurrency from '../../lib/helpers/formatCurrency';
import formatBdi from '../../lib/helpers/formatBdi';
import Form from '../Form/Form';
import formatNumber from '../../lib/helpers/formatNumber';
import useCRUD from '../../_Hooks/useCRUD';
import CenteredLoader from '../Loader/CenteredLoader';
import EditOrCreateCustomerOrSupplier from '../Drawer/EditOrCreateCustomerOrSupplier';
import Button from '../Button/Button';
import ConfirmModal from './ConfirmModal';
import CatalogDrawer from '../Drawer/CatalogDrawer';
import ConfirmModalAlertInfoBuilder from './ConfirmModalAlertInfoBuilder';
import formatNumberWithDynamicDecimals from '../../lib/helpers/formatNumberWithDynamicDecimals';
import { getCatalogDrawerDisplayToast, getLinkedItemText } from '../../lib/helpers/helper';
import useViewport from '../../_Hooks/useViewport';
import Drawer from '../Drawer/Drawer';

const EditItemModal = ({
  id,
  idReference,
  onClose = f => f,
  afterSubmit = f => f,
  model = 'refurbish-items',
  readOnly,
  tab = '0',
  isTemplate,
  groupedReference,
  type,
  renderColumns,
  isLibrary = false,
  onlyLabor,
  libraryProps,
  isChildrenDrawer,
  initialData,
  isProductConciliation,
  hideAttachments = false,
  hideComments = false
}) => {
  const { user } = useSelector(state => state.authReducer);
  const { userType, refurbishItemType, refurbishItemStatus } = useSelector(state => state.setup.enums);
  const isCustomer = user?.userType === userType.customer.value;
  const isUserAnonymous = user?.anonymous;
  const hideSupplier = !renderColumns?.idSupplier && isCustomer;

  const [files, setFiles] = useState([]);
  const [observation, setObservation] = useState('');
  const [selectedTab, setSelectedTab] = useState(tab || '0');
  const [linkedData, setLinkedData] = useState();
  const [editableData, setEditableData] = useState();
  const [showEditLibraryItemModal, setShowEditLibraryItemModal] = useState(false);
  const [confirmModalProps, setConfirmModalProps] = useState();
  const [linkedItem, setLinkedItem] = useState();
  const [showAllLaborFields, setShowAllLaborFields] = useState(false);
  const [editSupplierId, setEditSupplierId] = useState();
  const [showCatalogDrawer, setShowCatalogDrawer] = useState(false);
  const [isEditableFormValid, setIsEditableFormValid] = useState(true);
  const forceFormUpdate = useRef(false);
  const { isMobile } = useViewport(window.innerWidth);

  const detailTitle = {
    [refurbishItemType.labor]: 'Serviço',
    [refurbishItemType.product]: 'Produto',
    [refurbishItemType.parent]: 'Agrupamento'
  };
  const createTitle = {
    [refurbishItemType.labor]: 'Novo serviço',
    [refurbishItemType.product]: 'Novo produto'
  };

  const { data, loading, handleGet, handleUpdate, handleCreate } = useCRUD({
    model,
    pathOptions: id ? `/${id}` : '',
    options: libraryProps?.options || {
      include: [
        'supplier',
        'parent',
        { attributes: ['id', 'name'], model: 'Unit', as: 'units' },
        ...(isTemplate || !idReference ? [] : ['files']),
        {
          model: 'costCenter',
          as: 'costCenter',
          attributes: ['id', 'name']
        },
        {
          model: 'refurbishGroup',
          as: 'refurbishGroup',
          attributes: ['id', 'name']
        },
        ...(isLibrary
          ? []
          : [
              {
                model: isTemplate ? 'template' : 'refurbish',
                attributes: ['version', 'laborBdi', 'productBdi']
              },
              {
                model: 'Item',
                as: 'item',
                include: [{ attributes: ['id', 'name'], model: 'Unit', as: 'units' }]
              }
            ])
      ],
      where: { idRefurbish: idReference }
    },
    immediatelyLoadData: !!id
  });

  const hasSupplier = data?.supplier?.id && data?.supplier?.id !== 1;

  useEffect(() => {
    setLinkedData(data);
    forceFormUpdate.current = true;

    if (data?.id) {
      setFiles(data.files || []);
      setObservation(data.observation || '');
      setLinkedItem(data?.externalBaseCode && !data?.idItem ? data : data?.item);
      setEditableData(data);
    }
  }, [data]);

  const handleSaveLink = itemToSave => {
    handleUpdate({
      values: {
        idItem: itemToSave?.id || null,
        externalBaseCode: itemToSave?.externalBaseCode || null,
        ...(itemToSave && { type: itemToSave?.type }),
        event: 'item-nature-changed',
        action: itemToSave ? 'attached' : 'detached'
      },
      refresh: false,
      displayToast: getCatalogDrawerDisplayToast(itemToSave)
    }).then(resp => {
      if (!resp?.error) {
        handleGet();
      }
    });
  };

  const handleSubmit = () => {
    if (!isEditableFormValid) return;
    const { bdi, ...submitData } = editableData || {};
    const formattedBdi = formatBdi(bdi);

    const values = {
      ...submitData,
      ...(formatCurrency(data?.bdi || 0) !== formatCurrency(formattedBdi) && { bdi: formattedBdi }),
      idRefurbish: idReference,
      image: null,
      price: formatNumber(submitData.price),
      observation,
      files,
      type: data?.type || type,
      autoCode: !id && libraryProps?.checkedValue,
      quantity: formatNumber(submitData?.quantity || 0),
      ...(data?.status !== submitData.status &&
        submitData.status === refurbishItemStatus.approved.value && { isApproved: true }),
      ...(!linkedItem && {
        height: formatNumber(linkedData?.height),
        width: formatNumber(linkedData?.width),
        length: formatNumber(linkedData?.length),
        weight: formatNumber(linkedData?.weight),
        idUnit: linkedData?.idUnit || 1,
        name: linkedData?.name,
        code: linkedData?.code,
        images: linkedData?.images
      })
    };
    if (!id) {
      handleCreate({
        values,
        refresh: false,
        postOptions: {
          include: [
            'supplier',
            ...(isTemplate ? [] : ['files']),
            {
              model: 'costCenter',
              as: 'costCenter',
              attributes: ['id', 'name']
            },
            {
              model: 'refurbishGroup',
              as: 'refurbishGroup',
              attributes: ['id', 'name']
            }
          ]
        },
        displayToast: 'Item adicionado com sucesso.'
      }).then(resp => {
        if (!resp?.error) {
          onClose(resp);
          afterSubmit(resp);
        }
      });
    } else {
      handleUpdate({
        values,
        refresh: false,
        displayToast: 'Item atualizado com sucesso.'
      }).then(resp => {
        if (!resp?.error) {
          onClose(resp?.rows?.[0] || resp);
          afterSubmit(resp?.rows?.[0] || resp);
        }
      });
    }
  };

  const _type = linkedItem ? linkedItem?.type : data?.type || type;

  const handleConfirmModal = confirmModalType => {
    const confirmModalPropsMap = {
      unlinkItem: {
        title: 'Desvincular item',
        alertInfo: 'Ao desvincular esse item, ele perderá a relação com a Biblioteca',
        preInfoDescription: 'Deseja concluir ação?',
        onSubmit: () => handleSaveLink(null)
      },
      editLibraryItem: {
        title: 'Todas as ocorrências serão editadas',
        alertInfo: (
          <ConfirmModalAlertInfoBuilder
            alertText={`Ao editar esse item, os
            seguintes campos de todas as aparições desse item serão alteradas:`}
            afterAlertList={[
              'Nome do item',
              'Código',
              ...(_type === refurbishItemType.product ? ['Unidade', 'Imagem', 'Dimensões', 'Peso'] : [])
            ]}
          />
        ),
        preInfoDescription: 'Deseja confirmar?',
        onSubmit: () => handleSubmit()
      }
    };
    if (id) {
      setConfirmModalProps(confirmModalPropsMap[confirmModalType]);
    } else {
      confirmModalPropsMap[confirmModalType].onSubmit();
    }
  };

  const handleLinkClick = () => {
    if (linkedItem) {
      handleConfirmModal('unlinkItem');
      return;
    }
    setShowCatalogDrawer(true);
  };

  const _itemMapping = () => {
    const _linkedData = linkedData || data?.item || data || initialData || {};

    return itemMapping({
      id,
      type: _type,
      isCustomer: isCustomer || readOnly,
      refurbishItemTypeEnum: refurbishItemType,
      columnsToShow: renderColumns,
      linkedItem,
      isLibrary,
      onSeeMoreClick: () => setShowAllLaborFields(true),
      showAllLaborFields,
      libraryProps: libraryProps || {},
      headerProps: {
        onEditItem: linkedItem && !isCustomer && !readOnly ? () => setShowEditLibraryItemModal(true) : null,
        showLinkInfo: !isLibrary
      },
      isMobile: isMobile(),
      data: _linkedData
    });
  };

  const getBudgetItemMappingWithBDIFormatted = () => {
    let bdiLabel = null;

    const laborBDI = data?.[isTemplate ? 'template' : 'refurbish']?.laborBdi;
    const productBDI = data?.[isTemplate ? 'template' : 'refurbish']?.productBdi;

    if (_type === refurbishItemType.labor && laborBDI) {
      bdiLabel = `BDI (Padrão do orçamento ${laborBDI}%)`;
    }

    if (_type === refurbishItemType.product && productBDI) {
      bdiLabel = `BDI (Padrão do orçamento ${productBDI}%)`;
    }

    return budgetItemMapping({
      id,
      type: _type,
      isCustomer: isCustomer || readOnly,
      refurbishItemTypeEnum: refurbishItemType,
      isTemplate,
      idReference,
      columnsToShow: renderColumns,
      parentType: data?.parent?.type || data?.parent?.[0]?.type,
      linkedItem,
      bdiLabel,
      isLibrary
    });
  };

  const DetailsContent = useMemo(() => {
    const _data = editableData || data || initialData || {};
    const _linkedData = linkedData || data?.item || data || initialData || {};
    return (
      <>
        <FormContainer $itemInfo>
          <Form
            mapping={_itemMapping()}
            schema={itemSchema({})}
            displayButtons={false}
            isFormValid={setIsEditableFormValid}
            data={{
              ..._linkedData,
              height: formatCurrency(_linkedData?.height),
              width: formatCurrency(_linkedData?.width),
              length: formatCurrency(_linkedData?.length),
              weight: formatCurrency(_linkedData?.weight),
              socialCharges: _linkedData?.priceWithSocialCharges ? 'Não desonerado' : 'Desonerado'
            }}
            groupedReference={groupedReference}
            onFormChange={!linkedItem ? setLinkedData : undefined}
            forceUpdate={forceFormUpdate}
            validateOnRender
          />
        </FormContainer>
        {!onlyLabor && (
          <FormContainer>
            <Form
              mapping={getBudgetItemMappingWithBDIFormatted()}
              displayButtons={false}
              onFormChange={setEditableData}
              data={
                {
                  ..._data,
                  bdi: formatCurrency(_data?.bdi || 0),
                  price: formatCurrency(_data?.price || 0),
                  quantity: formatNumberWithDynamicDecimals(data?.quantity)
                } || {}
              }
              groupedReference={groupedReference}
            />
          </FormContainer>
        )}
      </>
    );
  }, [linkedItem, groupedReference, data, showAllLaborFields, libraryProps?.checkedValue, linkedData]);

  const drawerFooter = (
    <>
      <Button onClick={onClose} loading={loading}>
        Cancelar
      </Button>
      <div style={{ display: 'flex', gap: spaces.space1 }}>
        {!isLibrary && id && !isProductConciliation && (
          <Tooltip title={getLinkedItemText(data?.item)}>
            <Button
              id={`link-item${isChildrenDrawer && id ? `-${id}` : ''}`}
              type="primary"
              ghost
              loading={loading}
              onClick={handleLinkClick}
            >
              {`${linkedItem ? 'Desv' : 'V'}incular e Salvar`}
            </Button>
          </Tooltip>
        )}
        <Button
          id={`submit-drawer${isChildrenDrawer && id ? `-${id}` : ''}`}
          type="primary"
          loading={loading}
          onClick={() => (isLibrary ? handleConfirmModal('editLibraryItem') : handleSubmit())}
        >
          Salvar
        </Button>
      </div>
    </>
  );

  return (
    <Drawer
      open
      title={id ? detailTitle[_type] : createTitle[_type]}
      onClose={() => onClose(false)}
      width={880}
      padding="0"
      hideFooter={isCustomer || readOnly}
      footer={readOnly ? [] : drawerFooter}
      headerExtraContent={() =>
        !isLibrary && <ShareLinkDropdown id={id} whatsapp urlContext="o" style={{ marginLeft: spaces.space1 }} />
      }
      HowDoesItWorks={`how${isLibrary ? 'Library' : 'Budget'}ItemWorks`}
    >
      {loading ? (
        <CenteredLoader />
      ) : (
        <>
          <ConfirmModal
            open={confirmModalProps}
            onClose={() => setConfirmModalProps(null)}
            title={confirmModalProps?.title}
            alertInfo={confirmModalProps?.alertInfo}
            description={confirmModalProps?.description}
            alertInfoPadding={`${spaces.space1} ${spaces.space1}`}
            onSubmit={confirmModalProps?.onSubmit}
          />
          {showCatalogDrawer && (
            <CatalogDrawer
              open
              shouldConfirm
              tabsToOpen={[0, 1]}
              cardProps={{ addTooltipText: 'Vincular', addIcon: faLink, iconStyle: { margin: '-3.5px' } }}
              disableTypeFilter
              newItemTypeRedirect={_type}
              fixedType={refurbishItemType.productLabor}
              onClose={() => setShowCatalogDrawer(false)}
              subtitle="Busque o item da biblioteca para vincular com o item selecionado"
              onSubmit={val => {
                setShowCatalogDrawer(false);
                handleSaveLink(val);
              }}
            />
          )}
          {showEditLibraryItemModal && (
            <EditItemModal
              id={linkedItem?.id}
              type={linkedItem.type}
              isLibrary
              onlyLabor
              model="item"
              isChildrenDrawer
              onClose={() => {
                setShowEditLibraryItemModal(false);
              }}
              afterSubmit={() => {
                handleGet();
              }}
            />
          )}
          <Container>
            <Tabs activeKey={selectedTab} onChange={setSelectedTab}>
              <TabPane key="0" tab="Detalhes">
                <TabContent padding="0">{DetailsContent}</TabContent>
              </TabPane>

              {data?.id && !isTemplate && !isLibrary && !hideAttachments && (
                <TabPane key="1" tab="Anexos">
                  <TabContent padding={spaces.space2}>
                    <ItemAttachmentContent
                      data={{ files, observation }}
                      setFiles={setFiles}
                      setObservation={setObservation}
                      readOnly={isCustomer || readOnly}
                      idRefurbish={idReference}
                    />
                  </TabContent>
                </TabPane>
              )}
              {data?.id && !hideSupplier && (
                <TabPane key="2" tab="Dados do fornecedor">
                  <TabContent padding={spaces.space2}>
                    <Description
                      title="Dados bancários"
                      mapping={mappingDescriptionConf.bank}
                      column={3}
                      data={data?.supplier || {}}
                      background={null}
                      noPadding
                    />
                    <Description
                      style={{ paddingTop: spaces.space2, borderTop: `1px solid ${colors.neutral100}` }}
                      title="Dados da empresa"
                      mapping={mappingDescriptionConf.supplier}
                      data={data?.supplier || {}}
                      background={null}
                      noPadding
                      extra={
                        hasSupplier ? (
                          <Button text onClick={() => setEditSupplierId(data?.supplier?.id)}>
                            <FontAwesomeIcon icon={faPenToSquare} color={colors.primary600} />
                          </Button>
                        ) : null
                      }
                    />
                  </TabContent>
                </TabPane>
              )}
              {data?.id && !isTemplate && !isLibrary && !isUserAnonymous && !hideComments && (
                <TabPane key="3" tab="Comentários">
                  <TabContent padding={spaces.space2}>
                    <CommentList
                      tooltipText="Os comentários são compartilhados entre os módulos de Orçamento e Planejamento.
                      Tudo que você comentar aqui também será apresentado no Orçamento."
                      data={data}
                      idRefurbish={idReference}
                      model={model}
                      isWithinDrawer
                    />
                  </TabContent>
                </TabPane>
              )}
            </Tabs>
          </Container>
          {hasSupplier && !hideSupplier && editSupplierId && (
            <EditOrCreateCustomerOrSupplier
              id={editSupplierId}
              model="supplier"
              onClose={() => {
                setEditSupplierId(null);
                handleGet();
              }}
            />
          )}
        </>
      )}
    </Drawer>
  );
};

EditItemModal.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  idReference: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClose: PropTypes.func,
  model: PropTypes.string,
  readOnly: PropTypes.bool,
  tab: PropTypes.string,
  isTemplate: PropTypes.bool,
  groupedReference: PropTypes.instanceOf(Object),
  type: PropTypes.number,
  renderColumns: PropTypes.instanceOf(Object),
  isLibrary: PropTypes.bool,
  onlyLabor: PropTypes.bool,
  libraryProps: PropTypes.instanceOf(Object),
  afterSubmit: PropTypes.func,
  isChildrenDrawer: PropTypes.bool,
  initialData: PropTypes.instanceOf(Object),
  isProductConciliation: PropTypes.bool,
  hideAttachments: PropTypes.bool,
  hideComments: PropTypes.bool
};

export default memo(EditItemModal);
