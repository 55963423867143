import dayjs from 'dayjs';
import { store } from '../config/redux-store';
import { getToday, getTomorrow, getYesterday } from './helper';

const handleFilter = ({
  selectedFilters = {},
  setFilter = f => f,
  beforeSetFilter = f => f,
  afterSetFilter = f => f,
  filterDefault = {},
  keyToIsolateNullFromArray
}) => {
  const filterKeys = Object.keys(selectedFilters);

  if (!filterKeys?.length) {
    setFilter(filterDefault);
    return null;
  }

  return filterKeys.forEach(key => {
    const selectedFilter = Array.isArray(selectedFilters[key])
      ? selectedFilters[key].filter(s => s.checked)
      : selectedFilters[key];

    beforeSetFilter();

    setFilter(prev => {
      if (Array.isArray(selectedFilter) && selectedFilter.length) {
        if (selectedFilter[0].where !== undefined) {
          return {
            ...selectedFilter.reduce((acc, cur) => ({ ...acc, ...cur.where }), {})
          };
        }

        if (keyToIsolateNullFromArray === key) {
          let hasNullOnArray = false;
          const list = selectedFilter.map(s => {
            if (s.value === null) hasNullOnArray = true;
            return s.value;
          });

          if (hasNullOnArray) {
            return { ...prev, or: [{ [key]: null }, { [key]: list }], [key]: undefined };
          }
          return { ...prev, [key]: list, or: undefined };
        }
        return { ...prev, [key]: selectedFilter.map(s => s.value) };
      }

      if (selectedFilter?.where !== undefined) {
        return { ...prev, ...selectedFilter.where };
      }

      const _filter = { ...prev };

      delete _filter[key];

      if (keyToIsolateNullFromArray) delete _filter?.or;

      if (filterDefault?.[key]) return { ...prev, [key]: filterDefault[key] };

      return _filter;
    });

    afterSetFilter();
  });
};

const clearFilter = appliedFilters => {
  const newFields = { ...appliedFilters };
  if (!appliedFilters) return;
  Object.entries(appliedFilters).forEach(([key, values]) => {
    if (values && values.length)
      values.forEach((item, index) => {
        newFields[key][index].checked = false;
      });
    else newFields[key] = undefined;
  });
};

const getEdgeDate = ({ dateFieldToFilter, minDate, maxDate }) =>
  dateFieldToFilter === 'payment.billingDate'
    ? {
        maxDate: maxDate || dayjs(new Date(2200, 11, 31)).format('YYYY-MM-DD'),
        minDate: minDate || dayjs(new Date(1900, 0, 1)).format('YYYY-MM-DD')
      }
    : null;

const InstallmentFilter = params => {
  const { rangeField: dateFieldToFilter, initialDate, endDate } = params;
  const { setup } = store.getState();
  const { installmentStatuses } = setup.enums;
  const hasPassedToday =
    initialDate &&
    endDate &&
    (dayjs(getToday()).isBefore(dayjs(initialDate || 0)) || dayjs(getToday()).isAfter(dayjs(endDate || 0)));
  const _dateFieldToFilter =
    dateFieldToFilter === 'payment.billingDate' || !dateFieldToFilter ? 'dueDate' : dateFieldToFilter;
  return {
    all: {
      idInstallmentStatus: installmentStatuses.all,
      ...(dateFieldToFilter && { [dateFieldToFilter]: { gte: initialDate, lte: endDate } })
    },
    overdue: {
      idInstallmentStatus: installmentStatuses.allPending,
      [_dateFieldToFilter]: {
        gte: initialDate || dayjs(new Date(1900, 0, 1)).format('YYYY-MM-DD'),
        lte:
          endDate && dayjs(endDate).isBefore(dayjs(getToday())) ? endDate : dayjs(getYesterday()).format('YYYY-MM-DD')
      }
    },
    today: {
      idInstallmentStatus: installmentStatuses.allPending,
      [_dateFieldToFilter]: {
        gte: hasPassedToday ? null : dayjs(getToday()).format('YYYY-MM-DD'),
        lte: hasPassedToday ? null : dayjs(getToday()).format('YYYY-MM-DD')
      }
    },
    open: {
      idInstallmentStatus: installmentStatuses.allPending,
      [_dateFieldToFilter]: {
        gte:
          dayjs(getToday()).isAfter(dayjs(initialDate || 0)) || dayjs(getToday()).isSame(dayjs(initialDate || 0))
            ? dayjs(getTomorrow()).format('YYYY-MM-DD')
            : initialDate,
        lte: endDate || dayjs(new Date(2200, 11, 31)).format('YYYY-MM-DD')
      }
    },
    paid: {
      idInstallmentStatus: installmentStatuses.allPaid,
      [dateFieldToFilter || 'paidDate']: {
        gte: initialDate || dayjs(new Date(1900, 0, 1)).format('YYYY-MM-DD'),
        lte: endDate || dayjs(new Date(2200, 11, 31)).format('YYYY-MM-DD')
      }
    }
  };
};

const validateRangeDateFilter = (filter, currentTab) => {
  const { initialDate, endDate } = filter;
  const { today, open } = {
    today: currentTab === 'today',
    open: currentTab === 'open',
    overdue: currentTab === 'overdue'
  };
  const filterOpen = (dayjs(endDate).isBefore(dayjs(initialDate)) || dayjs(endDate).isSame(dayjs(), 'day')) && open;
  const filterToday = dayjs(endDate).isBefore(dayjs(), 'day') && today;
  return filterOpen || filterToday;
};

const mountPurchaseOverviewWhere = ({
  filter,
  purchaseItemStatus,
  refurbishItemStatusEnum,
  purchaseItemsStatusEnum
}) => {
  const parsed = Object.entries(filter || {}).reduce((acc, [key, value]) => {
    const isCostFilter = ['averageCost', 'totalCost'].includes(key);

    if (isCostFilter && value?.length === 1) {
      const queries = {
        above: { gt: 100 },
        below: { lte: 100 }
      };

      return { ...acc, [key]: queries[value[0]], hasPurchased: true, hasBudget: true };
    }

    if (isCostFilter && value?.length !== 1) return acc;

    return { ...acc, [key]: value };
  }, {});

  if (purchaseItemStatus === purchaseItemsStatusEnum.notPurchased) {
    parsed.hasPurchased = false;
  } else if (purchaseItemStatus === purchaseItemsStatusEnum.notBudgeted) {
    parsed.hasBudget = false;
    return parsed;
  } else if (purchaseItemStatus === purchaseItemsStatusEnum.requested) {
    parsed.hasRequest = true;
  } else if (purchaseItemStatus === purchaseItemsStatusEnum.purchased) {
    parsed.hasPurchased = true;
  } else if (purchaseItemStatus === purchaseItemsStatusEnum.quoted) {
    parsed.hasQuoted = true;
  }

  if (!parsed.budgetStatus) parsed.or = [{ budgetStatus: refurbishItemStatusEnum.activeStatus }, { hasBudget: false }];

  return parsed;
};

export {
  handleFilter,
  clearFilter,
  InstallmentFilter,
  getEdgeDate,
  validateRangeDateFilter,
  mountPurchaseOverviewWhere
};
