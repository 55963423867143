import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Input } from 'antd';
import styled from 'styled-components';
import TextArea from 'antd/lib/input/TextArea';

import { Div, colors, spaces } from '../../styles/style';
import { Paragraph } from '../Text/Text';
import Select from '../Select/Select';
import { StyledContentDiv } from '../Table/PdfTable.styled';

const PdfHeader = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  background-color: ${colors.white};

  .header-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${spaces.space1};
    width: 50%;
  }

  .full-width {
    width: 100%;
  }

  .section {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    padding: ${spaces.space2} ${spaces.space3};
    border-bottom: 1px solid ${colors.neutral100};
  }

  .left-section {
    align-items: space-between;
    gap: ${spaces.space2};
    width: 35%;
    min-width: 464px;
    border-right: 1px solid ${colors.neutral100};

    .ant-select {
      min-width: 120px;
    }
  }

  .middle-section {
    align-items: flex-start;
    width: 232px;
    border-right: 1px solid ${colors.neutral100};
  }

  .right-section {
    align-items: flex-start;
    flex: 1;
  }
`;

const ExportPdfHeader = ({
  orientation,
  setOrientation,
  description,
  setDescription,
  headerCheckboxes,
  setHeaderCheckboxes,
  refurbish,
  isCustomer,
  CheckComponent,
  title,
  setTitle,
  format,
  setFormat,
  scale,
  setScale,
  isGantt
}) => {
  const orientationOptions = [
    { name: 'Retrato', value: 'portrait' },
    { name: 'Paisagem', value: 'landscape' }
  ];

  const formatOptions = [
    ...(isGantt ? [{ name: 'Automático', value: 'auto' }] : []),
    { name: 'A4', value: 'a4' },
    { name: 'A3', value: 'a3' },
    { name: 'A2', value: 'a2' },
    { name: 'A1', value: 'a1' },
    { name: 'A0', value: 'a0' }
  ];

  const scaleTypeOptions = [
    { name: 'Padrão (100%)', value: 'normal' },
    { name: 'Ajustar à largura', value: 'fit-width' }
  ];

  const HeaderCheckboxOptions = (
    <StyledContentDiv>
      <Paragraph>Cabeçalho</Paragraph>
      <Div padding={`${spaces.space2} 0 0`}>
        <Checkbox.Group
          key="checkGroupKey"
          value={headerCheckboxes}
          options={[
            { key: 'company', label: 'Informações da empresa', value: 'company' },
            ...(refurbish?.companyCustomer
              ? [{ key: 'customer', label: 'Informações do cliente', value: 'customer' }]
              : [])
          ]}
          onChange={setHeaderCheckboxes}
        />
      </Div>
    </StyledContentDiv>
  );

  return (
    <PdfHeader>
      <div className="section left-section">
        <Paragraph type="small">Formato e informações</Paragraph>

        <Div justify="space-between" gap={spaces.space2}>
          <div className="header-column">
            <Paragraph type="small">Tamanho do papel</Paragraph>
            <Select
              name="idFormat"
              options={formatOptions}
              value={format}
              placeholder="Selecione..."
              onChange={selected => setFormat(selected)}
            />
          </div>
          <div className="header-column">
            <Paragraph type="small">Orientação</Paragraph>
            <Select
              name="idOrientation"
              options={orientationOptions}
              value={orientation}
              placeholder="Selecione..."
              onChange={selected => setOrientation(selected)}
              disabled={format === 'auto'}
            />
          </div>
          {!isGantt && (
            <div className="header-column">
              <Paragraph type="small">Escala</Paragraph>
              <Select
                name="idFormat"
                options={scaleTypeOptions}
                value={scale}
                placeholder="Selecione..."
                onChange={selected => setScale(selected)}
              />
            </div>
          )}
        </Div>

        <div className="header-column full-width">
          <Paragraph type="small">Titulo do PDF</Paragraph>
          <Input id="pdf-title-input" type="text" value={title} onChange={e => setTitle(e.target.value)} />
        </div>
        <div className="header-column full-width">
          <Paragraph type="small">Descrição</Paragraph>
          <TextArea
            id="pdf-description-input"
            placeholder="Digite"
            value={description}
            onChange={e => setDescription(e.target.value)}
          />
        </div>
      </div>
      {!isCustomer && <div className="section middle-section">{HeaderCheckboxOptions}</div>}
      <div className="section right-section">{CheckComponent}</div>
    </PdfHeader>
  );
};

ExportPdfHeader.propTypes = {
  orientation: PropTypes.string,
  setOrientation: PropTypes.func,
  description: PropTypes.string,
  setDescription: PropTypes.func,
  headerCheckboxes: PropTypes.instanceOf(Array),
  setHeaderCheckboxes: PropTypes.func,
  refurbish: PropTypes.instanceOf(Object),
  isCustomer: PropTypes.bool,
  CheckComponent: PropTypes.instanceOf(Object),
  title: PropTypes.string,
  setTitle: PropTypes.func,
  format: PropTypes.string,
  setFormat: PropTypes.func,
  scale: PropTypes.string,
  setScale: PropTypes.func,
  isGantt: PropTypes.bool
};

export default ExportPdfHeader;
