import React from 'react';
import { useParams } from 'react-router-dom';
import LogoIcon from '../../components/Icons/Logo';
import { Header, Container, Content } from '../ViewPublic.style';
import Payment from './Payment';

const PaymentViewPublic = () => {
  const { id } = useParams();
  return (
    <Container>
      <Header>
        <div>
          <LogoIcon scale={0.6} />
        </div>
      </Header>
      <Content>
        <Payment isView isPublic id={id} style={{ maxWidth: '992px', width: '100%' }} />
      </Content>
    </Container>
  );
};

export default PaymentViewPublic;
