import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons';
import { useSelector } from 'react-redux';
import Button from '../Button/Button';
import { spaces } from '../../styles/style';
import { allDropdown } from '../../lib/mapping/Dropdown/allDropdown';
import useCRUD from '../../_Hooks/useCRUD';
import Modal from '../Modal/Modal';
import useViewport from '../../_Hooks/useViewport';
import DropDownButton from '../Button/DropDownButton';
import { StyledDropDown, StyledItemMenu, StyledMenu, SubMenu } from './DropdownV2.styled';
import { hasPermission } from '../../routes/Common/PrivateRoute';

const Dropdown = ({
  data,
  slim,
  mappingKey = 'default',
  modal = {},
  button = {},
  menu,
  model,
  options,
  forceSubmit,
  afterSubmit = f => f,
  buttonType = 'primary',
  customActions = null,
  ghost = true,
  children,
  customTrigger = {},
  className,
  onItemClick,
  alignLeftOptions = false,
  buttonPadding = false,
  id: idItem,
  menuMaxHeight,
  zIndex,
  withoutZIndex,
  mobileTitle = 'Opções',
  noModalPadding,
  onMobileClose = f => f,
  callbackModal,
  ...props
}) => {
  const { handleGet, handleCreate, handleUpdate, handleDelete } = useCRUD({
    model,
    options,
    immediatelyLoadData: false
  });

  const actionFunctions = customActions || {
    get: ({ pathOptions: refetchPathOptions, options: refetchOptions, ...params }) =>
      handleGet({ refetchPathOptions, refetchOptions, ...params }),
    post: ({ pathOptions: postPathOptions, options: postOptions, ...params }) =>
      handleCreate({ postPathOptions, postOptions, refresh: false, ...params }),
    put: ({ pathOptions: updatePathOptions, options: updateOptions, ...params }) =>
      handleUpdate({ updatePathOptions, updateOptions, refresh: false, ...params }),
    delete: ({ pathOptions: deletePathOptions, options: deleteOptions, ...params }) =>
      handleDelete({ deletePathOptions, deleteOptions, refresh: false, ...params }),
    patch: ({ pathOptions: updatePathOptions, options: updateOptions, ...params }) =>
      handleUpdate({ updatePathOptions, updateOptions, verb: 'patch', refresh: false, ...params })
  };

  const [showModal, setShowModal] = useState({});
  const [showRenderModal, setShowRenderModal] = useState(false);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const { isMobile } = useViewport(window.innerWidth);
  const _isMobile = isMobile();

  const { setFunction = f => f, value: customTriggerValue } = customTrigger;
  const { user } = useSelector(state => state.authReducer);
  const { plans, permissions } = useSelector(state => state.setup);

  const handleSubmit = (key, newData = {}) => {
    const { id } = data || {};
    const { create } = newData || {};
    const { verb, pathOptions, displayToast, values, options: opts = {}, order, label, actionKey } = key || {};
    const submitOptions = { ...options, ...opts };
    const afterSubmitParams = { id, actionType: verb, oldData: data, actionKey };
    setShowRenderModal(false);
    setShowModal({});
    if (forceSubmit) {
      return forceSubmit({
        id,
        values: { ...data, ...newData, ...values },
        create,
        pathOptions,
        displayToast,
        refresh: false,
        refetchOptions: submitOptions,
        order,
        label,
        fromModal: key.modal
      })?.then(() => afterSubmit(afterSubmitParams));
    }
    return actionFunctions[verb]
      ? actionFunctions[verb]({
          id,
          values: { ...data, ...newData, ...values },
          pathOptions,
          displayToast,
          options: submitOptions
        }).then(resp => {
          return afterSubmit({ ...afterSubmitParams, newId: resp?.id, response: resp, idReceipt: resp?.idReceipt });
        })
      : afterSubmit(afterSubmitParams);
  };

  const handleClick = (e, key) => {
    if (_isMobile) {
      e.stopPropagation();
      e.preventDefault();
    }

    if (key?.stopPropagation) return;

    if (key?.externalAction) {
      setShowRenderModal(false);
      setFunction(false);
      setShowModal({ [key?.modal + key?.label]: key?.modalParams || {} });
      onItemClick({ ...key, values: { ...key.values, ...data } });
      return;
    }
    if (key?.modal) {
      setShowModal({ [key?.modal + key?.label]: key?.modalParams || {} });
      e.preventDefault();
      return;
    }

    handleSubmit(key);
  };

  const onClose = (index, reload = true, resp) => {
    setShowRenderModal(false);
    setFunction(false);
    setShowModal({ [index]: null });
    reload && afterSubmit(null, resp);
  };

  useEffect(() => {
    setShowRenderModal(customTriggerValue);
  }, [customTriggerValue]);

  useEffect(() => {
    const mappingFunction = {
      string: key => allDropdown[key],
      function: key => allDropdown[key(data)]
    };

    const keyType = typeof mappingKey;
    const mappedKey = mappingFunction[keyType] ? mappingFunction[keyType](mappingKey) : mappingKey;

    setDropdownOptions(mappedKey);
  }, [mappingKey]);

  useEffect(() => {
    if (callbackModal) {
      setShowModal({ [`${callbackModal?.modal}-callback`]: callbackModal?.modalParams || {} });
    }
  }, [callbackModal]);

  const renderModal = (item, modalName, modalParams) => {
    const ModalOption = item?.modal && modal[item?.modal];
    return (
      ModalOption &&
      showModal[modalName] && (
        <ModalOption
          key={modalName}
          onSubmit={newData => handleSubmit(item, newData)}
          onClose={(reload, resp) => onClose(modalName, reload, resp)}
          {...data}
          {...modalParams}
          {...showModal[modalName]}
        />
      )
    );
  };

  const renderMenuItems = (items, idx = 0, parent) => {
    const renderedItems = [];
    items.forEach(item => {
      const withPermission = hasPermission(user, item.roles, plans, permissions);

      if (withPermission === false) return;

      const CustomButton = button[item.button];
      const RenderItemButton = CustomButton || DropDownButton;
      const id = `${parent ? `${parent}-` : ''}${item?.id}`;

      const withChildren = item?.children?.length > 0;

      const modalName = item?.modal + item?.label;
      const modalParams = showModal[modalName]?.modalParams || {};

      const renderedItem =
        withChildren && withPermission ? (
          <SubMenu
            key={`submenu-item-${id}`}
            title={<RenderItemButton id={id} optionData={item} index={idx} {...item.buttonParams} />}
            id={`submenu-item-${id}`}
            $alignLeftOptions={alignLeftOptions}
            $menuMaxHeight={menuMaxHeight}
          >
            {renderMenuItems(item.children, idx + 1, id)}
            {renderModal(item, modalName, modalParams)}
          </SubMenu>
        ) : (
          <StyledItemMenu key={`menu-item-${id}`} bar={item.bar}>
            <RenderItemButton
              id={id}
              onClose={reload => onClose(item.modal, reload, data)}
              optionData={item}
              index={idx}
              onClick={e => handleClick(e, item)}
              style={{ padding: _isMobile && '0' }}
              width="100%"
              $justifyContent="flex-start"
              {...item.buttonParams}
            />
            {renderModal(item, modalName, modalParams)}
          </StyledItemMenu>
        );

      renderedItems.push(renderedItem);
    });

    return renderedItems;
  };

  const _menu = (
    <StyledMenu $alignLeftOptions={alignLeftOptions} $menuMaxHeight={menuMaxHeight}>
      {menu || renderMenuItems(dropdownOptions)}
    </StyledMenu>
  );

  const MobileButton = children
    ? () =>
        React.cloneElement(children, {
          onClick: e => {
            e.stopPropagation();
            setShowRenderModal(true);
            setFunction(true);
          }
        })
    : null;

  return (
    <>
      {_isMobile ? (
        <>
          {MobileButton ? (
            <MobileButton />
          ) : (
            <Button
              type={buttonType}
              shape={slim ? 'text' : null}
              ghost={ghost}
              onClick={e => {
                e.stopPropagation();
                setShowRenderModal(true);
              }}
              style={{ padding: spaces.space1 }}
            >
              <FontAwesomeIcon icon={faEllipsisV} />
            </Button>
          )}
          {showRenderModal && (
            <Modal
              title={mobileTitle}
              open
              hideFooter
              noPadding={noModalPadding}
              onClose={e => {
                e.stopPropagation();
                setShowRenderModal(false);
                setFunction(false);
                onMobileClose();
              }}
            >
              {_menu}
            </Modal>
          )}
        </>
      ) : (
        <StyledDropDown
          dropdownRender={() => _menu}
          overlayStyle={!withoutZIndex && { zIndex: zIndex || 9999 }}
          overlayClassName="c-dropdown"
          slim
          {...props}
        >
          {children || (
            <Button
              padding={buttonPadding}
              type={buttonType}
              text={!!slim}
              ghost={ghost}
              onClick={e => e.stopPropagation()}
              icon={<FontAwesomeIcon icon={faEllipsisV} />}
              id={idItem}
            />
          )}
        </StyledDropDown>
      )}
      {callbackModal && renderModal(callbackModal, `${callbackModal?.modal}-callback`, callbackModal?.modalParams)}
    </>
  );
};

Dropdown.propTypes = {
  data: PropTypes.instanceOf(Object),
  slim: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  mappingKey: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  modal: PropTypes.instanceOf(Object),
  button: PropTypes.instanceOf(Object),
  menu: PropTypes.instanceOf(Object),
  model: PropTypes.string,
  options: PropTypes.instanceOf(Object),
  forceSubmit: PropTypes.func,
  afterSubmit: PropTypes.func,
  buttonType: PropTypes.string,
  ghost: PropTypes.bool,
  children: PropTypes.instanceOf(Object),
  customActions: PropTypes.instanceOf(Object),
  customTrigger: PropTypes.instanceOf(Object),
  className: PropTypes.string,
  onItemClick: PropTypes.func,
  alignLeftOptions: PropTypes.bool,
  buttonPadding: PropTypes.string,
  id: PropTypes.string,
  menuMaxHeight: PropTypes.string,
  zIndex: PropTypes.number,
  mobileTitle: PropTypes.string,
  noModalPadding: PropTypes.bool,
  onMobileClose: PropTypes.func,
  withoutZIndex: PropTypes.bool,
  callbackModal: PropTypes.instanceOf(Object)
};

export default Dropdown;
