import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

// context
import { toast } from 'react-toastify';
import useCRUD from './useCRUD';

// Components
import { columns } from '../lib/mapping/TableOrList/specificationColumns';

// helper
import { getRefetchOneOptions, getRefetchOptions } from '../lib/helpers/projectFunctions';
import { handleFilter as handleFilterHelper } from '../lib/helpers/filterFunctions';
import { hasPermission } from '../routes/Common/PrivateRoute';
import { parseArrayAsObject } from '../lib/helpers/parseArrayAsObject';
import { exportToModel, indexList } from '../lib/helpers/helper';
import ProjectOrOpportunityEventService from '../lib/gtm/opportunity';
import useViewport from './useViewport';
import eventBus from '../lib/helpers/eventBus';

const gtmEventService = ProjectOrOpportunityEventService();

const composeSort = {
  supplier: ['supplier', 'name'],
  refurbishGroup: ['refurbishGroup', 'name'],
  costCenter: ['costCenter', 'name'],
  units: ['units', 'name'],
  plIdResponsible: ['user', 'name']
};

const useSpecification = ({
  data,
  isTemplate,
  setIsDefaultOrder = f => f,
  setShowUnitItem,
  setExpandedItem = f => f,
  setIsEmptyState = f => f,
  grouped,
  idCompany,
  modelExport,
  prefixName,
  isApply,
  initExpandedRow,
  extraInclude = [],
  externalAfterChange = f => f
}) => {
  const { user } = useSelector(state => state.authReducer);
  const { id, myColumns, clientColumns, productBdi, laborBdi } = data || {};
  const { refurbishItemStatus: statusArray } = useSelector(state => state.setup.systemData);
  const { refurbishItemStatus: itemStatusEnum, refurbishItemType, userType } = useSelector(state => state.setup.enums);
  const { plans, permissions } = useSelector(state => state.setup);
  const [isLoading, setIsLoading] = useState(true);
  const [reloadFooter, setReloadFooter] = useState(true);
  const [sorted, setSorted] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [filter, setFilter] = useState(false);
  const [expandedRowKeys, setExpandedRowKeys] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [forceCellUpdate, setForceCellUpdate] = useState('');
  const [itemToRefresh, setItemToRefresh] = useState(null);
  const [serializedStatusArray, setSerializedStatusArray] = useState({});
  const [newList, setNewList] = useState([]);
  const [levels, setLevels] = useState([]);
  const { isMobile: isMobileFn } = useViewport(window.innerWidth);
  const isMobile = isMobileFn();

  const lastAdd = useRef(null);
  const reload = useRef(null);
  const _expandedRowKeys = useRef(initExpandedRow);

  const isGrouped = !!grouped?.model;
  const isCustomer = user.userType === userType.customer.value;
  const childrenColumn = isGrouped ? 'item' : 'children';

  let referenceKey;
  let model;
  let includeModel;

  if (isTemplate) {
    referenceKey = 'idTemplate';
    model = 'template-item';
    includeModel = 'TemplateItem';
  } else {
    referenceKey = 'idRefurbish';
    model = 'refurbish-items';
    includeModel = 'RefurbishItems';
  }

  const _hasPermissionUnitEdit = hasPermission(user, ['units'], plans, permissions);

  const { handleGet, handleCreate, handleUpdate, setList, handleDelete } = useCRUD({
    model,
    options: { where: { [referenceKey]: id } },
    immediatelyLoadData: false
  });

  const { list: supplierList, handleGet: getSupplier, handleUpdate: updateSupplier } = useCRUD({
    model: 'supplier',
    options: { onlyMine: true, where: { idCompany, [referenceKey]: id }, order: [['name', 'asc']] },
    immediatelyLoadData: !isCustomer
  });

  const { list: groupList, handleGet: getGroup, handleUpdate: updateRefurbishGroup } = useCRUD({
    model: 'refurbishGroup',
    options: { where: { idCompany, [referenceKey]: id }, order: [['name', 'asc']] },
    immediatelyLoadData: !isCustomer
  });

  const { list: categoryList, handleGet: getCategory, handleUpdate: updateCostCenter } = useCRUD({
    model: 'costCenter',
    options: { onlyMine: true, where: { idCompany, [referenceKey]: id }, order: [['name', 'asc']] },
    immediatelyLoadData: !isCustomer
  });

  const { list: unitList, handleGet: getUnit } = useCRUD({
    model: 'unit',
    options: { where: { idCompany, [referenceKey]: id }, order: [['name', 'asc']] },
    immediatelyLoadData: !isCustomer
  });

  const { list: levelList, handleGet: getLevel } = useCRUD({
    model,
    options: {
      attributes: [
        ['id', 'value'],
        ['name', 'title']
      ],
      where: { [referenceKey]: id, idParent: null, type: refurbishItemType.parent },
      include: [
        {
          model: includeModel,
          attributes: [
            ['id', 'value'],
            ['name', 'title']
          ],
          where: { type: refurbishItemType.parent },
          include: ['item'],
          as: 'children',
          required: false
        }
      ],
      order: [['order', 'asc']]
    },
    immediatelyLoadData: !!isGrouped
  });

  const updateFunctions = {
    supplier: updateSupplier,
    refurbishGroup: updateRefurbishGroup,
    costCenter: updateCostCenter
  };

  const paramsOptions = {
    isTemplate,
    idReference: id,
    filter,
    sorted,
    defaultSort: grouped?.model
      ? [
          ['name', 'asc'],
          ['item', 'name', 'asc'],
          ['item', 'createdAt', 'asc']
        ]
      : [
          ['order', 'asc'],
          ['createdAt', 'asc'],
          ['children', 'order', 'asc'],
          ['children', 'createdAt', 'asc'],
          ['children', 'children', 'order', 'asc'],
          ['children', 'children', 'createdAt', 'asc']
        ],
    isGrouped,
    refurbishItemType,
    refurbishItemStatus: itemStatusEnum,
    requiredChildren: true,
    extraInclude
  };

  const handleSelectItem = (record, selected, selectedRows) => {
    setSelectedItems(selectedRows);
    setSelectedRowKeys(selectedRows?.map(s => s.id));
  };

  const handleSelectAll = (selected, selectedRows) => {
    setSelectedItems(selectedRows);
    setSelectedRowKeys(selectedRows?.map(s => s.id));
  };

  const handleToggleAllRow = () => {
    _expandedRowKeys.current = !_expandedRowKeys?.current || _expandedRowKeys?.current?.length ? [] : levels;
    setExpandedRowKeys(_expandedRowKeys.current);
  };

  const refetchOptions = getRefetchOptions(paramsOptions);

  const handleFilter = (selectedFilters = {}) =>
    handleFilterHelper({
      selectedFilters,
      setFilter,
      beforeSetFilter: () => {
        reload.current = !reload.current;
      },
      afterSetFilter: () => {
        setForceCellUpdate('action');
      }
    });

  const handleSort = ({ key, order }) => {
    reload.current = !reload.current;
    setSorted({ key: composeSort[key] || key, order });
    setForceCellUpdate('action');
    setIsDefaultOrder(key === undefined);
  };

  const handleLoad = _data => {
    return handleGet({
      generateLoading: false,
      refetchOptions,
      refetchPathOptions: grouped?.model ? `/group/${grouped.model}` : ''
    }).then(resp => {
      setIsLoading(false);
      if (!resp?.error) {
        lastAdd.current = _data?.id;
        setExpandedItem(null);
        setNewList(indexList({ currentList: resp, childrenColumn, setLevels }));
        setIsEmptyState(
          !resp?.length && (!filter || !Object.keys(filter)?.length) && (!sorted || !Object.keys(sorted)?.length)
        );
      }
    });
  };

  const handleAfterChange = (_data, mode) => {
    externalAfterChange(_data, mode);

    const { where } = refetchOptions;
    const idFilter = _data?.[grouped?.key] || _data?.idParentLevel || _data?.idParent || _data?.id;
    return handleGet({
      generateLoading: false,
      refetchOptions: { ...refetchOptions, where: { ...where, id: idFilter } },
      refetchPathOptions: grouped?.model ? `/group/${grouped.model}` : ''
    }).then(resp => {
      setIsLoading(false);
      setItemToRefresh(null);

      if (resp?.error) return;
      setExpandedItem(null);

      setNewList(prev => {
        const level = resp?.[0];

        if (!level && !_data) return prev;

        if (!level) {
          return indexList({ currentList: prev.filter(p => p.id !== _data.id), childrenColumn, setLevels });
        }

        const prevIndex = prev.findIndex(p => p.id === level.id);

        if (prevIndex < 0) {
          return indexList({ currentList: [...prev, level], childrenColumn, setLevels });
        }

        const _prev = [...prev];
        _prev[prevIndex] = level;
        return indexList({ currentList: _prev, childrenColumn, setLevels });
      });
    });
  };

  const handleBulkAfterChange = _data => {
    const { where } = refetchOptions;
    const idFilters = _data.map(item => item?.[grouped?.key] || item?.idParentLevel || item?.idParent || item?.id);
    return handleGet({
      generateLoading: false,
      refetchOptions: { ...refetchOptions, where: { ...where, id: idFilters } },
      refetchPathOptions: grouped?.model ? `/group/${grouped.model}` : ''
    }).then(resp => {
      setIsLoading(false);
      setItemToRefresh(null);

      if (resp?.error) return;
      setExpandedItem(null);

      setNewList(prev => {
        const updatedList = prev.flatMap(item => {
          const itemIsExpectedOnResp = idFilters?.includes(item?.id);
          const updatedItem = resp.find(r => r.id === item.id);
          return itemIsExpectedOnResp && !updatedItem ? [] : updatedItem || item;
        });

        return indexList({ currentList: updatedList, childrenColumn, setLevels });
      });
    });
  };

  useEffect(() => {
    if (Array.isArray(itemToRefresh)) {
      handleBulkAfterChange(itemToRefresh);
      return;
    }
    if (itemToRefresh) {
      handleAfterChange(itemToRefresh);
    }
  }, [itemToRefresh]);

  const handleSubmitRefurbishItems = (
    { values, create, dragRow, dragIsLevel },
    displayToast = 'Item adicionado com sucesso.'
  ) => {
    const { idCostCenter, idRefurbishGroup, idSupplier, idSearch, idReference, ...newData } = values;

    const itemValues = {
      idSupplier: idSupplier || undefined,
      idCostCenter: idCostCenter || undefined,
      idRefurbishGroup: idRefurbishGroup || undefined,
      ...newData
    };

    if (!newData.id || create) {
      return handleCreate({
        values: {
          ...itemValues,
          ...(idSearch ? { idSearch } : { idItem: values.id }),
          [referenceKey]: id
        },
        displayToast,
        refresh: false
      }).then(resp => {
        if (!resp?.error) {
          lastAdd.current = resp?.id;
          eventBus.dispatch('updateGuide');
          if (referenceKey === 'idTemplate' || dragIsLevel) handleLoad();
          else handleAfterChange(resp || values);
        }
      });
    }

    return handleUpdate({
      id: itemValues.id,
      values: {
        ...itemValues,
        [referenceKey]: id,
        ...(itemValues?.status === itemStatusEnum.approved.value && { isApproved: true })
      },
      refresh: false,
      updateOptions: getRefetchOneOptions()
    }).then(resp => {
      setSelectedRowKeys([]);
      setSelectedItems([]);
      if (resp?.idTemplate || dragIsLevel) handleLoad();
      else {
        setItemToRefresh(resp);
        if (dragRow) {
          setItemToRefresh(dragRow);
        }
      }
    });
  };

  const handleChange = (values, key, groupEdit) => (value, isNew) => {
    const changeFn = grouped?.value && groupEdit ? updateFunctions[grouped?.value] : handleSubmitRefurbishItems;
    changeFn({ id: values.id, values: { id: values.id, [key]: value } }).then(() => {
      if (isNew) {
        setReloadFooter(!reloadFooter);
      }
      if (key === 'status' && value === itemStatusEnum.rejected.value) {
        toast.success('Item reprovado. Itens reprovados podem ser vistos pelo filtro de status.');
      }
    });
  };

  useEffect(() => {
    handleLoad();
  }, [filter, sorted, reload.current]);

  useEffect(() => {
    setSelectedRowKeys([]);
    setSelectedItems([]);
  }, [filter]);

  useEffect(() => {
    const object = parseArrayAsObject(statusArray, true);
    setSerializedStatusArray(object);
  }, [statusArray]);

  useEffect(() => {
    setForceCellUpdate('supplier');
  }, [supplierList]);

  useEffect(() => {
    setForceCellUpdate('group');
  }, [groupList]);

  useEffect(() => {
    setForceCellUpdate('category');
  }, [categoryList]);

  useEffect(() => {
    setForceCellUpdate('unit');
  }, [unitList]);

  useEffect(() => {
    if (grouped) {
      _expandedRowKeys.current = initExpandedRow;
      setExpandedRowKeys(_expandedRowKeys.current);
      setLevels([]);
      setSorted(null);
      reload.current = !reload.current;
    }
  }, [grouped]);

  const refetchLists = column => () => {
    const loadList = {
      supplier: getSupplier,
      group: getGroup,
      category: getCategory,
      unit: getUnit,
      level: getLevel
    };
    loadList[column]();
    setForceCellUpdate(column);
  };

  const _columns = columns({
    isTemplate,
    statusArray,
    serializedStatusArray,
    refurbishItemType,
    readOnly: isCustomer || user?.isCustomerView || !!user?.anonymous,
    isUserAnonymous: !!user?.anonymous,
    idReference: id,
    referenceKey,
    handleChange,
    handleAfterChange,
    forceCellUpdate,
    lastAdd,
    supplierList,
    groupList,
    categoryList,
    unitList,
    levelList,
    refetchLists,
    setShowUnitItem,
    groupModel: grouped?.model,
    hasPermission: _hasPermissionUnitEdit,
    refetchOptions,
    columnsToShow: isCustomer ? clientColumns : myColumns,
    isApply,
    isMobile,
    productBdi,
    laborBdi,
    handleToggleAllRow
  });

  const getElementsIds = () => {
    const selectedMap = {};
    const result = [];

    selectedItems.forEach(item => {
      selectedMap[item.id] = true;
      result.push(item.id);
    });

    const pushChildren = parent => {
      parent.children.forEach(child => {
        result.push(child.id);
        if (child.type === refurbishItemType.parent && child.children?.length) {
          pushChildren(child);
        }
      });
    };

    const findSelectedChild = parent => {
      if (parent.children?.length) {
        const selectedChilds = parent.children.filter(child => selectedMap[child.id] || findSelectedChild(child));

        if (selectedChilds?.length) {
          result.push(...selectedChilds.map(c => c.id));
          return selectedChilds;
        }
      }

      return false;
    };

    newList.forEach(parent => {
      const isParent = parent.type === refurbishItemType.parent && parent.children?.length;

      if (selectedMap[parent.id]) {
        result.push(parent.id);
        if (isParent) {
          pushChildren(parent);
        }
      } else if (isParent) {
        const selectedChilds = findSelectedChild(parent);

        if (selectedChilds?.length) {
          result.push(parent.id);
        }
      }
    });

    return [...new Set(result)];
  };

  const handleExportSelectedItems = typeExport => {
    const customHeader = _columns?.reduce((result, column) => {
      if (column.key !== 'action') {
        result.push(column?.key === 'name' ? 'Item' : column.title);
      }
      return result;
    }, []);
    customHeader.splice(customHeader.indexOf('Item'), 0, 'Tipo');
    const ids = selectedItems?.length && !isGrouped ? getElementsIds() : [];

    return exportToModel({
      where: { idRefurbish: id, ...(ids?.length ? { id: ids } : {}) },
      handleGet,
      model: 'refurbishItems',
      exportType: typeExport,
      customerView: user?.isCustomerView,
      prefixName: prefixName || 'specification',
      modelExport: modelExport || prefixName,
      ...(prefixName !== 'planning' && { customHeader })
    }).then(() => {
      gtmEventService.onExport({
        entity: prefixName === 'planning' ? 'construct-schedule' : 'project-items',
        fileType: typeExport || 'csv',
        type: isCustomer ? 'cliente' : 'professional'
      });
    });
  };

  return {
    isLoading,
    selectedItems,
    setSelectedItems,
    selectedRowKeys,
    setSelectedRowKeys,
    columns: _columns,
    handleLoad,
    setList,
    list: newList,
    filter,
    handleFilter,
    handleSort,
    setIsLoading,
    levelList,
    supplierList,
    groupList,
    categoryList,
    setForceCellUpdate,
    handleSubmitRefurbishItems,
    handleGet,
    refetchOptions,
    refetchLists,
    handleSelectItem,
    handleSelectAll,
    handleExportSelectedItems,
    handleUpdate,
    expandedRowKeys,
    handleToggleAllRow,
    levels,
    handleDelete,
    handleCreate,
    serializedStatusArray,
    setItemToRefresh,
    setFilter
  };
};

export default useSpecification;
